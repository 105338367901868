import * as React from 'react';
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import icape from '../icape-da.scss';

/*https://mui.com/material-ui/customization/theme-components/#global-style-overrides*/

/* BLUE ICAPE BUTTON */
export const ButtonIcape = styled(Button)(({ theme }) => ({
    padding: "0 30px 0 30px",
    width: "fit-content",
    height: 40,
    backgroundColor: icape.blueIcape,
    color: icape.white,
    fill: icape.white,
    borderRadius: 10,
    display: "flex",
    gap: "10px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    transition: "all 0.3s ease-in-out",
    lineHeight: 1.25,
    '&:hover': {
        backgroundColor: icape.softBlue,
        transition: "all 0.3s ease-in-out",
    },
    '&:active':{
        backgroundColor: icape.darkBlue,
        color: icape.softBlue,
        fill: icape.softBlue,
    },
    '&:disabled':{
        backgroundColor: icape.softGrey2,
        color: icape.grey,
        fill: icape.grey,
    }
}));

/* SWEET BLUE BUTTON */
export const ButtonIcapeSoftBlue = styled(Button)(({ theme }) => ({
    padding: "0 30px 0 30px",
    width: "fit-content",
    height: 40,
    backgroundColor: icape.softBlue,
    color: icape.white,
    fill: icape.white,
    borderRadius: 10,
    display: "flex",
    gap: "10px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    transition: "all 0.3s ease-in-out",
     lineHeight: 1.25,
    '&:hover': {
        backgroundColor: icape.darkBlue,
        transition: "all 0.3s ease-in-out",
    },
    '&:active':{
        backgroundColor: icape.blueIcape,
        color: icape.softBlue2,
        fill: icape.softBlue2,
    },
    '&:disabled':{
        backgroundColor: icape.softGrey2,
        color: icape.grey,
        fill: icape.grey,
    }
}));

/* SWEET GREEN BUTTON */
export const ButtonIcapeGreen = styled(Button)(({ theme }) => ({
    padding: "0 30px 0 30px",
    width: "fit-content",
    height: 40,
    backgroundColor: icape.green,
    color: icape.white,
    fill: icape.white,
    borderRadius: 10,
    display: "flex",
    gap: "10px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    transition: "all 0.3s ease-in-out",
     lineHeight: 1.25,
    '&:hover': {
        backgroundColor: icape.softGreen,
        transition: "all 0.3s ease-in-out",
    },
    '&:active':{
        backgroundColor: icape.darkGreen,
        color: icape.softGreen,
        fill: icape.softGreen,
    },
    '&:disabled':{
        backgroundColor: icape.softGrey2,
        color: icape.grey,
        fill: icape.grey,
    }
}));

/* OUTLINED BLUE BUTTON */
export const ButtonIcapeOutlined = styled(Button)(({ theme }) => ({
    padding: "0 30px 0 30px",
    width: "fit-content",
    height: 40,
    backgroundColor: icape.white,
    color: icape.blueIcape,
    fill: icape.blueIcape,
    border: "2px solid",
    borderColor: icape.blueIcape,
    borderRadius: 10,
    display: "flex",
    gap: "10px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    transition: "all 0.3s ease-in-out",
     lineHeight: 1.25,
    '&:hover': {
        backgroundColor: icape.softBlue,
        borderColor: icape.softBlue,
        color: icape.white,
        fill: icape.white,
        transition: "all 0.3s ease-in-out",
    },
    '&:active':{
        backgroundColor: icape.darkBlue,
        borderColor: icape.darkBlue,
        color: icape.softBlue,
        fill: icape.softBlue,
    },
    '&:disabled':{
        borderColor: icape.softGrey2,
        color: icape.grey,
        fill: icape.grey,
    }
}));

/* OUTLINED RED BUTTON */
export const ButtonIcapeOutlinedRed = styled(Button)(({ theme }) => ({
    padding: "0 30px 0 30px",
    width: "fit-content",
    height: 40,
    backgroundColor: icape.white,
    color: icape.red,
    fill: icape.red,
    border: "2px solid",
    borderColor: icape.red,
    borderRadius: 10,
    display: "flex",
    gap: "10px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    transition: "all 0.3s ease-in-out",
     lineHeight: 1.25,
    '&:hover': {
        backgroundColor: icape.darkRed,
        borderColor: icape.darkRed,
        color: icape.white,
        fill: icape.white,
        transition: "all 0.3s ease-in-out",
    },
    '&:active':{
        backgroundColor: icape.darkRed,
        borderColor: icape.darkRed,
        color: icape.white,
        fill: icape.white,
    },
    '&:disabled':{
        borderColor: icape.softGrey2,
        color: icape.grey,
        fill: icape.grey,
    }
}));

export default function StyledButton(){
    return <ButtonIcape />;
}