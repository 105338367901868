import React, {createContext, useEffect, useState} from "react";
import {getStorageValue} from "../tools/cookie-handler";
import i18n from "../i18n";
import {
    de as dateFnsDe,
    enUS as dateFnsEnUS,
    es as dateFnsEs,
    fr as dateFnsFr,
    it as dateFnsIt,
    ja as dateFnsJa,
    ptBR as dateFnsPtBR,
    zhCN as dateFnsZhCN
} from "date-fns/locale";
import {
    deDE as muiDeDE,
    enUS as muiEnUS,
    esES as muiEsES,
    frFR as muiFrFR,
    itIT as muiItIT,
    jaJP as muiJaJP,
    ptBR as muiPtBR,
    zhCN as muiZhCN
} from "@mui/x-data-grid";
import {
    getCompanyAddresses,
    getCustomerAddresses,
    getCustomerData,
    getMailPreferences
} from "../services/customer.service";
import useAuth from "../hooks/useAuth";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {getCountries} from "../services/countries.service";
import AuthService from "../services/auth.service";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [ user, setUser ] = useState({});

    const {auth} = useAuth();
    const [connectToBackend, setConnectToBackend] = useState(false);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);

    // CURRENCY
    const [activeCurrency, setActiveCurrency] = useState(getStorageValue('userPreferences')?.activeCurrency || "$");
    const [currencyCode, setCurrencyCode] = useState(getStorageValue('userPreferences')?.currencyCode || "USD");
    const [orderCurrency, setOrderCurrency] = useState(getStorageValue('userPreferences')?.activeCurrency || "$");
    const [currenciesAllowed, setCurrenciesAllowed] = useState([]);
    // LANGUAGE
    const [activeLanguage, setActiveLanguage] = useState(getStorageValue('userPreferences')?.activeLanguage || '');
    const [muiLocale, setMuiLocale] = useState(dateFnsEnUS);
    const [dataGridLocale, setDataGridLocale] = useState(muiEnUS.components.MuiDataGrid.defaultProps.localeText);
    const [localesList, setLocalesList] = useState([]);
    const [companyLocale, setCompanyLocale] = useState("");

    // Change MUI Calendar lang
    useEffect(() => {
        if (activeLanguage === "CN"){
            setMuiLocale(dateFnsZhCN)
            setDataGridLocale(muiZhCN.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "DE"){
            setMuiLocale(dateFnsDe)
            setDataGridLocale(muiDeDE.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "EN"){
            setMuiLocale(dateFnsEnUS)
            setDataGridLocale(muiEnUS.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "ES"){
            setMuiLocale(dateFnsEs)
            setDataGridLocale(muiEsES.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "FR"){
            setMuiLocale(dateFnsFr)
            setDataGridLocale(muiFrFR.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "IT"){
            setMuiLocale(dateFnsIt)
            setDataGridLocale(muiItIT.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "JA"){
            setMuiLocale(dateFnsJa)
            setDataGridLocale(muiJaJP.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "PT"){
            setMuiLocale(dateFnsPtBR)
            setDataGridLocale(muiPtBR.components.MuiDataGrid.defaultProps.localeText)
        }
    }, [activeLanguage])

    // 1st visit, no cookies, setting up user lang. On refresh, load the good lang
    useEffect(() => {
        // if no cookies with use the browser locale to update the top bar menu
        if (!getStorageValue('userPreferences')){
            setActiveLanguage("EN");
            return
        }
        activeLanguage === "CN" && i18n.changeLanguage('cnCN');
        activeLanguage === "DE" && i18n.changeLanguage('deDE');
        activeLanguage === "EN" && i18n.changeLanguage('enEN');
        activeLanguage === "ES" && i18n.changeLanguage('esES');
        activeLanguage === "FR" && i18n.changeLanguage('frFR');
        activeLanguage === "IT" && i18n.changeLanguage('itIT');
        activeLanguage === "JA" && i18n.changeLanguage('jaJP');
        activeLanguage === "PT" && i18n.changeLanguage('ptBR');
    }, [i18n]);

    // Handle cookie saving with user preferences
    useEffect(() => {
        localStorage.setItem('userPreferences', JSON.stringify({
            activeCurrency,
            activeLanguage,
            currencyCode
        }));
    }, [activeCurrency,activeLanguage]);

    //LOGIN
    const [isFirstConnection, setIsFirstConnection] = useState()

    //USER ACCOUNT
    const [customerInfo, setCustomerInfo] = useState([]);

    //ACCOUNT SIDEBAR
    const [businessUnit, setBusinessUnit] = useState('');
    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [emailOfReferrer, setEmailOfReferrer] = useState('');
    const [firstNameOfReferrer, setFirstNameOfReferrer] = useState('');
    const [lastNameOfReferrer, setLastNameOfReferrer] = useState('');

    //ACCOUNT SETTINGS
    const [firstName, setFirstName] = useState(customerInfo?.firstName || '');
    const [lastName, setLastName] = useState(customerInfo?.lastName || '');
    const [phoneNumber, setPhoneNumber] = useState(customerInfo?.phoneNumber || '');
    const [email, setEmail] = useState(customerInfo?.email || '');
    const [newPassword, setNewPassword] = useState(customerInfo?.password || '');
    const [isTestAccount, setIsTestAccount] = useState(false);
    // TODO IS THIS REALLY NECESSARY ? parce qu'on pas plus par les locales de bu donc pourquoi tout mettre en place
    const [businessUnitLocale, setBusinessUnitLocale] = useState('');

    //USER MAIL PREFERENCES
    const [customerMailPref, setCustomerMailPref] = useState({});

    const [technicalDocsEmail, setTechnicalDocsEmail] = useState(customerMailPref?.technicalDocsEmail || '');
    const [extraTechnicalDocsEmail, setExtraTechnicalDocsEmail] = useState(customerMailPref?.extraTechnicalDocsEmail || '');

    const [orderConfirmationEmail, setOrderConfirmationEmail] = useState(customerMailPref?.orderConfirmationEmail || '');
    const [extraOrderConfirmationEmail, setExtraOrderConfirmationEmail] = useState(customerMailPref?.extraOrderConfirmationEmail || '');

    const [deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail] = useState(customerMailPref?.deliveryAndInvoiceEmail || '');
    const [extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail] = useState(customerMailPref?.extraDeliveryAndInvoiceEmail || '');

    //USER ADDRESSES
    const [countriesList, setCountriesList] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([])
    const [customerAddress, setCustomerAddress] = useState([]);
    const [companyAddresses, setCompanyAddresses] = useState([]);
    const [addressId, setAddressId] = useState();

    const getAddress = (id) => {
        return customerAddress.find(address => address.id === id);
    }
    const getCompanyAddress = (id) => {
        return companyAddresses.find(address => address.id === id);
    }

    const [companyAddr, setCompanyAddr] = useState(getAddress(addressId)?.company || '');
    const [aliasAddr, setAliasAddr] = useState(getAddress(addressId)?.company || '');
    const [firstNameAddr, setFirstNameAddr] = useState(getAddress(addressId)?.firstName || '');
    const [lastNameAddr, setLastNameAddr] = useState(getAddress(addressId)?.lastName || '');
    const [phoneNumberAddr, setPhoneNumberAddr] = useState(getAddress(addressId)?.phoneNumber || '');
    const [street, setStreet] = useState(getAddress(addressId)?.street || '');
    const [postcode, setPostcode] = useState(getAddress(addressId)?.postcode || '');
    const [city, setCity] = useState(getAddress(addressId)?.city || '');
    const [countryCode, setCountryCode] = useState(getAddress(addressId)?.countryCode || '');
    const [isDefaultAddress, setIsDefaultAddress] = useState(getAddress(addressId)?.isDefaultAddress || false);
    const [isPrivateAddress, setIsPrivateAddress] = useState(getAddress(addressId)?.isPrivateAddress || false);

    const [isDataFullyLoaded, setIsDataFullyLoaded] = useState(false)


    //SYNC DATA FROM DB
    useEffect(async () => {
        if (auth.customerId !== undefined) {

            // Fetches in order => Customer data / Company addresses / Customer addresses / Mail preferences / Countries list
            try {
                setConnectToBackend(true);

                const customerData = await getCustomerData(auth.customerId);

                setCustomerInfo(customerData);
                setBusinessUnit(customerData.businessUnit);
                setCompany(customerData.company.name);
                setCompanyId(customerData.company.id);
                setIsVerified(customerData.isVerified);
                setFirstName(customerData.firstName);
                setLastName(customerData.lastName);
                setPhoneNumber(customerData.phoneNumber);
                setEmail(customerData.email);
                setEmailOfReferrer(customerData.company.emailOfReferrer);
                setFirstNameOfReferrer(customerData.company.firstnameOfReferrer);
                setLastNameOfReferrer(customerData.company.lastnameOfReferrer);
                setBusinessUnitLocale(customerData.businessUnitLocale);

                customerData.isTestAccount !== undefined && setIsTestAccount(customerData.isTestAccount);

                // Currencies
                const baseCurrency = customerData.currenciesAvailable.baseCurrency;
                const counterCurrencies = customerData.currenciesAvailable.counterCurrency;
                const allCurrencies = [baseCurrency];

                if (counterCurrencies.length > 0) {
                    for (let i = 0; i < counterCurrencies.length; i++) {
                        if (customerData.businessUnit === 'default_channel' && !customerData.isTestAccount) {
                            counterCurrencies[i].code === "EUR" && allCurrencies.push(counterCurrencies[i]);
                        }
                        else {
                            allCurrencies.push(counterCurrencies[i]);
                        }

                    }
                }

                logInConsole("Currencies available :", "blue");
                logInConsoleObject(allCurrencies);
                setCurrenciesAllowed(allCurrencies);

                const companyAddresses = await getCompanyAddresses(auth.customerId, customerData.company.id);
                setCompanyAddresses(companyAddresses);

                const customerAddresses = await getCustomerAddresses(auth.customerId);
                setCustomerAddress(customerAddresses);

                const mailsPreference = await getMailPreferences(auth.customerId);
                mailsPreference.technicalDocsEmail !== null && setTechnicalDocsEmail(mailsPreference.technicalDocsEmail);
                mailsPreference.extraTechnicalDocsEmail !== null && setExtraTechnicalDocsEmail(mailsPreference.extraTechnicalDocsEmail);
                mailsPreference.orderConfirmationEmail !== null && setOrderConfirmationEmail(mailsPreference.orderConfirmationEmail);
                mailsPreference.extraOrderConfirmationEmail !== null && setExtraOrderConfirmationEmail(mailsPreference.extraOrderConfirmationEmail);
                mailsPreference.deliveryAndInvoiceEmail !== null && setDeliveryAndInvoiceEmail(mailsPreference.deliveryAndInvoiceEmail);
                mailsPreference.extraDeliveryAndInvoiceEmail !== null && setExtraDeliveryAndInvoiceEmail(mailsPreference.extraDeliveryAndInvoiceEmail);

                setLocalesList(mailsPreference.locales);
                setCompanyLocale(mailsPreference.companyLocale);

                const countriesList = await getCountries();
                setCountriesList(countriesList);
                setIsDataFullyLoaded(true);

            }
            catch (error) {
                logInConsole(`Error when obtaining customer infos`, 'red');
                //Test pour résoudre le problème de connexion refresh token
                AuthService.logout();
                setErrorBackendConnection(true);
            }
            finally {
                setConnectToBackend(false);
            }

        }
    }, [auth.customerId])



    /**
     * Setting a countries list depending on customer addresses
     * Avoiding order in a country where he got no address to display in cart
     */
    const filterCountriesOnAvailableAddresses = () => {
        logInConsoleSpacer();
        logInConsole("Filtering countries on available addresses", "goldenrod");

        const customerAndCompanyAddresses = customerAddress.concat(companyAddresses);
        let _availableCountries = []
        for (let _address in customerAndCompanyAddresses) {
            for (let _country in countriesList) {
                if (countriesList[_country].code === customerAndCompanyAddresses[_address].countryCode) {
                    if (!_availableCountries.includes(countriesList[_country])) {
                        _availableCountries.push(countriesList[_country])
                    }
                }
            }
        }

        logInConsole("Available countries for orders:", "green");
        logInConsoleObject(_availableCountries);
        setAvailableCountries(_availableCountries);
    }
    useEffect(() => {
        if(!isDataFullyLoaded){
            return
        }
        filterCountriesOnAvailableAddresses();
    }, [isDataFullyLoaded]);



    /**
     * Search for the country defined in the customer default address
     * @returns {{}}
     */
    const defaultCountryFromAddresses = () => {
        let defaultAddress = {}
        let defaultCountry = {}

        for (let i in customerAddress) {
            if (customerAddress[i].isDefaultAddress) {
                defaultAddress = customerAddress[i];
                break;
            }
        }

        for (let i in availableCountries) {
            if (defaultAddress.countryCode === availableCountries[i].code) {
                defaultCountry = availableCountries[i];
                break;
            }
        }

        return defaultCountry;
    }

    return (
        <UserContext.Provider value={{
            user, setUser,
            auth,
            isFirstConnection, setIsFirstConnection,
            connectToBackend, setConnectToBackend,
            errorBackendConnection, setErrorBackendConnection,
            activeCurrency, setActiveCurrency,
            currencyCode, setCurrencyCode,
            orderCurrency, setOrderCurrency,
            activeLanguage, setActiveLanguage,
            muiLocale, setMuiLocale,
            dataGridLocale, setDataGridLocale,
            customerInfo, setCustomerInfo,
            currenciesAllowed, setCurrenciesAllowed,
            businessUnit, setBusinessUnit,
            company, setCompany,
            companyId, setCompanyId,
            isVerified, setIsVerified,
            emailOfReferrer, setEmailOfReferrer,
            firstNameOfReferrer, setFirstNameOfReferrer,
            lastNameOfReferrer, setLastNameOfReferrer,
            firstName, setFirstName,
            lastName, setLastName,
            phoneNumber, setPhoneNumber,
            email, setEmail,
            newPassword, setNewPassword,
            isTestAccount, setIsTestAccount,
            businessUnitLocale,
            localesList,
            companyLocale, setCompanyLocale,
            customerMailPref, setCustomerMailPref,
            technicalDocsEmail, setTechnicalDocsEmail,
            extraTechnicalDocsEmail, setExtraTechnicalDocsEmail,
            orderConfirmationEmail, setOrderConfirmationEmail,
            extraOrderConfirmationEmail, setExtraOrderConfirmationEmail,
            deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail,
            extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail,
            countriesList, setCountriesList,
            availableCountries,
            customerAddress, setCustomerAddress,
            companyAddresses, setCompanyAddresses,
            addressId, setAddressId,
            companyAddr, setCompanyAddr,
            aliasAddr, setAliasAddr,
            firstNameAddr, setFirstNameAddr,
            lastNameAddr, setLastNameAddr,
            phoneNumberAddr, setPhoneNumberAddr,
            street, setStreet,
            postcode, setPostcode,
            city, setCity,
            countryCode, setCountryCode,
            isDefaultAddress, setIsDefaultAddress,
            isPrivateAddress, setIsPrivateAddress,
            isDataFullyLoaded,
            getAddress,getCompanyAddress,
            defaultCountryFromAddresses
        }} displayName="User">
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;
