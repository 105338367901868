import {Alert, Box, IconButton, Snackbar, Tab, Tabs} from "@mui/material";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import UserAccountSettings from "./components/user-account-settings";
import UserAddresses from "./components/user-addresses";
import UserQuotes from "./components/user-quotes";
import UserOrders from "./components/user-orders";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UserInformation = () => {
    const {t} = useTranslation();
    const [value, setValue] = useState(0);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const updateUrl = (onLoad) => {
        const currentUrl = location.pathname;

        if(!onLoad) {
            value === 0 && navigate("/user", {replace: true});
            value === 1 && navigate("/user/addresses", {replace: true});
            value === 2 && navigate("/user/quotes", {replace: true});
            value === 3 && navigate("/user/orders", {replace: true});
        } else {
            currentUrl === '/user' && setValue(0);
            currentUrl === '/user/addresses' && setValue(1);
            currentUrl === '/user/quotes' && setValue(2);
            currentUrl === '/user/orders' && setValue(3);
        }
    }
    useEffect(() => {
        updateUrl(true);
    }, []);
    useEffect(() => {
        updateUrl(false);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Box className="technical-data-card technical-data-card-column user-content-wrapper">

            {/*MENU*/}
            <Box className="user-content-menu">
                <Tabs value={value}
                      onChange={handleChange}
                      aria-label="user menu"
                      variant="scrollable"
                      scrollButtons={window.innerWidth < 1200}
                      allowScrollButtonsMobile
                >
                    <Tab disableRipple label={t("account.accountSettings.tabName")} {...a11yProps(0)}/>
                    <Tab disableRipple label={t("account.addresses.tabName")} {...a11yProps(1)}/>
                    <Tab disableRipple label={t("account.quotes.tabName")} {...a11yProps(2)}/>
                    <Tab disableRipple label={t("account.orders.tabName")} {...a11yProps(3)}/>
                </Tabs>
            </Box>

            {/*TABS*/}
            <TabPanel value={value} index={0}>
                <UserAccountSettings setErrorBackendConnection={setErrorBackendConnection}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserAddresses setErrorBackendConnection={setErrorBackendConnection}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <UserQuotes setErrorBackendConnection={setErrorBackendConnection}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <UserOrders setErrorBackendConnection={setErrorBackendConnection}/>
            </TabPanel>

            {/*SNACKBAR CONNECT TO BO FAILED*/}
            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={errorBackendConnection}
                      message="Connection with back office failed" key='topright'>
                <Alert severity="error" variant="filled">
                    {t("popup.failedConnectBackend")}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={() => setErrorBackendConnection(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default UserInformation;
