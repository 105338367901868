const generateNewAutoReference = (company) => {
    let date = new Date();

    //date formating by adding a '0' if below 10
    let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth()+1}` : (date.getMonth() + 1);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    // let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    // let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Mapping des chiffres en lettres
    const letterMapping = {
        '0': 'A',
        '1': 'B',
        '2': 'C',
        '3': 'D',
        '4': 'E',
        '5': 'F',
        '6': 'G',
        '7': 'H',
        '8': 'I',
        '9': 'J'
    };

    const letters = hours + minutes
            .split('')
            .map(digit => letterMapping[digit])
            .join('')
        + seconds;

    // return company + '--'
    //     + date.getFullYear() + '-' + month + '-' + day
    //     + '--'
    //     + hours + 'h'
    //     + minutes + 'm'
    //     + seconds + 's';

    return company + '-'
        + month + day
        + '-'
        + letters
}

export {
    generateNewAutoReference
}