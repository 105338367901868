import {createContext, useEffect, useState} from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState(JSON.parse(localStorage.getItem("auth")) || {});
    const [ persist, setPersist ] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    useEffect(()=> {

        if(Object.keys(auth).length !== 0)
        {
            let str = auth?.customerUrl;
            auth.customerId = str.substring(str.lastIndexOf('/')+1, str.length)
        }

        //TODO has been disabled due to showing password/ useful for refresh token
        // localStorage.setItem('auth', JSON.stringify(auth));

        //Replaced here to delete password
        const authDataToStore = {
            customerId: auth?.customerId,
            customerUrl: auth?.customerUrl,
            token: auth?.token,
            username: auth?.username
        };
        localStorage.setItem('auth', JSON.stringify(authDataToStore));

    }, [auth])

    useEffect(() => {
        localStorage.setItem("persist", String(persist))
    }, [persist])

    return (
        <AuthContext.Provider value={{auth, setAuth, persist, setPersist}} displayName="Authentication">
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
