import Box from "@mui/material/Box";
import {ButtonIcape, ButtonIcapeGreen, ButtonIcapeOutlined} from "../../button/button-icape";
import React from "react";
import {useTranslation} from "react-i18next";

const StepNavigation = (props) => {

    const {t} = useTranslation();

    const {checkoutProcess} = props.checkout
    const {isDesktop} = props.device
    const {checkIfAllFieldsAreFilled} = props.field
    const {gtmCalculatorStepEvent} = props.google
    const {isPcbAreaOversized} = props.pcb
    const {repeatOrderMode} = props.repeatOrderMode
    const {connectToBO} = props.service
    const {activeStep, setActiveStep, completed, setCompleted, steps, setIsStepOneToFinalize, stepOneToBeFilled} = props.step


    // HANDLE STEPS
    const totalSteps = () => {
        return steps.length;
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        console.log(completed);
        gtmCalculatorStepEvent();
    };
    const handlePrevious = () => {
        setActiveStep(activeStep - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setIsStepOneToFinalize(false)
        handleNext();
    };


    return(
        <>
            <Box className={isDesktop ? "step-cta-desktop" : "step-cta-mobile"}>

                {/* PREVIOUS not shown on step 01 and if step 01 is not finalize */}
                {activeStep !== 0 && checkIfAllFieldsAreFilled() && (
                    <ButtonIcapeOutlined
                        id="previousButton"
                        onClick={handlePrevious}>
                        {t("cta.previous")}
                    </ButtonIcapeOutlined>
                )}

                {/* FINALIZE STEP 01 : only on step 02 */}
                {activeStep === 1 && !checkIfAllFieldsAreFilled() && (
                    <ButtonIcape
                        id="finalizeStep1Button"
                        onClick={stepOneToBeFilled}>
                        {t("cta.finalizeStep1")}
                    </ButtonIcape>
                )}

                {/* NEXT */}
                {activeStep < steps.length - 1 && (
                    <>
                        <ButtonIcape
                            id="nextButton"
                            onClick={checkIfAllFieldsAreFilled() ? handleComplete : handleNext}
                            disabled={(activeStep === 1 && (!checkIfAllFieldsAreFilled() || connectToBO)) || isPcbAreaOversized}>
                            {t("cta.next")}
                        </ButtonIcape>
                    </>
                )}

                {/* Only in step 3 */}
                {activeStep === steps.length - 1 && (
                    <>
                        {/*DOWNLOAD QUOTE*/}
                        <ButtonIcapeGreen
                            id="downloadQuote"
                            onClick={e => checkoutProcess(e.target.id)}
                            disabled={(activeStep === 1 && !checkIfAllFieldsAreFilled()) || isPcbAreaOversized || connectToBO ||(repeatOrderMode.background && !repeatOrderMode.lockPrice) }>
                            {t("cta.downloadMyQuote")}
                        </ButtonIcapeGreen>

                        {/*ORDER NOW*/}
                        <ButtonIcape
                            id="orderNow"
                            onClick={e => checkoutProcess(e.target.id)}
                            disabled={(activeStep === 1 && !checkIfAllFieldsAreFilled()) || isPcbAreaOversized || connectToBO}>
                            {t("cta.orderNow")}
                        </ButtonIcape>
                    </>
                )}
            </Box>
        </>
    )
}

export default StepNavigation