const getStorageValue = (key) => {
    if (typeof window !== "undefined") {
        const itemInLS = localStorage.getItem(key);
        return itemInLS !== null ? JSON.parse(itemInLS) : null;
    }
}

/**
 * Remove all sensible localstorage object
 */
const cleanUpLocalStorage = () => {
    let keysToRemove = ["aluminum", "cart", "designMyPanel", "flex", "hdi", "rigid"];
    for (let key of keysToRemove) {
        localStorage.removeItem(key);
    }
}

export {
    getStorageValue, cleanUpLocalStorage
}
