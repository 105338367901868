import * as React from "react";
import {useEffect, useState} from "react";
import {styled} from "@mui/material";

import PropTypes from 'prop-types';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateCalendar, LocalizationProvider} from '@mui/x-date-pickers';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';

import {addBusinessDays, addDays, differenceInDays, isValid, isWeekend, nextMonday, parseISO} from "date-fns";
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import {logInConsole} from "../../../tools/debug";


//SETTING COLORS FOR THE CALENDAR
const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'isShippingDay' && prop !== 'isWeekEnd' && prop !== 'isSelected' && prop !== 'isHoliday'
})(({theme, day, dayIsBetween, isFirstDay, isLastDay, isShippingDay, isWeekEnd, isSelected, isHoliday}) => ({
    ...(day && {
        width: '40px'
    }),
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: '#007DC8',
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        backgroundColor: '#007DC8',
        color: theme.palette.common.white,
        border: 'none !important',
        borderColor: 'white !important'
    }),
    ...(isLastDay && {
        // backgroundColor: '#00588C',
        borderTopRightRadius: '0%',
        borderBottomRightRadius: '0%',
    }),
    ...(isShippingDay && {
        backgroundColor: '#0d4a73',
        color: theme.palette.common.white
    }),
    ...(isWeekEnd && dayIsBetween && {
        backgroundColor: '#E6F2F8',
        border: 'none'
    }),
    ...(isSelected && {
        backgroundColor: '#BAE19F !important',
        color: '#353535 !important',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        borderTopLeftRadius: '0%',
        borderBottomLeftRadius: '0%',
    }),
    ...(isHoliday && {
        backgroundColor: '#80475E',
        color: '#FFFFFF'
    }),
}));

const CalendarIcape = (props) => {
    const calendar = props.calendar;
    const minDeliveryDate = props.minDeliveryDate
    const {requestedDate, setRequestedDate} = props.request;
    const [deliveryDate, setDeliveryDate] = useState(new Date(requestedDate));

    useEffect(() => {
        if (minDeliveryDate === '' || minDeliveryDate === null || requestedDate === '' || requestedDate === null) {
            return
        }
        if (new Date(requestedDate) < new Date(minDeliveryDate)) {
            logInConsole(`min delivery date has change : ${minDeliveryDate} (req:${requestedDate})`, 'olive');
            handleRequestedDate(new Date(minDeliveryDate));
        }
    }, [minDeliveryDate])

    // CALENDAR SETTINGS
    function disableDates(date) {
        return date.getDay() === 0 || date.getDay() === 6 || date < minDeliveryDate || calendar?.holidays?.includes(formatDate(date));
    }
    function renderDay(props) {
        const {day, selectedDay, ...other} = props;

        if (!selectedDay) {
            return <PickersDay day={day}{...other} />;
        }


        const today = new Date();
        let start;
        if(isWeekend(today))
        {
            start = nextMonday(today);
        }
        else
        {
            start = today;
        }

        const end = addDays(start, differenceInDays(deliveryDate, start));

        let calendarDeliveryStart = addBusinessDays(new Date(), 2);
        let calendarDeliveryEnd = addBusinessDays(new Date(), 6);
        let calendarHolidays = false;

        if (Object.keys(calendar).length > 0){
            calendarDeliveryStart = parseISO(calendar?.delivery?.start);
            calendarDeliveryEnd = parseISO(calendar?.delivery?.end);
            calendarHolidays = calendar?.holidays.includes(formatDate(day));
        }

        const dayIsBetween = isWithinInterval(day, {start, end});
        const isFirstDay = isSameDay(day, start);
        const isLastDay = isSameDay(day, end);
        const isWeekEnd = isWeekend(day);
        const isSelected = isSameDay(day, selectedDay);
        const isShippingDay = isWithinInterval(day, {
            start: calendarDeliveryStart,
            end: calendarDeliveryEnd
        })
        const isHoliday = calendarHolidays;


        return (
            <CustomPickersDay
                {...other}
                day={day}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                isShippingDay={isShippingDay}
                isWeekEnd={isWeekEnd}
                isSelected={isSelected}
                isHoliday={isHoliday}
            />
        );
    }
    renderDay.propTypes = {
        /**
         * The date to show.
         */
        day: PropTypes.object.isRequired,
        selectedDay: PropTypes.object,
    };

    /**
     * Convert date to correct string.
     * Js date needs ISO format and avoid UTC ISSUES
     * @param date
     * @returns {string}
     */
    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    const handleRequestedDate = (date) => {
        if (date === null || !isValid(date)) {
            return
        }
        date.setHours(9);
        setDeliveryDate(date);
        setRequestedDate(date);
    }




    return (
        <LocalizationProvider adapterLocale={props.muiLocale} dateAdapter={AdapterDateFns}>
            <DateCalendar
                onChange={(newDeliveryDate) => {
                    handleRequestedDate(newDeliveryDate);
                    props.updateRepeatOrder();
                }}
                shouldDisableDate={disableDates}
                value={deliveryDate}
                slots={{day: renderDay}}
                slotProps={{
                    day: {
                        selectedDay: deliveryDate,
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default CalendarIcape;