import {useContext, useEffect, useState} from "react";
import axios from 'axios';
import {formatDuration, intervalToDuration} from "date-fns";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import CalculatorAluminumContext from "../context/calculatorAluminumProvider";
import CalculatorHdiContext from "../context/calculatorHdiProvider";
import CalculatorFlexContext from "../context/calculatorFlexProvider";
import CalculatorRigidContext from "../context/calculatorRigidProvider";
import {getSelectorIdFromTechnoAndNbLayers} from "../tools/correspondence-technology";
import {ErrorHandling} from "../tools/error-handling";
import UserContext from "../context/UserProvider";
import useAxiosPrivate from "./useAxiosPrivate";

const useJayda = (props) => {
    const DEBUG = true;
    const API_BASE_URL = process.env.REACT_APP_JAYDA_API_BASE_URL;
    const WEBSOCKET_URL = process.env.REACT_APP_JAYDA_WEBSOCKET_URL;
    const axiosPrivate = useAxiosPrivate();

    const calculatorContexts = {
        "aluminum": CalculatorAluminumContext,
        "hdi": CalculatorHdiContext,
        "flex": CalculatorFlexContext,
        "rigid": CalculatorRigidContext
    };
    const calculatorUsed = calculatorContexts[props] || CalculatorRigidContext;
    const {customerInfo} = useContext(UserContext);


    let {jobId} = useContext(calculatorUsed);
    const {
        setJobParams,
        pcbImagesUrls, setPcbImagesUrls,
        setAnalyzeInProgress,
        progress, setProgress,
        setIsErrorConnectToJayda
    } = useContext(calculatorUsed);

    let updateProgress;
    let startTime;
    let stopTime = null;
    let nbLayers = null;
    let fileName;

    const [progressValue, setProgressValue] = useState(0);

    const log = (message) => {
        if (DEBUG) {
            logInConsole(message, 'Teal')
        }
    }
    const axiosPrivateJayda = axios.create({
        baseURL: API_BASE_URL,
        // temporary put in comment. Ucamco needs to find the solution on server part
        headers: {
            // 'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_JAYDA_API_KEY
        },
        withCredentials: false
    });
    //https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
    //when responding to a credentialed request, server must specify a domain, and cannot use wild carding.
    // The above example would fail if the header was wildcarded as: Access-Control-Allow-Origin: *.

    const sendJob = async (job) => {
        log('[useJayda] send job ', job);
        let bodyFormData = new FormData();
        bodyFormData.append('fileupload', job[0]);
        bodyFormData.append('username',"newishop_client");//Temporary line due to Jayda's latest update.
        log(job[0].path);
        fileName = job[0].path;

        // temporary put in comment. Ucamco needs to find the solution on server part
        let headers = {
            // 'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_JAYDA_API_KEY
        }
        let requestOptions = {
            headers,
            redirect: 'follow'
        }

        //CHRONOS
        let uploadStartTime = new Date();
        let UploadStopTime;
        startTime = new Date();

        let isConnectionError = false
        await axios.post(API_BASE_URL + '/submit', bodyFormData, requestOptions).then(
            (response) => {
                //stop chrono
                UploadStopTime = new Date();
                let debugMessage = `=> UPLOAD DURATION : ${formatDuration(intervalToDuration({
                    start: uploadStartTime,
                    end: UploadStopTime
                }), {format: ['minutes', 'seconds']})}`;
                log(debugMessage);
                jobId = response.data.data.jobNr;
                setAnalyzeInProgress('inProgress');
                return parseInt(response.data.data.jobNr);
            }, (error) => {
                //stop chrono
                UploadStopTime = new Date();
                let debugMessage = `=> UPLOAD DURATION(error) : ${formatDuration(intervalToDuration({
                    start: uploadStartTime,
                    end: UploadStopTime
                }), {format: ['minutes', 'seconds']})}`;
                log(debugMessage);
                log('error in send job', error);
                //@TODO Throw error to global app
                setIsErrorConnectToJayda(true);
                isConnectionError = true
                return -1;
            })
            .then((response) => {
                !isConnectionError && connectToWS();
            })
    }
    const connectToWS = () => {

        let socket = new WebSocket(WEBSOCKET_URL);

        socket.onopen = (message) => {
            log("socket onopen message : ", message);
            updateTrackingGaydaEvents("socket onOpen");
        }
        socket.onclose = (message) => {
            log("socket onclose message ", message);
        }
        socket.onerror = (error) => {
            log('socket onERROR', error);
            updateTrackingGaydaEvents("socket onError");
        }

        socket.onmessage = function (message) {
            let data = JSON.parse(message.data);
            console.log(data)
            if (parseInt(data["jobId"]) === jobId) {
                if (data["msgType"] === 'import') {
                    log('importing job ...');
                    startTime = new Date();
                    updateProgress = setInterval(getProgression, 1000);
                    updateTrackingGaydaEvents("socket onOpen");
                }
                if (data['msgType'] === 'dbparamschanged') {
                    log('dbparamschanged -> api request for new data');
                    getParams(jobId);
                    updateTrackingGaydaEvents("dbparamschanged");
                }
                if (data['msgType'] === 'analysis') {
                    getPcbScan(jobId);
                    updateTrackingGaydaEvents("analysis");
                }
                if (data['msgType'] === 'jobfinished') {
                    clearInterval(updateProgress);
                    setProgress(100);
                    setAnalyzeInProgress('ended');
                    socket.close();
                    stopTime = new Date();
                    let debugMessage = `=> DURATION OF ANALYSIS = jobId: ${jobId} - duration: ${formatDuration(intervalToDuration({
                        start: startTime,
                        end: stopTime
                    }), {format: ['minutes', 'seconds']})}`;
                    log(debugMessage);
                    setJobParams({});
                    updateTrackingGaydaEvents("jobfinished");
                }
            }
        };
    }
    const getPcbScan = async (jobId) => {

        let pcbImages = {};

        await axiosPrivateJayda.get(API_BASE_URL + `/job/${jobId}/image/bottom/b64`).then(
            (response) => {
                pcbImages = {
                    ...pcbImages,
                    "bottom": response.data.data.image
                }
                console.log("bottom: ",response.data.data);
            },
        (error) => {
                log('error in getPcbScan - bottom : ', error)
            }
        );
        await axiosPrivateJayda.get(API_BASE_URL + `/job/${jobId}/image/stackup/b64`).then(
            (response) => {
                pcbImages = {
                    ...pcbImages,
                    "stackup": response.data.data.image
                }
                console.log("stackup: ",response.data.data);
            },
            (error) => {
                log('error in getPcbScan - stackup : ', error)
            }
        );
        await axiosPrivateJayda.get(API_BASE_URL + `/job/${jobId}/image/top/b64`).then(
            (response) => {
                pcbImages = {
                    ...pcbImages,
                    "top": response.data.data.image
                }
                console.log("top: ",response.data.data);
            },
            (error) => {
                log('error in getPcbScan - top : ', error)
            }
        );
        setPcbImagesUrls(pcbImages);
    }
    const getParams = async (jobId) => {
        let params;
        await axiosPrivateJayda.get(API_BASE_URL + `/job/${jobId}/param/all`).then(
            (response) => {
                if (response.data.status === 'success') {
                    log('data from API');
                    logInConsoleObject(response.data.data);
                    params = convertJaydaDataToAppRequierments(response.data.data);
                    setJobParams(params);
                    nbLayers = response.data.data.NumCopperLayers.computed;
                } else {
                    params = 'Error : Jayda s API don t send a success value';
                }
            },
            (error) => {
                log('error in getParams : ', error)
                params = error;
            }
        );
        return params;
    }

    /**
     *
     * @param data received from API call
     * @returns object reformated with selector ID
     */
    const convertJaydaDataToAppRequierments = (data) => {

        let convertObj = {};

        convertObj.pcbWidth = data?.XDimensionMM.computed;
        convertObj.pcbLength = data?.YDimensionMM.computed;

        //props = rigid, aluminum, flex or hdi
        convertObj.technologyId =  getSelectorIdFromTechnoAndNbLayers(props, data?.NumCopperLayers.computed);

        convertObj.smallestDrillingHoleSizeId = (parseFloat(data?.MinPlatedHoleDiaMM.computed) > 0.2) ? 1 : 2;

        let solderMaskId;
        switch (data?.NumSolderMaskLayers.computed) {
            case "0" :
                solderMaskId = 1;
                break;
            case "1" :
                solderMaskId = 2;
                break;
            case "2" :
                solderMaskId = 4;
                break;
            default:
                solderMaskId = 1;
        }
        convertObj.solderMaskId = solderMaskId;
        convertObj.tracksWidthSpaceId = (parseFloat(data?.MinTrackWidthOuterMM.computed) * 1000 > 100) ? 1 : 2;
        convertObj.carbonId = parseInt(data?.NumCarbonLayers.computed) > 0 ? 2 : 1;

        let legendId;
        switch (data?.NumLegendLayers.computed) {
            case "0" :
                legendId = 1;
                break;
            case "1" :
                legendId = 2;
                break;
            case "2" :
                legendId = 4;
                break;
            default:
                legendId = 1;
        }
        convertObj.legendId = legendId;

        return convertObj;
    }

    const getProgression = async () => {

        await axiosPrivateJayda.get(API_BASE_URL + `/job/${jobId}/progress`).then(
            (response) => {
                if (response.data.status === 'success') {
                    setProgressValue(response.data.data.progress);
                    if (response.data.data.progress !== -1) {
                        setProgressValue(response.data.data.progress);
                    }
                } else {
                    setProgressValue('Error : Jayda s API don t send a success value');
                }
            },
            (error) => {
                log('error in getProgression : ', error)
                setProgressValue(error);
            }
        );
        return progressValue;
    }
    useEffect(() => {
        //log(`Jayda's progress: ${progressValue} - Total progress : ${progress}`);
        if (progressValue > progress) {
            setProgress(progressValue);
        }
    },[progressValue]);


    const updateTrackingGaydaEvents = (_message) => {
        logInConsoleSpacer();
        logInConsole('Try to POST tracking data to backend', 'darkmagenta', 'bold');

        let config = {headers: {"Content-Type": "application/json"}};
        const date = new Date()
        const dataTrackingGayda = {
            'jobId': jobId,
            'filename': fileName,
            'date': date.toISOString(),
            'message': _message,
            'startTime': startTime.toISOString(),
            'stopTime': stopTime === null ? null : stopTime.toISOString(),
            'company': customerInfo.company.id,
            'customer': customerInfo.id,
            'calculator': props,
            'nbLayers': nbLayers,
        }

        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/tracking/jayda`, JSON.stringify(dataTrackingGayda), config)
            .then((response) => {
                logInConsole('Tracking data sent to backend', 'green');
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                logInConsoleObject(resMessage);
                ErrorHandling(props, resMessage);
            })
            .finally(() => {
            })
    }


    return {
        sendJob,
        getParams,
        jobId,
        //jobParams,
        //analyzeInProgress,
        //progress,
    }
    //return useContext(JaydaContext);
}

export default useJayda;
