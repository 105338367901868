import React, {useContext, useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography
} from "@mui/material";
import './user.scss';
import Person from '@mui/icons-material/Person';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import {ButtonIcapeOutlinedRed, ButtonIcapeSoftBlue} from "../button/button-icape";
import {useTranslation} from "react-i18next";
import UserContext from "../../context/UserProvider";
import useAuth from "../../hooks/useAuth";


function UserSidebar() {
    const {t} = useTranslation();
    const {auth} = useAuth();

    const {
        company,
        isVerified,
        emailOfReferrer,
        firstNameOfReferrer,
        lastNameOfReferrer,
    } = useContext(UserContext);

    // DELETE GDPR POPUP MANAGER
    const [isGdprDeleteDialogOpen, setIsGdprDeleteDialogOpen] = useState(false);
    const [isGdprDeleted, setIsGdprDeleted] = useState(false);

    const handleDeleteGdprData = () => {

        //TODO DELETE USER DATA FOR REAL
        setIsGdprDeleteDialogOpen(false);
        setIsGdprDeleted(true);
    }

    /*ADMIN USER PHOTO*/
    const [isAdminPhoto, setIsAdminPhoto] = useState(false);
    const [adminPhotoUrl, setAdminPhotoUrl] = useState("");
    useEffect(() => {
        switch(auth.username) {
            case "alrick.rodari@icape-group.com":
                setIsAdminPhoto(true);
                setAdminPhotoUrl("/assets/admin-users/alrick-shop.png");
                break;

            case "ikram.chehouani@icape.fr":
                setIsAdminPhoto(true);
                setAdminPhotoUrl("/assets/admin-users/ikram.png");
                break;

            default:
                setIsAdminPhoto(false);
                break;
        }
    },[])

    return (
        <Box className="technical-data-card technical-data-card-column user-sidebar-wrapper">

            {/*AVATAR AND NAME*/}
            <Box className="user-sidebar-avatar">
                {/*TODO REPLACE TO CUSTOMER AVATAR CUSTOMIZATION*/}
                <Avatar alt="user's avatar">
                    {isAdminPhoto ?
                            <img src={adminPhotoUrl} alt="Admin user photo" style={{width: '200px'}}/>
                        :
                            <Person/>
                    }
                </Avatar>
                <Typography variant={"h1"}>{company}</Typography>
                {isVerified ?

                        <Typography id="ac-verified">
                            {t("account.sidebar.accountVerified")}
                            <CheckCircleIcon/>
                        </Typography>

                :

                    <Typography id="ac-not-verified">
                        {t("account.sidebar.notVerified")}
                        <HourglassEmptyIcon/>
                    </Typography>

                }

            </Box>

            <Divider/>

            {/*SALE REP*/}
            {isVerified &&
                <Box className={"user-sidebar-sales-info"}>
                    <Typography variant={"h2"}>
                        {t("common.referringSale")}
                    </Typography>
                    <Typography component={"span"}>
                        <WorkOutlineIcon />
                        {(firstNameOfReferrer !== "" && firstNameOfReferrer !== undefined) ? `${firstNameOfReferrer} ${lastNameOfReferrer}` : "No referring sale yet"}
                    </Typography>
                    <Typography component={"span"}>
                        <MailOutlineIcon />
                        {(emailOfReferrer !== "" && emailOfReferrer !== undefined) ? emailOfReferrer : "No referring sale yet"}
                    </Typography>
                    {/*TODO: manage phone in back*/}
                    {/*<Typography component={"span"}>*/}
                    {/*    <SmartphoneIcon />*/}
                    {/*    +33 1 06 82 73 46*/}
                    {/*</Typography>*/}
                </Box>
            }


            {/*GDPR*/}
            {/*<Box className={"user-sidebar-gdpr"}>*/}
            {/*    /!*TODO download GDPR data*!/*/}
            {/*    <Typography component={"span"}>*/}
            {/*        <DownloadIcon/>*/}
            {/*        {t("account.sidebar.downloadData")}*/}
            {/*    </Typography>*/}
            {/*    /!*TODO delete data*!/*/}
            {/*    <Typography component={"span"} onClick={ () => setIsGdprDeleteDialogOpen(true)}>*/}
            {/*        {t("account.sidebar.deleteData")}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            {/*GDPR DELETE POPUP MODAL*/}
            <Dialog open={isGdprDeleteDialogOpen} onClose={ () => setIsGdprDeleteDialogOpen(false)}>
                <DialogTitle>
                    {t("popup.deleteData.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("popup.deleteData.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeOutlinedRed onClick={ handleDeleteGdprData }>
                        {t("cta.delete")}
                    </ButtonIcapeOutlinedRed>
                    <ButtonIcapeSoftBlue  onClick={ () => setIsGdprDeleteDialogOpen(false)}>
                        {t("cta.cancel")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>

            {/*GDPR DELETE POPUP MODAL CONFIRMATION*/}
            <Dialog open={isGdprDeleted} onClose={ () => setIsGdprDeleted(false) }>
                <DialogTitle>
                    {t("popup.deleteDataConfirmation.title")}
                    <DoneIcon />
                </DialogTitle>
                <DialogActions>
                    <ButtonIcapeSoftBlue  onClick={ () => setIsGdprDeleted(false) }>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>

        </Box>
    )
}
export default UserSidebar;
