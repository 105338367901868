import {Box} from "@mui/material";
import UserSidebar from "./user-sidebar";
import UserInformation from "./user-information/user-information";

const userAccount = () => {
    return (
        <Box className="user-account-wrapper">
            <UserSidebar/>
            <UserInformation/>
        </Box>
    )
}

export default userAccount;
