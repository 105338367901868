import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import './checkout.scss';
import {ButtonIcape} from "../button/button-icape";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

function PaymentRefused() {
    const {t} = useTranslation();

    return (
        <Box className="checkout-card">
            <Typography variant={"h1"}>
                <ErrorIcon className={"checkout-icon"} sx={{fontSize: "40px"}}/>
                {t("checkout.paymentRefused.title")}
            </Typography>

            <span>
                {t("checkout.paymentRefused.description")}
            </span>
            <span>
                {t("checkout.paymentRefused.subDescription")}
            </span>

            <Link to="/">
                <ButtonIcape>
                    {t("cta.home")}
                </ButtonIcape>
            </Link>
        </Box>
    )
}

export default PaymentRefused
