import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Chip,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography
} from "@mui/material";
import './video-updates.scss';
import WebIcon from '@mui/icons-material/Web';
import CommitIcon from '@mui/icons-material/Commit';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import {useTranslation} from "react-i18next";
import useVideoUpdates from "../../hooks/useVideoUpdates";
import {useNavigate, useSearchParams} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


function VideoUpdates() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();


    /**
     * RESPONSIVE FUNCTION FOR WINDOW WIDTH
     */
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }


    /**
     * DRAWER SETTINGS
     */
    const [isSecondaryDrawerOpen, setIsSecondaryDrawerOpen] = useState(windowSize.innerWidth >= 1200);
    useEffect(() =>{
        windowSize.innerWidth >= 1200 ? setIsSecondaryDrawerOpen(true) :setIsSecondaryDrawerOpen(false)
    },[windowSize.innerWidth])


    /**
     * Getting month name
     * @param _monthNumber
     * @returns {string}
     */
    const getMonthName = (_monthNumber) => {
        const months = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        return months[parseInt(_monthNumber, 10) - 1];
    };
    /**
     * Get month index
     */
    const getMonthIndex = (monthName) => {
        const months = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        return months.indexOf(monthName);
    };

    /**
     * When video updates list is loaded
     * Check if URL got params to display the selected one
     * If not, displays the latest one
     */
    const {videoUpdatesList, isVideoUpdateLoading} = useVideoUpdates();
    const [updateActive, setUpdateActive] = useState([]);
    const [yearsAvailable, setYearsAvailable] = useState([])
    useEffect(() => {
        if (videoUpdatesList.length > 0) {
            // Check if URL got ID params and existing in the list ( !== -1)
            if (searchParams.get('id') !== null && videoUpdatesList.findIndex(video => video.id === parseInt(searchParams.get('id'))) !== -1) {
                const index = videoUpdatesList.findIndex(video => video.id === parseInt(searchParams.get('id')))
                setUpdateActive(videoUpdatesList[index]);
                setAccordionExpanded(`panel-${videoUpdatesList[index].date.substring(0, 4)}`);
            }

            // Displays the latest one and if params ID doesn't exist in the list, remove the params
            else {
                setUpdateActive(videoUpdatesList[0]);
                if (videoUpdatesList.findIndex(video => video.id === parseInt(searchParams.get('id'))) === -1) {
                    searchParams.delete('id');
                    navigate({ search: searchParams.toString() }, { replace: true });
                    setAccordionExpanded(`panel-${videoUpdatesList[0].date.substring(0, 4)}`);
                }
            }


            // Setting up the year / month display
            // Réduction pour transformer le tableau initial
            const result = videoUpdatesList.reduce((acc, item) => {
                const year = item.date.substring(0, 4);
                const month = getMonthName(item.date.substring(5, 7));

                // Rechercher l'année dans le tableau accumulé
                const existingYear = acc.find(entry => entry.year === parseInt(year));

                if (existingYear) {
                    // Ajouter le mois s'il n'existe pas déjà
                    existingYear.months.add(month);
                } else {
                    // Créer une nouvelle entrée pour l'année
                    acc.push({ year: parseInt(year), months: new Set([month]) });
                }

                return acc;
            }, []);

            // Transformer les Set en array pour un affichage final lisible
            const finalResult = result.map(item => ({
                year: item.year,
                months: Array.from(item.months).sort((a, b) => getMonthIndex(b) - getMonthIndex(a))
            }));
            setYearsAvailable(finalResult);
        }
    }, [videoUpdatesList]);


    /**
     * HANDLE ACTIVE VERSION OF VIDEO UPDATES
     * @param index
     */
    const handleVersionActive = (index) => {
        setUpdateActive(videoUpdatesList[index]);
        searchParams.set('id', videoUpdatesList[index].id);
        navigate({ search: searchParams.toString() }, { replace: true });

        if (windowSize.innerWidth < 1200) {
            setIsSecondaryDrawerOpen(!isSecondaryDrawerOpen)
        }
    }


    const [accordionExpanded, setAccordionExpanded] = useState("");
    const handleChange = (_panel) => (event, newExpanded) => {
        setAccordionExpanded(newExpanded ? _panel : false);
    };

    const chipBackground = {
        Fix: "purple",
        New: "green",
        Deprecation: "grey",
        Improvement: "blue"
    }

    return (
        <Box className="library-global-wrapper">

            {/*MENU*/}
            <Box className={isSecondaryDrawerOpen ? "library-list-wrapper" : "library-list-wrapper library-list-closed"}>
                {/*DRAWER MENU*/}
                <Box className="drawer-menu">
                    <Typography variant="h2">
                        All updates
                    </Typography>
                    <WebIcon onClick={() => setIsSecondaryDrawerOpen(!isSecondaryDrawerOpen)} className="library-list-icon"/>
                </Box>
                <Divider/>

                {/*RELEASE NOTES LIST*/}
                <Box className="drawer-list">

                    {!isVideoUpdateLoading && yearsAvailable.map((year, index) => (

                        <React.Fragment key={index}>
                            <Accordion expanded={accordionExpanded === `panel-${year.year}`}
                                       onChange={handleChange(`panel-${year.year}`)}
                                       className="main-accordion">

                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                                  aria-controls={`panel-${year.year}-content`}
                                                  id={`panel-${year.year}-header`}>
                                    {year.year}
                                </AccordionSummary>

                                <AccordionDetails>

                                    {
                                        (year.months).map((month, index) => (
                                            <React.Fragment key={index}>
                                                <Accordion defaultExpanded className="secondary-accordion">

                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        {t(`months.${month}`)}
                                                    </AccordionSummary>

                                                    <AccordionDetails>
                                                        <List>
                                                            {videoUpdatesList?.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        getMonthName(item.date.substring(5, 7)) === month &&
                                                                        <>
                                                                            <ListItem disablePadding>
                                                                                <ListItemButton disableRipple onClick={() => handleVersionActive(index)}>
                                                                                    <ListItemIcon><CommitIcon /></ListItemIcon>
                                                                                    <ListItemText primary= { item.title } />
                                                                                    {/*<ListItemText primary= { t(`videoUpdates.${item.id}.title`) } />*/}
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        </>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </List>
                                                    </AccordionDetails>

                                                </Accordion>
                                            </React.Fragment>
                                        ))
                                    }

                                </AccordionDetails>

                            </Accordion>
                        </React.Fragment>

                    ))}

                    {/*<span>I wasn't born yet</span>*/}
                </Box>
            </Box>

            {/*CONTENT*/}
            <Box className="library-display-wrapper">
                {/*TITLE*/}
                <Box className="library-display-title-wrapper">
                    <OndemandVideoTwoToneIcon/>
                    <Box sx={{width:"100%"}}>
                        {isVideoUpdateLoading ? <Skeleton variant="rounded" width="5%" height="10px"/> : <Chip className={chipBackground[updateActive?.type]} label={updateActive?.type} size="small" />}
                        <span>
                            {isVideoUpdateLoading ? <Skeleton variant="rounded" width="10%" height="10px"/> : updateActive?.date}
                        </span>
                        <Typography variant="h1">
                            {isVideoUpdateLoading ? <Skeleton variant="rounded" width="40%" height="40px"/> : updateActive?.title}
                            {/*{isVideoUpdateLoading ? <Skeleton variant="rounded" width="40%" height="40px"/> : t(`videoUpdates.${updateActive?.id}.title`)}*/}
                        </Typography>
                    </Box>
                </Box>

                {/*VIDEO UPDATE*/}
                <Box className="video-container" sx={{marginTop: '50px'}}>
                    {
                        isVideoUpdateLoading ?
                            <Skeleton variant="rounded" width="800px" height="400px"/>
                            :
                            <>
                                {
                                    updateActive?.videoUrl !== "No video available" &&
                                    <video controls controlsList="nodownload" loop={false} autoPlay={true} muted={true}
                                           key={updateActive?.videoUrl}>
                                        <source
                                            src={process.env.REACT_APP_BACKEND_BASE_URL + '/' + updateActive?.videoUrl}
                                            type="video/mp4"/>
                                    </video>
                                }
                            </>
                    }
                </Box>

                {/*OVERVIEW*/}
                <Box className="library-display-overview-wrapper">
                    {updateActive?.videoUrl !== "No video available" && <Divider/>}
                    <Box className="library-display-content">
                        <Typography component="h2" variant="h2">
                        {isVideoUpdateLoading ? <Skeleton variant="rounded" width="40%" height="30px"/> : "Overview"}
                        </Typography>
                        <Typography component={"p"} className="version-overview" sx={{whiteSpace: "pre-line"}}>
                            {isVideoUpdateLoading ? <Skeleton variant="rounded" height="120px"/> : updateActive?.description}
                            {/*{isVideoUpdateLoading ? <Skeleton variant="rounded" height="120px"/> : t(`videoUpdates.${updateActive?.id}.description`)}*/}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default VideoUpdates;