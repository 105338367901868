import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import UserContext from "../../context/UserProvider";
import {useTranslation} from "react-i18next";


export const AnnouncementBar = (props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {setIsFirstConnection} = useContext(UserContext);
    const isEnabled = props.isEnabled
    const auth = props.auth;
    const isVerified = props.isVerified;

    const handleFirstConnection = () => {
        setIsFirstConnection(true);
        navigate('/login');
    }


    return(
        <>
            {isEnabled &&
                <Box className={`announcement-bar-wrapper ${auth.username === undefined ? "blue-bar" : "yellow-bar"}`}>
                    <Box className={`announcement-bar ${auth.username === undefined ? "blue-bar" : "yellow-bar"}`}>
                        {
                            auth.username === undefined ?
                                <span onClick={handleFirstConnection}>
                                {t("announcements.firstLogin")}
                            </span>
                                :
                                <span>
                                {/*{t("announcements.migrationDelay")}*/}
                                    {/*<br/>*/}
                                    {/*{t("announcements.ordersLeft")}*/}
                                    {t("announcements.ordersMigrated")} 🎉
                            </span>
                        }
                    </Box>
                </Box>
            }
        </>
    )
}
