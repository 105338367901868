import React, {useContext, useEffect} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import './step-3.scss';
import {Info} from "@mui/icons-material";
import CalculatorFlexContext from "../../../../context/calculatorFlexProvider";
import {format, parseISO} from "date-fns";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import StencilFields from "../../components/stencil-fields";
import CommentField from "../../components/comment-field";
import CalendarIcape from "../../components/calendar-icape";
import {localeConverter} from "../../../../tools/localeConverter";

function Step3(props) {
    const {t} = useTranslation();
    const {availableCountries, muiLocale, localesList} = useContext(UserContext);

    const updateRepeatOrder = props.updateRepeatOrder
    const {setIsExcludingPriceChangeFieldsUpdated} = props.setIsExcludingPriceChangeFieldsUpdated

    const {
        repeatOrderMode, isLockFields,
        shippingMethodId, setShippingMethodId,
        country, setCountry,
        calendar,
        requestedDate, setRequestedDate,
        minDeliveryDate,
        daysToDelivery, productionTime,
        totalLeadTime, setTotalLeadTime,
        unitFormat,
        comment, setComment,
        priceOfferLocale, setPriceOfferLocale,
    } = useContext(CalculatorFlexContext);

    const formatDate = (date) => {
        return format(new Date(date), 'PPPP', { locale: muiLocale });
    }


    /**
     * Find and set the country from its id
     * Mui can't compare an object with the coutries list result. so it's work when selected but not at boot
     * this function avoid an empty field at boot even if the country useState is set
     * @param _countrySelectedId
     */
    const handleCountryChosen = (_countrySelectedId) => {
        for(let i in availableCountries) {
            if (availableCountries[i].id === _countrySelectedId) {
                setCountry(availableCountries[i]);
            }
        }
    }

    return (
        <>
            <Box className="technical-data-card-column step-3-options">
                {/*STENCIL*/}
                <StencilFields
                    fields={{}}
                    isStencil = {false}
                    isActive = {true}
                    repeatOrder={{}} />


                {/*COMMENT*/}
                <CommentField
                    fields={{comment, setComment, isLockFields, setIsExcludingPriceChangeFieldsUpdated}}
                    repeatOrder={{repeatOrderMode}}
                    isActive = {true} />
            </Box>

            {/*DELIVERY*/}
            <Box className="technical-data-card delivery-form-settings">
                <Typography variant={'h3'}>
                    {t("common.delivery")}
                </Typography>
                <Typography component={'span'} className="calendar-helper-info">
                    {t("calculators.step3.calendar.chooseDeliveryDate")}
                </Typography>
                <Box className={"delivery-picker-wrapper"}>
                    {/*DELIVERY FIELDS*/}
                    <Box className="delivery-selector-info">
                        <Box className={"delivery-box-fields"}>

                            {/*TOTAL LEAD TIME*/}
                            {/*
                            <FormControl>
                                <InputLabel id="totalLeadTime-label">Total lead time</InputLabel>
                                <Select
                                    labelId="totalLeadTime"
                                    id="totalLeadTime"
                                    value={totalLeadTime}
                                    label="Total lead time"
                                    onChange={(e) => setTotalLeadTime(e.target.value)}
                                    disabled={true}
                                >
                                    <MenuItem value={1}>5 WD</MenuItem>
                                    <MenuItem value={2}>6 WD</MenuItem>
                                    <MenuItem value={3}>7 WD</MenuItem>
                                    <MenuItem value={4}>8 WD</MenuItem>
                                    <MenuItem value={5}>9 WD</MenuItem>
                                    <MenuItem value={6}>10 WD</MenuItem>
                                    <MenuItem value={7}>11 WD</MenuItem>
                                    <MenuItem value={8}>12 WD</MenuItem>
                                    <MenuItem value={9}>13 WD</MenuItem>
                                    <MenuItem value={10}>14 WD</MenuItem>
                                    <MenuItem value={11}>15 WD</MenuItem>
                                    <MenuItem value={12}>Calendar selection</MenuItem>
                                </Select>
                            </FormControl>
                            */}

                            {/*SHIPPING METHOD*/}
                            <FormControl>
                                <InputLabel id="shippingMethod-label">
                                    {t("calculators.step3.fields.shippingMethod")}
                                </InputLabel>
                                <Select
                                    labelId="shippingMethod"
                                    id="shippingMethod"
                                    value={shippingMethodId}
                                    label={t("calculators.step3.fields.shippingMethod")}
                                    onChange={(e) => {
                                        setShippingMethodId(e.target.value);
                                        updateRepeatOrder(); }}>
                                    <MenuItem value={1}>{t("calculators.selectable.economy")}</MenuItem>
                                    <MenuItem value={2}>{t("calculators.selectable.express")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/*DELIVERY COUNTRY*/}
                            <FormControl>
                                <InputLabel id="deliveryCountry-label">
                                    {t("calculators.step3.fields.deliveryCountry")}
                                </InputLabel>
                                <Select
                                    labelId="deliveryCountry"
                                    id="deliveryCountry"
                                    value={country.id}
                                    label={t("calculators.step3.fields.deliveryCountry")}
                                    onChange={(e) => {
                                        handleCountryChosen(e.target.value)
                                        updateRepeatOrder(); }}>
                                    {availableCountries.map((_country, i) => {
                                        //return <MenuItem name="pays" value={country.id} key={i} disabled={!country.enabled}>{country.name}</MenuItem>
                                        if (_country.enabled) {
                                            return <MenuItem name="pays"
                                                             value={_country.id}
                                                             key={i}>
                                                {_country.name}
                                            </MenuItem>
                                        }
                                    })}
                                </Select>
                            </FormControl>

                            {/*LOCALE*/}
                            <FormControl disabled={repeatOrderMode.background && repeatOrderMode.lockPrice}>
                                <InputLabel id="locale-label">
                                    {t("account.accountSettings.mailPreferences.mailAndDocumentLanguage")}
                                </InputLabel>
                                <Select
                                    labelId="priceOfferLocale"
                                    id="priceOfferLocale"
                                    value={priceOfferLocale}
                                    label={t("account.accountSettings.mailPreferences.mailAndDocumentLanguage")}
                                    onChange={e => {
                                        setPriceOfferLocale(e.target.value);
                                        isLockFields && setIsExcludingPriceChangeFieldsUpdated(true);
                                    } }>
                                    {localesList.map((locale) => (
                                        <MenuItem value={locale} key={locale}>{t(localeConverter(locale))}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className={"delivery-box-info"}>
                            <Box className="legend-line">
                                <Box className="legend-square production-time"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.productionTime")} :
                                    <span className="working-days-legend">
                                        {productionTime}
                                    </span>
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square days-to-delivery"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.daysToDelivery")} :
                                    <span className="shipping-days-legend">
                                        {daysToDelivery}
                                    </span>
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square daysOff"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.publicHolidays")}
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square estimated-delivery-date"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.estimatedDeliveryDate")} :
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <span
                                    className="delivery-date-legend"> {requestedDate ? formatDate(requestedDate).toString() : ''}
                                </span>
                            </Box>
                        </Box>
                    </Box>

                    {/*CALENDAR ICAPE*/}
                    <CalendarIcape muiLocale={muiLocale}
                                   minDeliveryDate={parseISO(minDeliveryDate)}
                                   calendar={calendar}
                                   updateRepeatOrder={updateRepeatOrder}
                                   request={{requestedDate, setRequestedDate}}/>
                </Box>
                <Box className='customer-info'>
                    <Info/>
                    <span>{t("calculators.step3.calendar.deliveryInfo")}</span>
                </Box>
            </Box>
        </>
    )
}

export default Step3;