import React, {useContext, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import AllSteps from "./steps/all-steps";
import ProjectPanel from "../components/project-panel/project-panel";
import CalculatorLabServicesContext from "../../../context/calculatorLabServicesProvider";
import {Alert} from "@mui/lab";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Snackbar,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {ButtonIcape, ButtonIcapeGreen, ButtonIcapeOutlined} from "../../button/button-icape";
import '../calculators.scss';
import './calculator-labServices.scss';
import {Navigate} from "react-router";
import {useTranslation} from "react-i18next";

function CalculatorLabServices() {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const{
        customer_id,
        width, setWidth,
        length, setLength,
        returnPCB, setReturnPCB,
        testing_time, setTesting_time,
        test_price, setTest_price,
        pick_up_cost, setPick_up_cost,
        return_cost, setReturn_cost,
        total_price, setTotal_price,
        isTestLab, setIsTestLab,
        isNA_lab, setIsNA_lab,
        partNumber, setPartNumber,
        persist, setPersist,
        productVariant, setProductVariant,
        customerReferenceChoose, setCustomerReferenceChoose,
        customerReferences, setCustomerReferences,
        connectToBO, setConnectToBO,
        errorConnectionToBO, setErrorConnectionToBO,
        auth,
        unityFormat, setUnityFormat,
    } = useContext(CalculatorLabServicesContext);

    let _persist = true;

    let checkoutChoice = '';

    const generateNewAutoReference = () => {
        let date = new Date();
        let company = auth.companyName;
        return company + '--' + date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDay() + '--' + date.getHours() + 'h' + date.getMinutes() + 'm' + date.getSeconds();

    }
    const handleOrderNow = (e) => {
        handleAddNewReference()
        checkoutProcess('orderNow');
    }

    const [checkout, setCheckout] = useState(false);

    useEffect(() => {
        if (checkout && partNumber !== '') {
            setPersist(true);
            _persist = true;
            checkoutChoice = addReferenceDialogCallingButton;
            setCheckout(false)
        }
    }, [checkout, partNumber])

    const checkoutProcess = (_checkoutChoice) => {
        if(errorConnectionToBO)
        {
            //alert('TODO : Backend can not get back a valid response. How to show this behavior to user ?')
            return;
        }

        setAddReferenceDialogCallingButton(_checkoutChoice);

        if (customerReferenceChoose.title === '') {
            setIsCustomerReferenceFieldEmpty(true);
            setOpenROAddReferenceDialog(true);
            setROAddReferenceDialogValue({title: generateNewAutoReference(), isNewReference: true});
            return;
        }
        setIsCustomerReferenceFieldEmpty(false);

        checkoutChoice = _checkoutChoice;

        setCheckout(true);

    }

    const retryConnection = () => {
        //true = complete reload from the server and not from the cached version
        // window.location.reload(true);
    };

//states for repeat orders
    const [openROAddReferenceDialog, setOpenROAddReferenceDialog] = React.useState(false);
    const [rOAddReferenceDialogValue, setROAddReferenceDialogValue] = React.useState({title: '', isNewReference: true});

    const [openCustomerPriceOffersList, setOpenCustomerPriceOffersList] = React.useState(false);
    let loadingForCustomerPriceOffers = openCustomerPriceOffersList && customerReferences.length === 0;
    const [loadingForCustomerPriceOffers2, setLoadingForCustomerPriceOffers2] = useState(false);

    const [isCustomerReferenceFieldEmpty, setIsCustomerReferenceFieldEmpty] = React.useState(false);

    const [addReferenceDialogCallingButton, setAddReferenceDialogCallingButton] = useState(null);

    const handleAddNewReference = (e) => {
        console.log("[handle] Add new reference : ", rOAddReferenceDialogValue);
        setCustomerReferenceChoose(rOAddReferenceDialogValue);
        setPartNumber(rOAddReferenceDialogValue.title);
        setProductVariant('');
        setOpenROAddReferenceDialog(false);
        setIsCustomerReferenceFieldEmpty(false);
    }

    const handleCancelNewReference = () => {
        setCustomerReferenceChoose({title: ''});
        setPartNumber('');
        setROAddReferenceDialogValue({'title': '', isNewReference: false});
        setOpenROAddReferenceDialog(false);
    }
    const handleChangeROAddReferenceDialog = (e) => {
        setROAddReferenceDialogValue({
            ...rOAddReferenceDialogValue,
            title: e.target.value,
        });
        setCustomerReferenceChoose(e.target.value);
    }


    // Cart Cookie
    useEffect(() => {
        localStorage.setItem('carts', JSON.stringify({
            productVariant,
            testing_time,
            test_price,
            pick_up_cost,
            return_cost,
            total_price
        }));
    }, [productVariant, testing_time, test_price, pick_up_cost, return_cost, total_price])

    const handleDropFile = async (file) => {}

    return (
        <Box className="calculator-wrapper">

            {/*//once the product variant is received, go to /cart*/}
            {productVariant.length > 0 && <Navigate to="/cart" replace={true}/>}

            {/*TODO: CHECK IF USEFUL (GERBER)*/}
            {connectToBO ? (<LinearProgress/>) : (<Box sx={{display: "block", height: '4px'}}/>)}

            {/*CALCULATOR LAB-SERVICES*/}

            <div className="calculator">

                {/*SNACKBAR CONNECT TO BO FAILED*/}
                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}} open={errorConnectionToBO}
                          message="Connection with back office failed" key='topright'>
                    <Alert severity="error" variant="filled">Connection with back office failed ! Please try again in
                        few minutes.</Alert>
                </Snackbar>

                {/*TITLE*/}
                <Typography variant={'h1'}>{t("calculators.laboratory.calculatorTitle")}</Typography>

                {/*STEP TITLE & TOOLBAR*/}
                <Box className="title-wrapper lab-services-toolbar">
                    {/*TOOLBAR*/}
                    <Box className={"calculator-toolbar"}>
                        {/*AUTOCOMPLETE PROJECT REF SEARCH*/}
                        <Autocomplete
                            id="repeatOrdersReferences"
                            value={customerReferenceChoose}
                            open={openCustomerPriceOffersList}
                            onOpen={() => {
                                setOpenCustomerPriceOffersList(true)
                                setCustomerReferences([]);
                            }}
                            onClose={() => setOpenCustomerPriceOffersList(false)}
                            options={Array.from(new Set(customerReferences.map(customerReference => customerReference.PCB.partNumber))).map(partNumber => {
                                return {
                                    title: partNumber,
                                    isNewReference: false
                                }
                            })}

                            renderInput={(params) =>
                                <TextField error={isCustomerReferenceFieldEmpty} {...params}
                                           label={t("calculators.toolbar.projectReference")}
                                           InputProps={{
                                               ...params.InputProps, endAdornment: (
                                                   <React.Fragment>
                                                       {/*{loadingForCustomerPriceOffers ?*/}
                                                       {loadingForCustomerPriceOffers2 ?
                                                           <CircularProgress color="inherit" size={20}
                                                                             sx={{marginRight: "40px"}}/> : null}
                                                       {params.InputProps.endAdornment}
                                                   </React.Fragment>
                                               ),
                                           }}/>}
                            onChange={(e, newValue) => {
                                console.log("Autocomplete newValue : ", newValue)
                                if (newValue === null) {
                                    setCustomerReferenceChoose({title: ''});
                                    setPartNumber('');
                                }
                                if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                        setOpenROAddReferenceDialog(true);
                                        setROAddReferenceDialogValue({...newValue});
                                        setCustomerReferenceChoose({...newValue, isNewReference: false});
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    setAddReferenceDialogCallingButton('');
                                    setOpenROAddReferenceDialog(true);
                                    setROAddReferenceDialogValue({
                                        title: newValue.inputValue,
                                        isNewReference: true
                                    });
                                } else {
                                    if (newValue !== null) {
                                        setCustomerReferenceChoose({...newValue, isNewReference: false});
                                    }
                                }
                            }}
                            filterOptions={(options, params) => {
                                const {inputValue} = params;
                                const isInputEmpty = inputValue === '';

                                const filtered = isInputEmpty ? options :  options.filter(option => option.title.toLowerCase().trim() === inputValue.toLowerCase().trim());

                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => option.title.toLowerCase().trim() === inputValue.toLowerCase().trim());
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        title: `Add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                // e.g. value selected with enter, right from the input

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                            }}

                            //@TODO add purchase date to the list

                            renderOption={(props, option) => (
                                <li {...props}>{option?.title}</li>
                            )}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            loading={loadingForCustomerPriceOffers}
                        />

                        {/*PCB WIDTH*/}
                        <TextField
                            id="width"
                            label={t("calculators.step1.fields.pcbWidth")}
                            value={width}
                            onChange={(e) => {setWidth(e.target.value)}}
                            variant="outlined"
                            required
                            sx={{width: "100px"}}
                        />
                        {/*PCB LENGTH*/}
                        <TextField
                            id="PCB length"
                            label={t("calculators.step1.fields.pcbLength")}
                            value={length}
                            onChange={(e) => {setLength(e.target.value)}}
                            variant="outlined"
                            required
                            sx={{ width: "100px"}}
                        />

                        {/*TODO SET SWITCH WITH VARIABLE*/}
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={returnPCB} />}
                                label={t("calculators.laboratory.returnPcbAfterTesting")}
                                onChange={(e) => {setReturnPCB(e.target.checked)}}/>
                        </FormGroup>

                        {/*MM / INCH*/}
                        <ToggleButtonGroup
                            color="primary"
                            value={unityFormat}
                            exclusive
                            onChange={e => setUnityFormat(e.target.value)}
                            aria-label="Platform"
                        >
                            <ToggleButton value="mm">mm</ToggleButton>
                            <ToggleButton value="in">in</ToggleButton>
                        </ToggleButtonGroup>

                        {/* POPUP ADD NEW REFERENCE */}
                        <Dialog open={openROAddReferenceDialog}>
                            <DialogTitle>{t("popup.newRef.title")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{
                                    marginBottom: '20px'
                                }}>
                                    {t("popup.newRef.content")}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    value={rOAddReferenceDialogValue.title}
                                    onChange={handleChangeROAddReferenceDialog}
                                    label="title"
                                    type="text"
                                    variant="standard"
                                />
                            </DialogContent>
                            <DialogActions>
                                <ButtonIcapeOutlined onClick={handleCancelNewReference}>{t("cta.cancel")}</ButtonIcapeOutlined>

                                {addReferenceDialogCallingButton !== 'orderNow' && addReferenceDialogCallingButton !== 'downloadQuote' ?
                                    <ButtonIcape onClick={handleAddNewReference}>{t("cta.add")}</ButtonIcape> : ''}

                                {addReferenceDialogCallingButton === 'orderNow' ?
                                    <ButtonIcape onClick={e => handleOrderNow(e)}>{t("cta.orderNow")}</ButtonIcape> : ''}

                            </DialogActions>
                        </Dialog>

                    </Box>
                </Box>


                {/*TODO CHECK IF USEFUL (dataFromApi ?)*/}
                {/*STEP RENDERER*/}
                <Box className={"content-wrapper"}>
                    <AllSteps dropFile={handleDropFile}/>
                </Box>

                <Box className={"step-cta-desktop"}>

                    <Box sx={{display: "flex", gap: "30px"}}>
                        {/*DOWNLOAD QUOTE*/}
                        <ButtonIcapeGreen
                            id="downloadQuote"
                            onClick={e => checkoutProcess(e.target.id)}>
                            {t("cta.downloadMyQuote")}
                        </ButtonIcapeGreen>

                        {/*ORDER NOW*/}
                        <ButtonIcape
                            id="orderNow"
                            onClick={e => checkoutProcess(e.target.id)}>
                            {t("cta.orderNow")}
                        </ButtonIcape>
                    </Box>
                </Box>

            </div>

            {/*PROJECT PANEL*/}
            <ProjectPanel prices={{testing_time, test_price, pick_up_cost, return_cost, total_price, isTestLab, isNA_lab, returnPCB}}/>
        </Box>
    );
}

export default CalculatorLabServices;
