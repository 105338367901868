import Box from "@mui/material/Box";
import "./loading.scss";
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";

function LoadingScreen() {
    return (
        <>
            <Box className="loaging-container">
                <Box component={"span"}>
                    <CircularProgress color="success"/>
                    We are buidling you PCB shop, please be patient.
                </Box>
            </Box>
        </>
    )
}
export default LoadingScreen