import React from "react";
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";


const BackendConnection = () => {

    return (
        <Box className="connect-bo-progress-bar">
            <LinearProgress/>
        </Box>
    )

}


export default BackendConnection;
