import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'enEN', // Langue de secours par défaut (si la langue du navigateur n'est pas prise en charge)
        supportedLngs: ['enEN', 'frFR', 'cnCN', 'deDE', 'esES', 'itIT', 'jaJP', 'ptBR'],
        detection: {
            order: ['navigator'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json', // Remplacez le chemin par le chemin réel vers vos fichiers de traduction
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;