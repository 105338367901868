import React, {useContext} from "react";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import './step-2.scss';
import Typography from "@mui/material/Typography";
import CalculatorAluminumContext from "../../../../context/calculatorAluminumProvider";
import {mmToMil} from "../../../../tools/unit.utils";
import {useTranslation} from "react-i18next";

function Step2(props) {
    const {t} = useTranslation();

    const {
        isLockFields, unitFormat,
        technologyId, setTechnologyId,
        boardThicknessId, setBoardThicknessId,
        solderMaskColorId, setSolderMaskColorId,
        finishedOuterCuId, setFinishedOuterCuId,
        finishingKey, setFinishingKey,
        solderMaskId, legendId,
        legendColorId, setLegendColorId,
        ipc3Id, setIpc3Id,
        ulCountryId, setUlCountryId,
        vCutId, setVCutId,
        ionicContaminationId, setIonicContaminationId,
        impedanceControlId, setImpedanceControlId,
        thermalConductivityId, setThermalConductivityId,
        carbonInkId, setCarbonInkId,
        maskOptionId, setMaskOptionId
    } = useContext(CalculatorAluminumContext);

    return (
        <>
            {/*TECHNOLOGY*/}
            <Box className={"technical-data-card rigid-step-2-form"}>
                <Typography variant={'h3'}>
                    {t("calculators.step2.fields.technology.name")}
                </Typography>

                {/* STACK UP Field */}
                <FormControl disabled={true}>
                    <InputLabel id="stackUp-label">
                        {t("calculators.step1.fields.stackUp")}
                    </InputLabel>
                    <Select
                        labelId="stackUp"
                        id="stackUp"
                        value={technologyId}
                        label={t("calculators.step1.fields.stackUp")}
                        onChange={(e) => setTechnologyId(e.target.value)}
                    >
                        <MenuItem value={11}>{t("calculators.selectable.singleSide")}</MenuItem>
                        <MenuItem value={12}>{t("calculators.selectable.doubleSide")}</MenuItem>
                    </Select>
                </FormControl>

                {/* THERMAL CONDUCTIVITY Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="thermalConductivity-label">
                        {t("calculators.step2.fields.technology.thermalConductivity")}
                    </InputLabel>
                    <Select
                        labelId="thermalConductivity-label"
                        id="thermalConductivity"
                        value={thermalConductivityId}
                        label={t("calculators.step2.fields.technology.thermalConductivity")}
                        onChange={(e) => setThermalConductivityId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            W
                        </InputAdornment>}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>1.5</MenuItem>
                        <MenuItem value={3}>2</MenuItem>
                    </Select>
                </FormControl>

                {/* OUTER COPPER FINISHED Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="outerCopperFinished-label">
                        {t("calculators.step2.fields.technology.outerCopperFinished")}
                    </InputLabel>
                    <Select
                        labelId="outerCopperFinished"
                        id="outerCopperFinished"
                        value={finishedOuterCuId}
                        label={t("calculators.step2.fields.technology.outerCopperFinished")}
                        onChange={(e) => setFinishedOuterCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1}> { unitFormat === 'mm' ? 18 : "1/2"} </MenuItem>
                        <MenuItem value={2}> { unitFormat === 'mm' ? 35 : 1} </MenuItem>
                        <MenuItem value={3}> { unitFormat === 'mm' ? 70 : 2} </MenuItem>
                        <MenuItem value={4}> { unitFormat === 'mm' ? 105 : 3} </MenuItem>
                        <MenuItem value={5}> { unitFormat === 'mm' ? 140 : 4} </MenuItem>
                    </Select>
                </FormControl>

                {/* IPC Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="ipc-label">
                        {t("calculators.step2.fields.technology.ipc")}
                    </InputLabel>
                    <Select
                        labelId="ipc"
                        id="ipc"
                        value={ipc3Id}
                        label={t("calculators.step2.fields.technology.ipc")}
                        onChange={(e) => setIpc3Id(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.class2")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.class3")}</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/*MATERIAL & PCB DETAILS*/}
            <Box className={"technical-data-card-column rigid-step-2-form"}>
                {/*MATERIAL*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.material.name")}
                    </Typography>

                    {/* PCB THICKNESS Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="pcbThickness-label">
                            {t("calculators.step2.fields.material.pcbThickness")}
                        </InputLabel>
                        <Select
                            labelId="pcbThickness"
                            id="pcbThickness"
                            value={boardThicknessId}
                            label={t("calculators.step2.fields.material.pcbThickness")}
                            onChange={(e) => setBoardThicknessId(e.target.value)}
                            endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                {unitFormat === 'mm' ? unitFormat : "mil"}
                            </InputAdornment>}
                        >
                            <MenuItem value={1}> { unitFormat === 'mm' ? 1 : `${ mmToMil(1)}` } </MenuItem>
                            <MenuItem value={2}> { unitFormat === 'mm' ? 1.2 : `${ mmToMil(1.2)}` } </MenuItem>
                            <MenuItem value={3}> { unitFormat === 'mm' ? 1.5 : `${ mmToMil(1.5)}` } </MenuItem>
                            <MenuItem value={4}> { unitFormat === 'mm' ? 1.6 : `${ mmToMil(1.6)}` } </MenuItem>
                            <MenuItem value={5}> { unitFormat === 'mm' ? 2 : `${ mmToMil(2)}` } </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/*PCB DETAILS*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.pcbDetails.name")}
                    </Typography>

                    {/* SOLDER MASK COLOR Field */}
                    <FormControl disabled={isLockFields || (solderMaskId === "") || (solderMaskId === 1)}>
                        <InputLabel id="solderMaskColor-label">
                            {t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                        </InputLabel>
                        <Select
                            labelId="solderMaskColor"
                            id="solderMaskColor"
                            value={(solderMaskId === "") || (solderMaskId === 1) ? 7 : solderMaskColorId}
                            label={t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                            onChange={(e) => setSolderMaskColorId(e.target.value)}
                        >
                            <MenuItem value={7} disabled={true}>--</MenuItem>
                            <MenuItem value={1}><div className="color-dot-wrapper"><div className="color-dot color-dot-green"></div>{t("calculators.selectable.colors.green")}</div></MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-blue"></div>{t("calculators.selectable.colors.blue")}</div></MenuItem>
                            <MenuItem value={5}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                            <MenuItem value={6}><div className="color-dot-wrapper"><div className="color-dot color-dot-white"></div>{t("calculators.selectable.colors.white")}</div></MenuItem>
                            <MenuItem value={3}><div className="color-dot-wrapper"><div className="color-dot color-dot-red"></div>{t("calculators.selectable.colors.red")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-yellow"></div>{t("calculators.selectable.colors.yellow")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* LEGEND COLOR Field */}
                    <FormControl disabled={isLockFields || (legendId === "") || (legendId === 1)}>
                        <InputLabel id="legendColor-label">
                            {t("calculators.step2.fields.pcbDetails.legendColor")}
                        </InputLabel>
                        <Select
                            labelId="legendColor"
                            id="legendColor"
                            value={(legendId === "") || (legendId === 1) ? 1 : legendColorId}
                            label={t("calculators.step2.fields.pcbDetails.legendColor")}
                            onChange={(e) => setLegendColorId(e.target.value)}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-white"></div>{t("calculators.selectable.colors.white")}</div></MenuItem>
                            <MenuItem value={3}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-yellow"></div>{t("calculators.selectable.colors.yellow")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* FINISHING Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="finishing-label">
                            {t("calculators.step2.fields.pcbDetails.finishing")}
                        </InputLabel>
                        <Select
                            labelId="finishing"
                            id="finishing"
                            value={finishingKey}
                            label={t("calculators.step2.fields.pcbDetails.finishing")}
                            onChange={(e) => setFinishingKey(e.target.value)}
                        >
                            <MenuItem value={6}>--</MenuItem>
                            <MenuItem value={3}>O.S.P</MenuItem>
                            <MenuItem value={1}>HASL-LF</MenuItem>
                            <MenuItem value={2}>ENIG -2U"</MenuItem>
                            <MenuItem value={5}>{t("calculators.selectable.immersionSilver")}</MenuItem>
                            <MenuItem value={4}>{t("calculators.selectable.immersionTin")}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {/*ADVANCED OPTIONS*/}
            <Box className="technical-data-card rigid-step-2-form">
                <Typography variant={'h3'}>
                    {t("calculators.step2.fields.advancedOption.name")}
                </Typography>

                {/* UL COUNTRY Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="rawMaterial-label">
                        {t("calculators.step2.fields.advancedOption.ulCountry")}
                    </InputLabel>
                    <Select
                        labelId="ulCountry"
                        id="ulCountry"
                        value={ulCountryId}
                        label={t("calculators.step2.fields.advancedOption.ulCountry")}
                        onChange={(e) => setUlCountryId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.usa")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.canada")}</MenuItem>
                    </Select>
                </FormControl>

                {/* V CUT Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="rawMaterial-label">
                        {t("calculators.step2.fields.advancedOption.vCut")}
                    </InputLabel>
                    <Select
                        labelId="vcut"
                        id="vcut"
                        value={vCutId}
                        label="V cut"
                        onChange={(e) => setVCutId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* IONIC CONTAMINATION Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="ionicContamination-label">
                        {t("calculators.step2.fields.advancedOption.ionicContamination")}
                    </InputLabel>
                    <Select
                        labelId="ionicContamination"
                        id="ionicContamination"
                        value={ionicContaminationId}
                        label="ionic-contamination"
                        onChange={(e) => setIonicContaminationId(e.target.value)}
                    >
                        <MenuItem value={1}>No value</MenuItem>
                        <MenuItem value={2}>Standard value</MenuItem>
                    </Select>
                </FormControl>

                {/* IMPEDANCE CONTROL Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="impedanceControl-label">
                        {t("calculators.step2.fields.advancedOption.impedanceControl")}
                    </InputLabel>
                    <Select
                        labelId="impedanceControl"
                        id="impedanceControl"
                        value={impedanceControlId}
                        label={t("calculators.step2.fields.advancedOption.impedanceControl")}
                        onChange={(e) => setImpedanceControlId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* CARBON INK Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="carbonPrint-label">
                        {t("calculators.step2.fields.advancedOption.carbonPrint")}
                    </InputLabel>
                    <Select
                        labelId="carbonPrint-label"
                        id="carbonPrint"
                        value={carbonInkId}
                        label={t("calculators.step2.fields.advancedOption.carbonPrint")}
                        onChange={(e) => setCarbonInkId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* MASK OPTION Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="maskOption-label">
                        {t("calculators.step2.fields.advancedOption.maskOption")}
                    </InputLabel>
                    <Select
                        labelId="maskOption-label"
                        id="maskOption"
                        value={maskOptionId}
                        label={t("calculators.step2.fields.advancedOption.maskOption")}
                        onChange={(e) => setMaskOptionId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default Step2;
