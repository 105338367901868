import React, {useContext, useEffect} from "react";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import './step-2.scss';
import Typography from "@mui/material/Typography";
import CalculatorFlexContext from "../../../../context/calculatorFlexProvider";
import {useTranslation} from "react-i18next";


function Step2() {
    const {t} = useTranslation();

    const {
        isLockFields, unitFormat,
        technologyId, setTechnologyId,
        fr4StiffenerId, setFr4StiffenerId,
        stiffenerId, setStiffenerId,
        finishedInnerCuId, setFinishedInnerCuId,
        basedOuterCuId, setBasedOuterCuId,
        polymideBaseMaterialThicknessId, setPolymideBaseMaterialThicknessId,
        rawMaterialCoreId, setRawMaterialCoreId,
        coreAdhesiveThicknessId, setCoreAdhesiveThicknessId,
        doubleSide3mTapeId, setDoubleSide3mTapeId,
        coverlayId, coverlayColorId, setCoverlayColorId,
        finishingKey, setFinishingKey,
    } = useContext(CalculatorFlexContext);

    //Finished inner copper constraint
    useEffect(() => {
        let previousFinishedInnerId = finishedInnerCuId
        if( technologyId === "" || technologyId <= 32 ){
            setFinishedInnerCuId(1);
            return
        }
        if ( previousFinishedInnerId === 1){
            setFinishedInnerCuId(2);
        }
    }, [technologyId]);

    //Core adhesive thickness constraint
    useEffect(() => {
        let previousAdhesiveState = coreAdhesiveThicknessId;
        if( rawMaterialCoreId === 2 ){
            setCoreAdhesiveThicknessId(1);
            return
        }
        if ( previousAdhesiveState === 1){
            setCoreAdhesiveThicknessId(2);
        }
    }, [rawMaterialCoreId]);


    return (
        <>
            {/*TECHNOLOGY*/}
            <Box className={"technical-data-card rigid-step-2-form"}>
                <Typography variant={'h3'}>{t("calculators.step2.fields.technology.name")}</Typography>

                {/* STACK UP Field */}
                <FormControl disabled={true}>
                    <InputLabel id="stackUp-label">
                        {t("calculators.step1.fields.stackUp")}
                    </InputLabel>
                    <Select
                        labelId="stackUp"
                        id="stackUp"
                        value={technologyId}
                        label={t("calculators.step1.fields.stackUp")}
                        onChange={(e) => setTechnologyId(e.target.value)}
                    >
                        <MenuItem value={31}>{t("calculators.selectable.singleSide")}</MenuItem>
                        <MenuItem value={32}>{t("calculators.selectable.doubleSide")}</MenuItem>
                        <MenuItem value={33}>4 {t("common.layers")}</MenuItem>
                    </Select>
                </FormControl>

                {/* FR4 STIFFENER Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="fr4Stiffener-label">
                        {t("calculators.step2.fields.technology.fr4Stiffener")}
                    </InputLabel>
                    <Select
                        labelId="fr4Stiffener"
                        id="fr4Stiffener"
                        value={fr4StiffenerId}
                        label={t("calculators.step2.fields.technology.fr4Stiffener")}
                        onChange={(e) => setFr4StiffenerId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* STIFFENER Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="stiffener-label">
                        {t("calculators.step2.fields.technology.stiffener")}
                    </InputLabel>
                    <Select
                        labelId="stiffener"
                        id="stiffener"
                        value={stiffenerId}
                        label={t("calculators.step2.fields.technology.stiffener")}
                        onChange={(e) => setStiffenerId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.pi")}</MenuItem>
                        <MenuItem value={3}>{t("calculators.selectable.3mTape")}</MenuItem>
                        <MenuItem value={4}>{t("calculators.selectable.pet")}</MenuItem>
                    </Select>
                </FormControl>

                {/* INNER COPPER Field */}
                <FormControl disabled={isLockFields || technologyId === "" || technologyId <= 32}>
                    <InputLabel id="innerCopper-label">
                        {t("calculators.step2.fields.technology.innerCopper")}
                    </InputLabel>
                    <Select
                        labelId="innerCopper"
                        id="innerCopper"
                        value={finishedInnerCuId}
                        label={t("calculators.step2.fields.technology.innerCopper")}
                        onChange={(e) => setFinishedInnerCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1} disabled={true}>--</MenuItem>
                        <MenuItem value={2}> { unitFormat === 'mm' ? 12 : "1/3"} </MenuItem>
                        <MenuItem value={3}> { unitFormat === 'mm' ? 17.5 : "1/2"} </MenuItem>
                        <MenuItem value={4}> { unitFormat === 'mm' ? 35 : "1"} </MenuItem>
                    </Select>
                </FormControl>

                {/* OUTER COPPER BASE Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="pcbThickness-label">
                        {t("calculators.step2.fields.technology.outerCopperBase")}
                    </InputLabel>
                    <Select
                        labelId="outerCopperBase"
                        id="outerCopperBase"
                        value={basedOuterCuId}
                        label={t("calculators.step2.fields.technology.outerCopperBase")}
                        onChange={e => setBasedOuterCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1}> { unitFormat === 'mm' ? 12 : "1/3"} </MenuItem>
                        <MenuItem value={2}> { unitFormat === 'mm' ? 17.5 : "1/2"} </MenuItem>
                        <MenuItem value={3}> { unitFormat === 'mm' ? 35 : "1"} </MenuItem>
                    </Select>
                </FormControl>

            </Box>

            {/*MATERIAL & PCB DETAILS*/}
            <Box className={"technical-data-card-column rigid-step-2-form"}>
                {/*MATERIAL*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.material.name")}
                    </Typography>

                    {/* POLYMIDE BASE MATERIAL THICKNESS Field*/}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="polymideBaseMaterialThicknessId-label">
                            {t("calculators.step2.fields.technology.polymideBaseMaterialThickness")}
                        </InputLabel>
                        <Select
                            labelId="polymideBaseMaterialThicknessId"
                            id="polymideBaseMaterialThicknessId"
                            value={polymideBaseMaterialThicknessId}
                            label={t("calculators.step2.fields.technology.polymideBaseMaterialThickness")}
                            onChange={(e) => setPolymideBaseMaterialThicknessId(e.target.value)}
                            endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                {unitFormat === 'mm' ? unitFormat : "mil"}
                            </InputAdornment>}
                        >
                            <MenuItem value={1}>--</MenuItem>
                            <MenuItem value={2}> { unitFormat === 'mm' ? 12.5 : 0.47 } </MenuItem>
                            <MenuItem value={3}> { unitFormat === 'mm' ? 20 : 0.79 } </MenuItem>
                            <MenuItem value={4}> { unitFormat === 'mm' ? 25 : 0.98 } </MenuItem>
                            <MenuItem value={5}> { unitFormat === 'mm' ? 50 : 1.97 } </MenuItem>
                        </Select>
                    </FormControl>

                    {/* RAW MATERIAL CORE Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="rawMaterialCoreId-label">
                            {t("calculators.step2.fields.material.rawMaterialCore")}
                        </InputLabel>
                        <Select
                            labelId="rawMaterialCoreId"
                            id="rawMaterialCoreId"
                            value={rawMaterialCoreId}
                            label={t("calculators.step2.fields.material.rawMaterialTgValue")}
                            onChange={(e) => setRawMaterialCoreId(e.target.value)}
                        >
                            <MenuItem value={1}>{t("calculators.selectable.withAdhesive")}</MenuItem>
                            <MenuItem value={2}>{t("calculators.selectable.withoutAdhesive")}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* CORE ADHESIVE THICKNESS Field */}
                    <FormControl disabled={isLockFields || rawMaterialCoreId === 2}>
                        <InputLabel id="coreAdhesiveThicknessId-label">
                            {t("calculators.step2.fields.technology.coreAdhesiveThickness")}
                        </InputLabel>
                        <Select
                            labelId="coreAdhesiveThicknessId"
                            id="coreAdhesiveThicknessId"
                            value={coreAdhesiveThicknessId}
                            label={t("calculators.step2.fields.technology.coreAdhesiveThickness")}
                            onChange={(e) => setCoreAdhesiveThicknessId(e.target.value)}
                            endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                {unitFormat === 'mm' ? "µm" : "mil"}
                            </InputAdornment>}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}> { unitFormat === 'mm' ? 12.5 : 0.47 } </MenuItem>
                            <MenuItem value={3}> { unitFormat === 'mm' ? 20 : 0.79 } </MenuItem>
                            <MenuItem value={4}> { unitFormat === 'mm' ? 25 : 0.98 } </MenuItem>
                        </Select>
                    </FormControl>

                    {/* DOUBLE SIDE 3M TAPE Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="doubleSide3mTapeId-label">
                            {t("calculators.step2.fields.material.doubleSide3mTape")}
                        </InputLabel>
                        <Select
                            labelId="doubleSide3mTapeId"
                            id="doubleSide3mTapeId"
                            value={doubleSide3mTapeId}
                            label={t("calculators.step2.fields.material.doubleSide3mTape")}
                            onChange={(e) => setDoubleSide3mTapeId(e.target.value)}
                        >
                            <MenuItem value={1}>{t("calculators.selectable.none")}</MenuItem>
                            <MenuItem value={2}>{t("calculators.selectable.series3m9077")}</MenuItem>

                        </Select>
                    </FormControl>

                </Box>

                {/*PCB DETAILS*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.pcbDetails.name")}
                    </Typography>

                    {/* COVERLAY COLOR Field */}
                    <FormControl disabled={isLockFields || coverlayId === 1 || coverlayId === ''}>
                        <InputLabel id="coverlayColorId-label">
                            {t("calculators.step2.fields.pcbDetails.coverlayColor")}
                        </InputLabel>
                        <Select
                            labelId="coverlayColorId"
                            id="coverlayColorId"
                            value={coverlayColorId}
                            label={t("calculators.step2.fields.pcbDetails.coverlayColor")}
                            onChange={(e) => setCoverlayColorId(e.target.value)}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-yellow"></div>{t("calculators.selectable.colors.yellow")}</div></MenuItem>
                            <MenuItem value={3}><div className="color-dot-wrapper"><div className="color-dot color-dot-white"></div>{t("calculators.selectable.colors.white")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* FINISHING Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="finishing-label">
                            {t("calculators.step2.fields.pcbDetails.finishing")}
                        </InputLabel>
                        <Select
                            labelId="finishing"
                            id="finishing"
                            value={finishingKey}
                            label={t("calculators.step2.fields.pcbDetails.finishing")}
                            onChange={(e) => setFinishingKey(e.target.value)}
                        >
                            <MenuItem value={1}>O.S.P</MenuItem>
                            <MenuItem value={2}>ENIG 1u</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

        </>
    )
}

export default Step2;