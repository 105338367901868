import React, {useEffect, useState} from "react";
import Widget from './widget/widget'
import Login from './login/login'
import Register from "./register/register";
import ResetPassword from "./forgotten-password/forgotten-password";
import "./login-wrapper.scss";
import BackendConnection from "../backend-connection/backend-connection";
import {Alert, Snackbar} from "@mui/material";
import {snackbarTransition} from "../../tools/snackbarTransition";
import {useTranslation} from "react-i18next";

function LoginWrapper(props) {
    const {t} = useTranslation();

    const [view, setView] = useState(props.view);
    const [backendConnection, setBackendConnection] = useState({
        isError: false,
        isLoading: false
    });

    /**
     * Update the component alterning with login, register and reset password
     * @param viewComponent
     */
    const handleView = (viewComponent) =>{
        setView(viewComponent)
    }

    useEffect(() => {
        console.log(backendConnection);
    }, [backendConnection]);

    return(
        <>
            { backendConnection.isLoading && <BackendConnection /> }
            <div className="login-wrapper">
                <div className="login-block">

                    {
                        view === 'login' &&
                        <>
                            <Widget/>
                            <Login handleView={handleView}
                                   backendConnection={{backendConnection, setBackendConnection}}/>
                        </>
                    }
                    {
                        view === 'register'
                        && <Register handleView={handleView}
                                     backendConnection={{backendConnection, setBackendConnection}}/>
                    }
                    {
                        view === 'reset-password' &&
                        <>
                            <Widget/>
                            <ResetPassword handleView={handleView}
                                           backendConnection={{backendConnection, setBackendConnection}}/>
                        </>
                    }
                </div>
            </div>

            {/*SNACKBAR CONNECT TO BO FAILED*/}
            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={backendConnection.isError}
                      key='topright'
                      sx={{ width: '100%' }}
                      autoHideDuration={5000}
                      TransitionComponent={snackbarTransition}
                      onClose={() => setBackendConnection({...backendConnection, isError: false})}>
                <Alert severity="error" variant="filled"
                       onClose={() => setBackendConnection({...backendConnection, isError: false})}>
                    {t("popup.failedConnectBackend")}
                </Alert>
            </Snackbar>
        </>
    )
}
export default LoginWrapper;
