import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "./debug";
import html2canvas from "html2canvas";
import {getStorageValue} from "./cookie-handler";
import {axiosPrivate} from "../api/axios";


const captureRoot = async () => {
    logInConsole(`Capturing app root screenshot`, 'orange');
    try {
        const targetElement = document.getElementById('routes-to-capture');
        const canvas = await html2canvas(targetElement);
        logInConsole('captured', 'green');
        return canvas.toDataURL('image/png'); // L'image au format base64
    } catch (error) {
        logInConsole(`Panelization screenshot error  :, ${error}`);
    }
};

/**
 * Send an error report to backend for deep analysis
 * @param {'aluminum'|'flex'|'hdi'|'rigid'| null} _calculator
 * @param {string} _errorMessage
 */
export const ErrorHandling = async (_calculator, _errorMessage) => {
    logInConsoleSpacer();
    logInConsole('Error, sending it to backend', 'orange');

    //capture screenshot of the page
    const appScreenshot = await captureRoot();

    //getting localstorage
    const authLocalStorage = getStorageValue('auth');
    const userPreferencesLocalStorage = getStorageValue('userPreferences');
    let calculatorLocalStorage = {};
    if (_calculator !== null) {
       calculatorLocalStorage = getStorageValue(_calculator);
    }

    const data = {
        cookieData: {authLocalStorage, calculatorLocalStorage},
        clientData:[
            navigator.appName,
            navigator.appVersion,
            navigator.product,
            navigator.productSub,
            navigator.platform,
            navigator.userAgent,
            navigator.language,
            window.screen.width,
            window.screen.height],
        errorMessage: _errorMessage,
        reactData: "",
        userData: userPreferencesLocalStorage,
        screenShot: appScreenshot
    }
    logInConsoleObject(data);

    try {
        await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/errors/helper`, data)
        logInConsole(`Report sent to backend`, 'green');

    } catch (error) {
        logInConsole(`Failed to send report to backend`, 'red');

    } finally {

    }

}
