import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalculatorLabServicesContext from "../../../../../context/calculatorLabServicesProvider";
import {useContext} from "react";
import {useTranslation} from "react-i18next";

function createData(name, delay, price, description, deliveryReport, machine, customPrice) {
    return {
        name,
        delay,
        price,
        description,
        deliveryReport,
        machine,
        customPrice
    };
}

const rows = [
    createData('calculators.laboratory.tests.china.pcbValidation.name',2,
        179.43,
        'calculators.laboratory.tests.china.pcbValidation.description',
        'calculators.laboratory.tests.china.pcbValidation.deliveryReport',
        'calculators.laboratory.tests.china.pcbValidation.machine',
        'calculators.laboratory.tests.china.pcbValidation.customPrice',
        ),
    createData('calculators.laboratory.tests.china.microsectionPcb.name',2,
        160.54,
        'calculators.laboratory.tests.china.microsectionPcb.description',
        'calculators.laboratory.tests.china.microsectionPcb.deliveryReport',
        'calculators.laboratory.tests.china.microsectionPcb.machine',
        'calculators.laboratory.tests.china.microsectionPcb.customPrice',
        ),
    createData('calculators.laboratory.tests.china.microsectionPcbThermalShock.name',3,
        292.54,
        'calculators.laboratory.tests.china.microsectionPcbThermalShock.description',
        'calculators.laboratory.tests.china.microsectionPcbThermalShock.deliveryReport',
        'calculators.laboratory.tests.china.microsectionPcbThermalShock.machine',
        'calculators.laboratory.tests.china.microsectionPcbThermalShock.customPrice',
        ),
    createData('calculators.laboratory.tests.china.microsectionPcbAgeing.name',null,
        292.76,
        'calculators.laboratory.tests.china.microsectionPcbAgeing.description',
        'calculators.laboratory.tests.china.microsectionPcbAgeing.deliveryReport',
        'calculators.laboratory.tests.china.microsectionPcbAgeing.machine',
        'calculators.laboratory.tests.china.microsectionPcbAgeing.customPrice',
        ),
    createData('calculators.laboratory.tests.china.ionicContamination.name',2,
        113.33,
        'calculators.laboratory.tests.china.ionicContamination.description',
        'calculators.laboratory.tests.china.ionicContamination.deliveryReport',
        'calculators.laboratory.tests.china.ionicContamination.machine',
        'calculators.laboratory.tests.china.ionicContamination.customPrice',
        ),
    createData('calculators.laboratory.tests.china.mechanicalDimensionSmall.name',2,
        160.54,
        'calculators.laboratory.tests.china.mechanicalDimensionSmall.description',
        'calculators.laboratory.tests.china.mechanicalDimensionSmall.deliveryReport',
        'calculators.laboratory.tests.china.mechanicalDimensionSmall.machine',
        'calculators.laboratory.tests.china.mechanicalDimensionSmall.customPrice',
        ),
    createData('calculators.laboratory.tests.china.mechanicalDimensionLarge.name',2,
        135.99,
        'calculators.laboratory.tests.china.mechanicalDimensionLarge.description',
        'calculators.laboratory.tests.china.mechanicalDimensionLarge.deliveryReport',
        'calculators.laboratory.tests.china.mechanicalDimensionLarge.machine',
        'calculators.laboratory.tests.china.mechanicalDimensionLarge.customPrice',
        ),
    createData('calculators.laboratory.tests.china.tgMeasurements.name',3,
        188.88,
        'calculators.laboratory.tests.china.tgMeasurements.description',
        'calculators.laboratory.tests.china.tgMeasurements.deliveryReport',
        'calculators.laboratory.tests.china.tgMeasurements.machine',
        'calculators.laboratory.tests.china.tgMeasurements.customPrice',
        ),
    createData('calculators.laboratory.tests.china.surfaceFinishThickness.name',2,
        75.55,
        'calculators.laboratory.tests.china.surfaceFinishThickness.description',
        'calculators.laboratory.tests.china.surfaceFinishThickness.deliveryReport',
        'calculators.laboratory.tests.china.surfaceFinishThickness.machine',
        'calculators.laboratory.tests.china.surfaceFinishThickness.customPrice',
        ),
    createData('calculators.laboratory.tests.china.edxAnalysis.name',5,
        227,
        'calculators.laboratory.tests.china.edxAnalysis.description',
        'calculators.laboratory.tests.china.edxAnalysis.deliveryReport',
        'calculators.laboratory.tests.china.edxAnalysis.machine',
        'calculators.laboratory.tests.china.edxAnalysis.customPrice',
        ),
];

//Get price as a number in case it's a string. eg: 28.33/hour (On request)
function cleanPrice(price) {
    if (typeof price === "string"){
        return Number(price.substring(0, price.indexOf('/')));
    }
    return price;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'calculators.laboratory.tests.head.testName',
    },
    {
        id: 'delay',
        numeric: true,
        disablePadding: false,
        label: 'calculators.laboratory.tests.head.delay',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'calculators.laboratory.tests.head.price',
    },
];

function EnhancedTableHead(props) {
    const {t} = useTranslation();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select tests',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align='center'>
                    <Box component="span">
                        {t("calculators.laboratory.tests.head.details")}
                    </Box>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const {t} = useTranslation();
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{...(numSelected > 0 && {
                    color: '#007dc8',
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography>
                    {numSelected} {t("common.selected")}
                </Typography>
            ) : (
                <Typography>
                    0 {t("common.selected")}
                </Typography>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ChinaTable() {
    const {t} = useTranslation();

    const{
        returnPCB, setReturnPCB,
        testing_time, setTesting_time,
        test_price, setTest_price,
        selected, setSelected,
        pick_up_cost, setPick_up_cost,
        return_cost, setReturn_cost,
        calcTotalPrice,
    } = useContext(CalculatorLabServicesContext);

    //HardCoded variables for pick_up_cost and return_cost (different in other tables)
    const pk_up_cost = 46.40;
    let rtn_cost;

    //value depending on if returnPCB is checked or not
    returnPCB ? rtn_cost = 23.20 : rtn_cost = 0;
    setReturn_cost(rtn_cost);

    //function to calculate total
    calcTotalPrice(test_price,pick_up_cost,return_cost);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    // const [selected, setSelected] = React.useState([]);
    // console.log(selected);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        let newSelected = [];
        if (event.target.checked) {
            newSelected = rows.map((n) => n.name);
        }

        //Calcul total of test_price
        let tst_price = Math.round(rows.filter(x => newSelected.includes(x.name)).reduce( (previous, next, index) => previous + cleanPrice(next.price), 0) * 100) /100;
        setTest_price(tst_price);

        setPick_up_cost(pk_up_cost);

        setTesting_time(rows.filter(x => newSelected.includes(x.name)).reduce( (previous, next, index) => previous + next.delay, 0));
        setSelected(newSelected);

    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        const rowsSelected = rows.filter(x => newSelected.includes(x.name));

        //Calcul total of test_price
        let tst_price = Math.round(rowsSelected.reduce( (previous, next, index) => previous + cleanPrice(next.price), 0) * 100) /100;
        setTest_price(tst_price);

        setPick_up_cost(pk_up_cost);

        setTesting_time(rowsSelected.reduce( (previous, next, index) => previous + next.delay, 0));
        setSelected(newSelected);

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // ROW CALLED IN THE TABLE BELOW
    function Row(props) {
        const { row } = props;
        const isItemSelected = isSelected(row.name);
        const labelId = `enhanced-table-checkbox-${props.index}`;
        const [open, setOpen] = React.useState(false);
        return (
            <>
                {/*ROW TO DISPLAY*/}
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    className="lab-services-table-row"
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.name, row.price)}
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                    <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                    >
                        {t(row.name)}
                    </TableCell>
                    <TableCell align="right">{t(row.delay)}</TableCell>
                    <TableCell align="right">{t(row.price)}</TableCell>
                    <TableCell align="center" padding="none">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                                setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>

                {/*COLLAPSE CONTENT FOR EACH ROW*/}
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box className="lab-services-collapse-wrapper">
                                {
                                    t(row.description).length > 1 ?
                                        <Box>
                                            <Typography variant="h4" gutterBottom component="div">
                                                {t("calculators.laboratory.tests.details.description")}
                                            </Typography>
                                            <span>
                                                {t(row.description)}
                                            </span>
                                        </Box> : null
                                }
                                {
                                    t(row.deliveryReport).length > 1 ?
                                        <Box>
                                            <Typography variant="h4" gutterBottom component="div">
                                                {t("calculators.laboratory.tests.details.deliveryReport")}
                                            </Typography>
                                            <span>
                                                {t(row.deliveryReport)}
                                            </span>
                                        </Box> : null
                                }
                                {
                                    t(row.machine).length > 1 ?
                                        <Box>
                                            <Typography variant="h4" gutterBottom component="div">
                                                {t("calculators.laboratory.tests.details.machine")}
                                            </Typography>
                                            <span>
                                                {t(row.machine)}
                                            </span>
                                        </Box> : null
                                }
                                {
                                    t(row.customPrice).length > 1 ?
                                        <Box>
                                            <Typography variant="h4" gutterBottom component="div">
                                                {t("calculators.laboratory.tests.details.customPrice")}
                                            </Typography>
                                            <span>
                                                {t(row.customPrice)}
                                            </span>
                                        </Box> : null
                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <Row key={row.name} row={row}/>
                            ))
                                }
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*TOOL BAR WITH SELECTED ROWS*/}
            <Box className="lab-services-table-toolbar">
                <EnhancedTableToolbar numSelected={selected.length} />
            </Box>

            {/*PAGINATION*/}
            <TablePagination
                rowsPerPageOptions={[3, 5, 10, 20]}
                labelRowsPerPage={t("mui.rowPerPage")}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}
