import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

 axios.create({
    baseURL : BASE_URL
});

 const axiosPrivate = axios.create({
    baseURL : BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials : true
});

export {
    axios,
    axiosPrivate,
    BASE_URL
}
