import {axiosPrivate} from "../api/axios";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {ErrorHandling} from "../tools/error-handling";


/**
 * Fetches all Orders from the customer's company.
 * @param {int} customerId
 * @returns {Promise<*[]|any>}
 */
export const getOrdersByCompany = async (customerId) => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Orders`, 'darkmagenta', 'bold');

    try {
        const orders = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/customized/${customerId}`);

        if (orders && orders.data.length > 0) {
            logInConsole(`Customer orders received`, 'green');
            logInConsoleObject(orders.data);
        }
        else{
            logInConsole(`No customer order found (empty)`, 'orange');
        }

        return orders.data

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quotes --->  ${resMessage}`, 'red');
        ErrorHandling( null, resMessage);

        throw error;
    }
}

/**
 * Fetches order details.
 * @param {string} orderToken
 * @returns {Promise<*[]|any>}
 */
export const getOrderDetails = async (orderToken) => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Order details`, 'darkmagenta', 'bold');

    try {
        const orderDetails = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${orderToken}/customized`);

        logInConsole(`Customer orders received`, 'green');
        logInConsoleObject(orderDetails.data);

        return orderDetails.data
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quotes --->  ${resMessage}`, 'red');
        ErrorHandling( null, resMessage);

        throw error;
    }
}

/**
 * Fetches repeat order info.
 * @param {int} orderId
 * @returns {Promise<*[]|any>}
 */
export const getRepeatOrderinfo = async (orderId) => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Repeat order information`, 'darkmagenta', 'bold');

    try {
        const repeatOrderInfos = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${orderId}/repeatOrderInfos`);

        logInConsole(`Repeat order information received`, 'green');
        logInConsoleObject(repeatOrderInfos.data);

        return repeatOrderInfos.data
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quotes --->  ${resMessage}`, 'red');
        ErrorHandling( null, resMessage);

        throw error;
    }
}

