const devDebug = true

const logInConsole = (message, color='white', fontWeight='normal') => {
    if (process.env.NODE_ENV === 'development' && devDebug) {
        console.log("%c" + message, "color:" + color + ";font-weight:"+fontWeight+';');
    }
}

const logInConsoleObject = (_object) => {
    if (process.env.NODE_ENV === 'development' && devDebug) {
        console.log(_object);
    }
}

const logInConsoleSpacer = () => {
    if (process.env.NODE_ENV === 'development' && devDebug) {
        console.log("");
        console.log("------------------------------------------------------------------");
    }
}

export {
    logInConsole,
    logInConsoleObject,
    logInConsoleSpacer
}
