export default function authHeader() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if(auth && auth.token) {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": 'Bearer '+ auth.token
        };
    }else {
        return {};
    }
}
