import { axios } from '../api/axios';
import {cleanUpLocalStorage} from "../tools/cookie-handler";

const register = (username, password) => {
    return axios.post( "/register", {
        username, password
    });
}

const loginSylius = (email, password) => {
    console.log('SYLIUS login')
    return axios.post("/shop/authentication-token", {email, password})
        .then((response) => {
            console.log(">> ",response.data)
            if(response.data) {
                sessionStorage.setItem("user", JSON.stringify(response.data));

            }
            return response.data;
    })
        .catch((e)=> {
            //API NOT ACCESSIBLE (CORS ?)
            console.log(">>> ",e);
            return e;
        })
}
const logout = () => {
    // removing data storage
    localStorage.removeItem("auth");
    cleanUpLocalStorage();
    window.location.reload();
}

const getCurrentUser = () => {
    console.log("getCurrentUser call");
    return JSON.parse(sessionStorage.getItem("user"))
}

const authService = {
    register,
    loginSylius,
    logout,
    getCurrentUser
}
export default authService;
