import {createContext, useState} from "react";
import * as React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import {addBusinessDays} from "date-fns";
const CalculatorRigidFlexContext = createContext({});

export const CalculatorRigidFlexProvider = ({ children }) => {

    //Cookie's storage
    const getStorageValue = (key) => {
        if (typeof window !== "undefined") {
            const itemInLS = localStorage.getItem(key);
            return itemInLS !== null ? JSON.parse(itemInLS) : null;
        }
    }

    //Useful for Api
    const axiosPrivate = useAxiosPrivate();

    const {auth} = useAuth();
    const [customer_id, setCustomer_id] = useState(getStorageValue('rigid-flex')?.rigidFlex.customer_id || parseInt(auth.customerId));

    //Backoffice connexion
    const [connectToBO, setConnectToBO] = useState(false);
    const [errorConnectionToBO, setErrorConnectionToBO] = useState(false);

    //For submit Popup
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const [value, setValue] = React.useState(0);

    //Component RigidFlex params
    const [quantity, setQuantity] = useState(getStorageValue('rigid-flex')?.rigidFlex.quantity || 120);
    const [layer, setLayer] = useState(getStorageValue('rigid-flex')?.rigidFlex.layer || 5);
    const [description, setDescription] = useState(getStorageValue('rigid-flex')?.rigidFlex.description || "");
    const [media, setMedia] = useState();

    //Calendar params
    const [countryId, setCountryId] = useState(getStorageValue('rigid-flex')?.rigidFlex.countryId || 73);
    const [minDeliveryDate, setMinDeliveryDate] = useState( addBusinessDays(new Date() , 4));
    const [daysToDelivery, setDaysToDelivery] = useState( getStorageValue('rigid-flex')?.rigidFlex.daysToDelivery || "");
    const [requestedDate, setRequestedDate] = useState(getStorageValue('rigid-flex')?.rigidFlex.requestedDate || "");
    const [deliveryDate, setDeliveryDate] = useState(requestedDate ? new Date(requestedDate) : null);

    const [countriesList, setCountriesList] = useState([]);

    return (
        <CalculatorRigidFlexContext.Provider value={{
            getStorageValue,
            axiosPrivate,
            connectToBO, setConnectToBO,
            errorConnectionToBO, setErrorConnectionToBO,
            media, setMedia,
            value, setValue,
            auth,
            customer_id, setCustomer_id,
            quantity, setQuantity,
            layer, setLayer,
            description, setDescription,
            countryId, setCountryId,
            minDeliveryDate, setMinDeliveryDate,
            daysToDelivery, setDaysToDelivery,
            requestedDate, setRequestedDate,
            countriesList, setCountriesList,
            deliveryDate, setDeliveryDate,
            isSuccess, setIsSuccess,
            isFailed, setIsFailed,
        }} displayName="CalculatorRigidFlex">
            {children}
        </CalculatorRigidFlexContext.Provider>
    )
}

export default CalculatorRigidFlexContext;
