import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import {ButtonIcape, ButtonIcapeOutlined} from "../../../button/button-icape";
import React, {useState} from "react";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {regex} from "../../../../tools/regex";
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UserAddressEdit = (props) => {
    const {t} = useTranslation();
    const {address, handleUpdateAddress, isAddAddress, isCompanyAddress, handleAddData,
        company, setCompany,
        alias, setAlias,
        firstName, setFirstName,
        lastName, setLastName,
        phoneNumber, setPhoneNumber,
        postcode, setPostcode,
        street, setStreet,
        city, setCity,
        countryCode, setCountryCode,
        countriesList,
        isDefaultAddress, setIsDefaultAddress,
        isPrivateAddress, setIsPrivateAddress,
        setShowFormAddress
    } = props;

    const switchDefaultAddress = {inputProps: {'aria-label': 'default address'}};
    const switchPrivateAddress = {inputProps: {'aria-label': 'private address'}};

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

    const [isAliasError, setIsAliasError] = useState(false)
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isZipCodeError, setIsZipCodeError] = useState(false);

    const handlePhoneNumberChange = (newPhoneNumber) => {
        setIsPhoneNumberValid(!matchIsValidTel(newPhoneNumber));
        setPhoneNumber(newPhoneNumber);
    }


    const checkLastName = () => {
        if (regex(lastName, 'name')) {
            setIsLastNameError(false);
            return true
        }
        setIsLastNameError(true);
        return false
    }
    const checkFirstName = () => {
        if (regex(firstName, 'name')) {
            setIsFirstNameError(false);
            return true
        }
        setIsFirstNameError(true);
        return false
    }
    const checkZipCode = () => {
        if (regex(postcode, 'zipCode')) {
            setIsZipCodeError(false);
            return true
        }
        setIsZipCodeError(true);
        return false
    }
    const checkAddress = () => {

        const isLastOk = checkLastName();
        const isFirstOk = checkFirstName();
        const isZipOk = checkZipCode();

        if (isLastOk && isFirstOk && isZipOk && !isPhoneNumberValid) {
            handleUpdateAddress({id: address.id}, isCompanyAddress);
        }
    }

    return (
        <>
            {/*FIELDS*/}
            <Box className="user-content-field">
                {/*ALIAS*/}
                <TextField
                    label={t("common.addressAlias")}
                    value={alias}
                    error={isAliasError}
                    onChange={e => setAlias(e.target.value)}
                />
                {/*LAST NAME*/}
                <TextField
                    label={t("common.lastName")}
                    value={lastName}
                    error={isLastNameError}
                    onChange={e => setLastName(e.target.value)}
                />
                {/*FIRST NAME*/}
                <TextField
                    label={t("common.firstName")}
                    value={firstName}
                    error={isFirstNameError}
                    onChange={e => setFirstName(e.target.value)}
                />
                {/*ADDRESS*/}
                <TextField
                    label={t("common.address")}
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                />
                {/*ZIP CODE*/}
                <TextField
                    label={t("common.zipCode")}
                    value={postcode}
                    error={isZipCodeError}
                    onChange={e => setPostcode(e.target.value)}
                />
                {/*CITY*/}
                <TextField
                    label={t("common.city")}
                    value={city}
                    onChange={e => setCity(e.target.value)}
                />
                {/*COUNTRY*/}
                <FormControl>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId={t("common.country")}
                        id="country"
                        value={countryCode}
                        label='Country'
                        onChange={e => setCountryCode(e.target.value)}
                    >
                        {/*TODO FILER IN THE FIRST PLACE*/}
                        {countriesList.map((country) => {
                            if (country.enabled) {
                                return <MenuItem name="pays" value={country.code} key={country.id}>
                                    {country.name}
                                </MenuItem>
                            }
                        })}
                    </Select>
                </FormControl>
                {/*PHONE NUMBER*/}
                <MuiTelInput
                    label={t("common.phone")}
                    value={phoneNumber}
                    defaultCountry="FR"
                    error={isPhoneNumberValid}
                    onChange={handlePhoneNumberChange}
                />
            </Box>

            {!isCompanyAddress &&
                <>
                    {/*SWITCH DEFAULT*/}
                    <Box className="user-content-switch-address">
                        <FormGroup>
                            <FormControlLabel label={t("account.addresses.defaultAddress")} control={
                                <Switch {...switchDefaultAddress}
                                        checked={isDefaultAddress}
                                        onChange={e => setIsDefaultAddress(e.target.checked)}
                                />}
                            />
                        </FormGroup>
                    </Box>

                    {/*SWITCH PRIVATE*/}
                    <Box className="user-content-switch-address">
                        <FormGroup>
                            <FormControlLabel label={t("account.addresses.privateAddress")} control={
                                <Switch {...switchPrivateAddress}
                                        checked={isPrivateAddress}
                                        onChange={e => setIsPrivateAddress(e.target.checked)}
                                />}
                            />
                        </FormGroup>
                        <Box className="user-content-switch-address-icon" title={"A private address can only be seen by you, it won't appear in your companies addresses"}>
                            <InfoOutlinedIcon />
                        </Box>

                    </Box>
                </>
            }

            {/*CTA*/}
            {isAddAddress ?
                <>
                    <Box className="user-content-cta">
                        <ButtonIcapeOutlined onClick={() => setShowFormAddress(false)}>
                            {t("cta.cancel")}
                        </ButtonIcapeOutlined>
                        <ButtonIcape onClick={handleAddData}>
                            {t("cta.add")}
                        </ButtonIcape>
                    </Box>
                </>
                :
                <>
                    <Box className="user-content-cta">
                        <ButtonIcapeOutlined onClick={() => setShowFormAddress(false)}>
                            {t("cta.cancel")}
                        </ButtonIcapeOutlined>
                        <ButtonIcape onClick={() => checkAddress()}>
                            {t("cta.update")}
                        </ButtonIcape>
                    </Box>
                </>
            }
        </>
    )
}
export default UserAddressEdit;
