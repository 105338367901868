import {Box, Typography} from "@mui/material";
import "./user-addresses.scss"
import {ButtonIcapeGreen, ButtonIcapeOutlinedRed} from "../../../button/button-icape";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import React from "react";
import {useTranslation} from "react-i18next";

const UserAddressList = (props) => {
    const {t} = useTranslation();
    const {address, handleModifyAddress, setIsDeletingPopup, countriesList, isCompanyAddress} = props;

    // FUNCTION TO RETRIEVE COUNTRY NAME
    const countryNameFinder = (_countryCode) => {
        let countryName = null;

        countriesList.forEach(function(country) {
            if (country.code === _countryCode) {
                countryName = country.name;
            }
        });

        return countryName;
    }

    return (
        <Box className="user-address-card">

            {/*ADDRESSE TEXT*/}
            <Box className="user-address-card-text">
                {/*<Typography variant={"h4"}>{address.company}</Typography>*/}
                <Typography variant={"h4"}>{address.alias}</Typography>
                <Typography component={"span"}>{address.lastName} {address.firstName}</Typography>
                <Typography component={"span"}>{address.street}</Typography>
                <Typography component={"span"}>{address.postcode} {address.city}</Typography>
                <Typography component={"span"}>{countryNameFinder(address.countryCode)}</Typography>
                <Typography component={"span"}>{address.phoneNumber}</Typography>
                <Typography component={"span"}>{address.isDefaultAddress ? t("account.addresses.defaultAddress") : ''}</Typography>
                {address.isPrivateAddress &&
                    <Box className="user-address-card-privacy" title="Private address">
                        <LockPersonIcon />
                    </Box>
                }
            </Box>

            {/*CTA*/}
            {!isCompanyAddress &&
                <Box className={"user-address-card-cta"} sx={address.isDefaultAddress ? null : {marginTop: '20px'}}>
                    {!address.isDefaultAddress && !isCompanyAddress ?
                        <ButtonIcapeOutlinedRed onClick={() => setIsDeletingPopup(address?.id)}>
                            {t("cta.delete")}
                        </ButtonIcapeOutlinedRed>
                        : null
                    }
                    <ButtonIcapeGreen onClick={() => handleModifyAddress(address?.id, isCompanyAddress)}>
                        {t("cta.modify")}
                    </ButtonIcapeGreen>
                </Box>
            }
        </Box>
    )
}
export default UserAddressList;
