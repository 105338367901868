import React, {useContext, useEffect} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getCountries} from "../../../services/countries.service";
import AllSteps from "./steps/all-steps";
import '../calculators.scss';
import './calculator-rigidFlex.scss';
import CalculatorRigidFlexContext from "../../../context/calculatorRigidFlexProvider";
import {Alert} from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Snackbar
} from "@mui/material";
import {ButtonIcape, ButtonIcapeSoftBlue} from "../../button/button-icape";
import {useTranslation} from "react-i18next";
import UserContext from "../../../context/UserProvider";


function CalculatorRigidFlex() {
    const {t} = useTranslation();

    const{
        axiosPrivate,
        connectToBO, setConnectToBO,
        errorConnectionToBO, setErrorConnectionToBO,
        media, setMedia,
        auth,
        customer_id, setCustomer_id,
        quantity, setQuantity,
        layer, setLayer,
        description, setDescription,
        countryId, setCountryId,
        minDeliveryDate, setMinDeliveryDate,
        daysToDelivery, setDaysToDelivery,
        requestedDate, setRequestedDate,
        deliveryDate, setDeliveryDate,
        isSuccess, setIsSuccess,
        isFailed, setIsFailed,
    } = useContext(CalculatorRigidFlexContext);


    //Storage cookie rigid-flex's body
    useEffect(() => {
        localStorage.setItem('rigid-flex', JSON.stringify({
            rigidFlex: {
                customer_id,
                quantity,
                layer,
                description,
                countryId,
                daysToDelivery,
                requestedDate,
                minDeliveryDate,
                media
            }
        }));
    }, [customer_id, quantity, layer, description, countryId, daysToDelivery, requestedDate, minDeliveryDate, media])

    //Api post in Backend
    const handleSubmit = () => {

        let config = {headers: {"Content-Type": "application/json"}};

        let data = {
            "customer_id": parseInt(auth.customerId),
            "quantity": parseInt(quantity),
            layer,
            description,
            countryId,
            daysToDelivery,
            requestedDate,
            minDeliveryDate,
            media
        };

        setConnectToBO(true);

        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/rigid-flexes`, data, config)
            .then(
                (response) => {
                    //Popup success
                    setIsSuccess(true);

                    setConnectToBO(false);
                    console.log("Data successfully saved !!");

                    setQuantity(120);
                    setLayer(5);
                    setDescription('');
                    setMedia();
                },
                (error) => {
                    //Popup error
                    setIsFailed(true)

                    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    console.log("Something's wrong ---> ", resMessage);

                    setErrorConnectionToBO(true);
                    setTimeout(retryConnection, 4000)
                }

            ).finally(
            () => {
                setConnectToBO(false);
            }
        )
};

    const retryConnection = () => {
        //true = complete reload from the server and not from the cached version
        //window.location.reload(true);
    }

    return (
        <div className="calculator-wrapper">

            {/*PROGRESS BAR CONNECT TO BO*/}
            {connectToBO ? (
                <Box className="connect-bo-progress-bar">
                    <LinearProgress/>
                </Box>) : "" }

            {/*CALCULATOR RIGID-FLEX*/}
            <div className="calculator no-project-panel">

                {/*SNACKBAR CONNECT TO BO FAILED*/}
                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}} open={errorConnectionToBO}
                          message="Connection with back office failed" key='topright'>
                    <Alert severity="error" variant="filled">Connection with back office failed ! Please try again in
                        few minutes.</Alert>
                </Snackbar>

                {/*TITLE*/}
                <Typography variant={'h1'}>{t("calculators.rigidFlex.calculatorTitle")}</Typography>

                {/*TODO CHECK IF USEFUL (dataFromApi ?)*/}
                {/*RENDERER*/}
                <Box className={"content-wrapper"}>
                    <AllSteps/>
                </Box>
                {isSuccess ?
                    <>
                        <Dialog open={isSuccess} onClose={ () => setIsSuccess(false)}>
                            <DialogTitle>{t("popup.RFQSent.title")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t("popup.RFQSent.description")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <ButtonIcapeSoftBlue onClick={ () => setIsSuccess(false)}>{t("cta.close")}</ButtonIcapeSoftBlue>
                            </DialogActions>
                        </Dialog>
                    </>
                : null
                }

                {isFailed ?
                    <>
                            <Dialog open={isFailed} onClose={ () => setIsFailed(false)}>
                                <DialogTitle>{t("popup.RFQSent.failedTitle")}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {t("popup.RFQSent.failedDescription")}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <ButtonIcapeSoftBlue onClick={ () => setIsFailed(false)}>{t("cta.close")}</ButtonIcapeSoftBlue>
                                </DialogActions>
                            </Dialog>
                    </>
                : null
                }

                {/*CTA*/}
                <Box className={"step-cta-desktop"}>
                    <ButtonIcape onClick={handleSubmit}>{t("cta.submitRequest")}</ButtonIcape>
                </Box>
            </div>

        </div>
    );
}

export default CalculatorRigidFlex;
