import React, {useContext} from 'react';
import {Box, Typography} from "@mui/material";
import './project-panel.scss';
import PriceMatrix from "./price-matrix";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {useLocation} from "react-router-dom";
import {getAreaSize} from "../../../../tools/unit.utils";
import UserContext from "../../../../context/UserProvider";
import {useTranslation} from "react-i18next";
import LockIcon from '@mui/icons-material/Lock';


function ProjectPanel(props) {
    const {t} = useTranslation();

    const {
        pcbWidth, pcbLength,
        pcbWidthIn, pcbLengthIn,
        pcbPerPanel, panelQuantity,
        unitFormat, pcbDeliveryFormat,
        unitPrice_PP, total_PP, stencil_PP,
        quantity, stencilTypeId,
        incoterm_PP, requestedDate, minDeliveryDate,
        testing_time, test_price, pick_up_cost, return_cost,
        total_price, isTestLab, isNA_lab, returnPCB, priceMatrix,
        repeatOrderMode, loadCurrency,
        country, tariff, batchSize} = props.prices;

    const location =useLocation();

    // CURRENCY
    const {activeCurrency} = useContext(UserContext);


    return (
        location.pathname.endsWith("repeat-order") ? ""
            :
            <Box className={"project-panel-wrapper"}>


                {/*PRICE MATRIX Except for lab services*/}
                <Box className="project-panel-price-matrix-wrapper"
                     sx={location.pathname === '/laboratory-services' ? {display:'none'} : {}}>
                    <Typography variant={'h2'}>{t("calculators.projectPanel.priceMatrix")}</Typography>
                    <PriceMatrix matrix={{requestedDate, minDeliveryDate, priceMatrix, repeatOrderMode}}/>
                </Box>

                {/*PROJECT INFORMATION*/}
                <Box className={"panel-project-info-wrapper"}>
                    {/*TITLE*/}
                    <Typography variant={'h2'}>{t("calculators.projectPanel.projectInformation")}</Typography>

                    {/*DISCOUNT*/}
                    <Box component="span" className={"panel-discount"}>
                        {t("promotions.selforder5.description")}
                        <br/>
                        {t("common.code")}: <span>selforder5</span>
                    </Box>

                    {isTestLab ?
                        <>

                            {/*PRICING LabServices*/}
                            {/*TODO ca pete ici parce que j'ai tout passé en tableau mais pas pour le lab parce qu'on s'en balance sa mere du LAB cHeH*/}
                            <Box className={"panel-pricing"}>
                                {/*COLONNE1*/}
                                <Box className={"panel-pricing-column"}>

                                    <Box component="span">
                                        {t("calculators.projectPanel.totalTestingTime")}
                                    </Box>
                                    <Box component="span">
                                        {t("calculators.projectPanel.testPrice")}
                                    </Box>
                                    <Box component="span">
                                        {t("calculators.projectPanel.PickUpCost")}
                                    </Box>

                                    {returnPCB ?
                                        <>
                                            <Box component="span">
                                                {t("calculators.projectPanel.returnCost")}
                                            </Box>
                                        </>
                                        : null
                                    }

                                    <Box component="span">
                                        {t("calculators.projectPanel.totalExclTax")}
                                    </Box>

                                </Box>

                                {/*COLONNE2*/}
                                <Box className={"panel-pricing-column"}>
                                    <Box component="span">
                                        {testing_time} Days
                                    </Box>
                                    <Box component="span">
                                        {test_price} {activeCurrency}
                                    </Box>
                                    <Box component="span">
                                        {pick_up_cost} {activeCurrency}
                                    </Box>

                                    {returnPCB ?
                                        <>
                                            <Box component="span">
                                                {/*{return_cost.toFixed(2)} €*/}
                                                {return_cost} {activeCurrency}
                                            </Box>
                                        </>
                                        : null
                                    }

                                    <Box component="span">
                                        {total_price} {activeCurrency}
                                    </Box>
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            {/*PRICING*/}
                            <table className="panel-pricing">
                                <tbody>
                                <tr>
                                    <td>{t("calculators.projectPanel.pcbAreaSize")}</td>
                                    <td>
                                        {batchSize}{unitFormat === 'mm' ? 'm' : unitFormat}²
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("calculators.projectPanel.pcbUnitPrice")}</td>
                                    <td>{unitPrice_PP.toFixed(2)} { repeatOrderMode.lockPrice ? loadCurrency.symbol : activeCurrency }</td>
                                </tr>
                                {stencilTypeId > 1 &&
                                    <tr>
                                        <td>{t("common.stencil")}</td>
                                        <td>{stencil_PP} {activeCurrency}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>{t("common.incoterm")}</td>
                                    <td>{incoterm_PP ? incoterm_PP : "-"}</td>
                                </tr>
                                <tr>
                                    <td>{t("calculators.toolbar.quantity")}</td>
                                    <td>{isNaN(quantity) ? 0 : quantity}</td>
                                </tr>
                                {
                                    country?.code === "US" &&
                                    <tr>
                                        <td>Tariff</td>
                                        <td>{tariff} { repeatOrderMode.lockPrice ? loadCurrency.symbol : activeCurrency }</td>
                                    </tr>
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>{t("calculators.projectPanel.totalExclTax")}</td>
                                    <td>
                                        <Box className={repeatOrderMode.lockPrice && "table-price-lock"}>
                                            { repeatOrderMode.lockPrice &&
                                                <LockIcon/>
                                            }
                                            { total_PP.toFixed(2) } { repeatOrderMode.lockPrice ? loadCurrency.symbol : activeCurrency }
                                        </Box>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </>
                    }
                </Box>

                {/* {isNA_lab ?
                    <>
                        HELPER BAN NA
                        <Box className={"helper-ban"}>
                            <Box className="helper-ban-icon">
                                <ContactSupportIcon/>
                            </Box>
                            <a href="https://www.icape-group.com/contact/contact-forms" target="_blank" rel="noreferrer">
                                For any question about US tests laboratories,
                                you can contact us at: <br/>
                                <strong>Phone :</strong> +1 317-405-9427 <br/>
                                <strong>Fax :</strong> +1 317-663-0729 <br/>
                                <strong>Email :</strong> sales@divsys.com
                            </a>
                        </Box>
                    </>
                    :
                    <>
                        HELPER BAN
                        <Box className={"helper-ban"}>
                            <Box className="helper-ban-icon">
                                <ContactSupportIcon/>
                            </Box>
                            <a href="https://www.icape-group.com/contact/contact-forms" target="_blank" rel="noreferrer">
                                If your project is not feasible, you can send us your request, you will receive a personalized offer
                                as soon as possible.
                            </a>
                        </Box>
                    </>
                }*/}
            </Box>
    )
}

export default ProjectPanel;
