import React from "react";

function Logo (props) {

    return (
        <>
            <a href="https://www.icape-group.com/">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 1404.51 491.8"
                     height={props.height}
                     fill= {props.fillColor}
                >
                    <path  d="m1398.5,259.2c2,3.6,3.6,7.5,4.5,11.6,1,4,1.5,8.2,1.4,12.3.1,4.7-.8,9.3-2.4,13.7-1.5,4-3.9,7.7-6.9,10.8-3,3.1-6.6,5.5-10.6,7.1-4.3,1.7-9,2.6-13.7,2.5-4.7.1-9.4-.8-13.8-2.7-4-1.7-7.6-4.2-10.6-7.4-2.9-3.2-5.3-6.9-6.8-10.9-1.6-4.3-2.5-8.9-2.4-13.5,0-4.1.6-8.2,1.9-12.1s3.5-7.5,6.5-10.4l6,5.7c-2.4,2.2-4.2,4.9-5.4,7.9-1.2,3-1.9,6.3-1.9,9.5,0,3.5.7,7,2,10.2,2.6,6.2,7.6,11.1,13.9,13.6,3.4,1.3,7,2,10.7,1.9,3.6,0,7.2-.6,10.6-1.9,3.1-1.2,6-3.1,8.4-5.4,2.4-2.4,4.3-5.2,5.6-8.4,1.4-3.4,2-7.1,2-10.8,0-2.7-.3-5.5-.9-8.1-.6-2.7-1.5-5.3-2.8-7.8h-19.6v14.7h-7v-22.3l31.3.2Z"/>
                    <path  d="m1339,243.3v-16.3c0-3.1.2-6.1.5-9.2.3-2.8,1.1-5.6,2.3-8.2,1.2-2.4,3-4.4,5.3-5.9s5.5-2.2,9.4-2.2c4.7,0,8.5,1.4,11.4,4.2,2.9,2.8,4.8,6.7,5.5,11.7l29.4-18.1v9.3l-28.7,17.3v9.8h28.7v7.6h-63.8Zm28.1-7.6v-6.8c0-2.1,0-4.3-.2-6.5-.1-2.1-.5-4.2-1.2-6.2-.6-1.8-1.7-3.4-3.1-4.7-1.7-1.3-3.9-2-6.1-1.9-1.9-.1-3.8.4-5.5,1.4-1.4.9-2.5,2.1-3.2,3.6-.8,1.6-1.3,3.3-1.5,5-.2,1.8-.4,3.7-.4,5.5v10.6h21.2Z"/>
                    <path  d="m1404.5,158.4c.1,4.6-.8,9.1-2.5,13.4-1.6,4-4,7.5-7,10.6-3,3-6.7,5.4-10.7,7-8.6,3.3-18.2,3.3-26.8,0-4-1.6-7.6-3.9-10.7-7-3-3-5.4-6.6-7-10.6-3.4-8.6-3.4-18.2,0-26.8,1.6-3.9,4-7.5,7-10.6,3-3,6.7-5.4,10.7-7,8.6-3.3,18.2-3.3,26.8,0,4,1.6,7.6,3.9,10.7,7,3,3,5.4,6.6,7,10.6,1.7,4.3,2.6,8.8,2.5,13.4Zm-7,0c0-3.5-.7-7-2.1-10.2-1.3-3-3.3-5.8-5.7-8-2.5-2.3-5.3-4-8.4-5.2-6.7-2.5-14-2.5-20.7,0-3.1,1.2-6,2.9-8.4,5.2-2.4,2.3-4.4,5-5.7,8-2.8,6.6-2.8,13.9,0,20.5,1.3,3,3.3,5.8,5.7,8,2.5,2.3,5.3,4,8.4,5.2,6.7,2.5,14,2.5,20.7,0,3.1-1.2,6-2.9,8.4-5.2,2.4-2.2,4.4-5,5.7-8,1.4-3.2,2.1-6.7,2.1-10.3h0Z"/>
                    <path  d="m1378.5,64.9c3.4,0,6.7.4,9.9,1.3,3,.8,5.8,2.2,8.3,4.1,2.5,2,4.4,4.6,5.7,7.4,2.8,7.2,2.8,15.2,0,22.4-1.3,2.9-3.2,5.4-5.7,7.5-2.4,1.9-5.3,3.3-8.3,4.1-3.2.9-6.6,1.3-9.9,1.3h-39.5v-7.6h38.5c2.5,0,5-.3,7.5-.8,2.3-.5,4.5-1.4,6.4-2.7,1.9-1.3,3.4-3,4.5-5.1,2.2-5.1,2.2-10.8,0-15.9-1.1-2-2.6-3.8-4.5-5.1s-4.1-2.2-6.4-2.7c-2.5-.5-5-.8-7.5-.8h-38.5v-7.6h39.5v.2Z"/>
                    <path  d="m1339,49.3v-19c0-2.8.3-5.7.9-8.4.5-2.5,1.5-5,2.9-7.1s3.2-3.8,5.4-5c5.4-2.5,11.6-2.5,17,.1,2.2,1.2,4,3,5.4,5.1,1.3,2.1,2.3,4.4,2.8,6.8.5,2.3.8,4.6.8,7v12.8h28.7v7.6l-63.9.1Zm28.1-7.6v-12.8c.2-3.3-.7-6.5-2.6-9.2-1.7-2.4-4.4-3.6-8-3.6s-6.3,1.2-8,3.6c-1.8,2.7-2.7,6-2.6,9.2v12.8h21.2Z"/>
                    <path  d="m4.9,8h68.1v309.3H4.9V8h0Z"/>
                    <path  d="m572.9,8h56.3l134.5,309.3h-76.9l-26.7-65.5h-120l-25.8,65.5h-75.1L572.9,8Zm26.2,90l-37.6,96.1h75.6l-38-96.1Z"/>
                    <path  d="m794.8,8h114.9c15.3-.1,30.5,1.4,45.4,4.4,14.3,2.9,26.7,7.9,37.3,15.1,10.8,7.3,19.5,17.2,25.3,28.8,6.3,12.1,9.4,27.3,9.4,45.7s-2.9,33.2-8.7,45.4c-5.4,11.7-13.6,21.7-24,29.3-10.2,7.3-22.3,12.5-36.3,15.5-14.9,3.2-30.2,4.7-45.4,4.6h-49.8v120.6h-68.1V8h0Zm68.1,131h45.4c5.9,0,11.9-.6,17.7-1.7,5.4-1.1,10.6-3.1,15.3-5.9,4.5-2.8,8.3-6.7,10.9-11.4,2.8-4.8,4.1-10.8,4.2-18.1,0-7.9-1.8-14.2-5.5-19s-8.5-8.7-14-11.1c-6-2.7-12.4-4.4-19-5-6.7-.7-13.4-1.1-20.1-1.1h-34.9v73.3Z"/>
                    <path  d="m1072.6,8h210.1v62.9h-142v57.7h134.1v62.9h-134.1v62.9h149.8v62.9h-218l.1-309.3Z"/>
                    <path  d="m279.6,250.8c-48.7,0-88.3-39.5-88.3-88.2s39.5-88.3,88.2-88.3c34.5,0,65.9,20.1,80.3,51.5h78.1C421.2,53.8,356.6,0,279.6,0,190,.1,117.1,73,117.1,162.6s72.9,162.5,162.5,162.5c77,0,141.6-53.8,158.3-125.8h-78.1c-14.4,31.4-45.7,51.5-80.2,51.5Z"/>
                    <circle cx="278.6" cy="162.6" r="40.5"/>
                    <path  d="m27.6,398.7H0v-7.1h63.4v7.2h-27.6v71.2h-8.3v-71.3h.1Z"/>
                    <path  d="m128.8,417.1c4.4,4.3,6.6,10.5,6.5,18.8v34.2h-8v-33.5c0-6.1-1.5-10.8-4.6-14s-7.4-4.8-13.1-4.8c-6.3,0-11.4,1.9-15.1,5.7s-5.5,9-5.5,15.6v30.9h-8v-83.1h8v34.5c2.2-3.5,5.3-6.2,9-8,4.1-1.9,8.5-2.8,13-2.8,7.5,0,13.4,2.1,17.8,6.5Z"/>
                    <path  d="m214.6,443h-49.3c.2,5.7,2.8,11.1,7.1,14.8,4.3,3.8,9.6,5.7,16.1,5.7,3.5,0,6.9-.7,10.1-2,3.1-1.3,5.8-3.3,8-5.8l4.5,5.2c-2.6,3.2-6,5.6-9.8,7.2-9.4,3.7-20,3.2-29-1.4-4.6-2.5-8.4-6.2-10.9-10.7-2.7-4.7-4-10-3.9-15.5-.1-5.4,1.1-10.7,3.7-15.4,2.4-4.4,6-8.1,10.3-10.7,4.5-2.6,9.6-3.9,14.7-3.8,5.1-.1,10.2,1.2,14.7,3.8,4.3,2.5,7.9,6.2,10.3,10.6,2.5,4.8,3.8,10.1,3.7,15.5l-.3,2.5Zm-42.7-20.1c-3.9,3.7-6.2,8.8-6.6,14.2h41.8c-.3-5.4-2.7-10.4-6.6-14.2-8.1-7.3-20.4-7.3-28.6,0h0Z"/>
                    <path  d="m318,414.4c4.4,2.5,8.1,6.1,10.5,10.6,5.1,9.8,5.1,21.5,0,31.3-2.4,4.4-6,8.1-10.5,10.6-4.6,2.6-9.8,3.8-15.1,3.8-4.5.1-8.9-.9-12.9-2.9-3.9-2-7.1-5-9.5-8.7v32.8h-8v-80.7h7.6v11.6c2.3-3.8,5.6-6.9,9.5-9,4.1-2.1,8.6-3.2,13.3-3.1,5.4-.2,10.5,1.1,15.1,3.7Zm-4.3,46.2c3.4-1.9,6.1-4.7,7.9-8.1,2-3.7,2.9-7.8,2.9-12,.1-4.1-.9-8.2-2.9-11.9-1.8-3.4-4.6-6.2-7.9-8.1-7-3.9-15.5-3.9-22.5,0-3.3,1.9-6,4.7-7.9,8.1-1.9,3.7-2.9,7.8-2.9,11.9-.1,4.2.9,8.3,2.9,12,1.8,3.4,4.5,6.2,7.9,8.1,7,3.8,15.5,3.8,22.5,0Z"/>
                    <path  d="m363.5,466.7c-4.5-2.5-8.2-6.2-10.8-10.7-5.2-9.6-5.2-21.3,0-30.9,2.5-4.5,6.3-8.2,10.8-10.7,9.6-5.1,21.1-5.1,30.7,0,4.5,2.5,8.2,6.2,10.7,10.7,5.1,9.7,5.1,21.2,0,30.9-2.5,4.5-6.2,8.2-10.7,10.7-9.6,5.1-21.1,5.1-30.7,0Zm26.6-6.1c3.3-1.9,6-4.7,7.8-8.1,3.7-7.6,3.7-16.4,0-24-1.8-3.4-4.5-6.2-7.8-8.1-7-3.8-15.5-3.8-22.5,0-3.3,1.9-6,4.7-7.8,8.1-3.8,7.5-3.8,16.5,0,24,1.8,3.4,4.5,6.2,7.8,8.1,7,3.8,15.5,3.8,22.5,0Z"/>
                    <path  d="m516,411.1l-22.3,58.9h-7.5l-18.6-48.3-18.6,48.3h-7.5l-22.2-58.9h7.6l18.5,50.1,18.9-50.1h6.8l18.8,50.1,18.7-50.1h7.4Z"/>
                    <path  d="m583.7,443h-49.3c.2,5.7,2.8,11.1,7.1,14.8,4.3,3.8,9.6,5.7,16.1,5.7,3.5,0,6.9-.7,10.1-2,3.1-1.3,5.8-3.3,8-5.8l4.5,5.2c-2.6,3.2-6,5.6-9.8,7.2-4.1,1.7-8.5,2.5-12.9,2.5-5.6.1-11.1-1.2-16.1-3.9-4.6-2.5-8.4-6.2-10.9-10.7-2.7-4.7-4-10-3.9-15.5-.1-5.4,1.2-10.7,3.8-15.5,2.4-4.4,5.9-8.1,10.3-10.7,4.5-2.6,9.6-3.9,14.7-3.8,5.1-.1,10.2,1.2,14.7,3.8,4.3,2.5,7.8,6.2,10.2,10.6,2.5,4.8,3.8,10.1,3.7,15.5l-.3,2.6Zm-42.7-20.1c-3.9,3.7-6.2,8.8-6.5,14.2h41.8c-.3-5.4-2.7-10.4-6.5-14.2-8.3-7.3-20.6-7.3-28.8,0Z"/>
                    <path  d="m622.2,413.6c3.7-2,8.3-3,13.7-3v7.7l-1.9-.1c-6.2,0-11.1,1.9-14.6,5.7s-5.3,9.1-5.3,16v30h-8v-58.9h7.6v11.6c1.9-3.8,4.8-7,8.5-9Z"/>
                    <path  d="m701.6,466.7c-4.5-2.5-8.2-6.2-10.8-10.7-5.2-9.6-5.2-21.3,0-30.9,2.5-4.5,6.2-8.2,10.8-10.7,9.6-5.1,21.1-5.1,30.7,0,4.5,2.5,8.2,6.2,10.7,10.7,5.2,9.7,5.2,21.3,0,30.9-2.5,4.5-6.2,8.2-10.7,10.7-9.6,5.1-21.1,5.1-30.7,0Zm26.6-6.1c3.3-1.9,6-4.7,7.8-8.1,3.7-7.6,3.7-16.4,0-24-1.8-3.4-4.5-6.2-7.8-8.1-7-3.8-15.5-3.8-22.5,0-3.3,1.9-6,4.7-7.8,8.1-3.8,7.5-3.8,16.5,0,24,1.8,3.4,4.5,6.2,7.8,8.1,7,3.8,15.5,3.8,22.5,0Z"/>
                    <path  d="m780.9,395.8c-1.8,1.9-2.6,4.6-2.6,8.3v6.9h18.2v6.7h-17.9v52.2h-8v-52.2h-10.5v-6.7h10.5v-7.3c0-5.4,1.5-9.6,4.6-12.8,3.1-3.1,7.5-4.7,13.2-4.7,2.2,0,4.4.3,6.5,1,1.9.5,3.7,1.5,5.3,2.7l-2.7,5.9c-2.5-2-5.6-3-8.7-2.9-3.5.1-6.1,1-7.9,2.9Z"/>
                    <path  d="m894.3,414.4c4.4,2.5,8.1,6.1,10.5,10.6,5.1,9.8,5.1,21.5,0,31.3-2.4,4.4-6.1,8.1-10.5,10.6-4.6,2.6-9.8,3.8-15.1,3.8-4.5.1-8.9-1-12.9-3-3.9-2-7.1-5-9.5-8.7v32.8h-8v-80.6h7.6v11.6c2.3-3.8,5.6-6.9,9.5-9,4.1-2.1,8.7-3.2,13.3-3.1,5.3-.2,10.4,1.1,15.1,3.7Zm-4.4,46.2c3.4-1.9,6.1-4.7,7.9-8.1,2-3.7,2.9-7.8,2.9-12,.1-4.2-.9-8.3-2.9-11.9-1.8-3.4-4.6-6.2-7.9-8.1-7-3.9-15.5-3.9-22.5,0-3.3,1.9-6,4.7-7.8,8.1-1.9,3.7-2.9,7.8-2.8,11.9-.1,4.2.9,8.3,2.8,12,1.8,3.4,4.5,6.2,7.8,8.1,7,3.8,15.5,3.8,22.5,0Z"/>
                    <path  d="m947,413.6c3.7-2,8.3-3,13.7-3v7.7l-1.9-.1c-6.2,0-11.1,1.9-14.6,5.7s-5.3,9.1-5.3,16v30h-7.9v-58.9h7.6v11.6c1.7-3.8,4.7-7,8.4-9Z"/>
                    <path  d="m1031.9,443h-49.3c.2,5.7,2.8,11.1,7.1,14.8,4.3,3.8,9.6,5.7,16.1,5.7,3.5,0,6.9-.7,10.1-2,3.1-1.3,5.8-3.3,8-5.8l4.5,5.2c-2.6,3.2-6,5.6-9.8,7.2-4.1,1.7-8.5,2.5-12.9,2.5-5.6.1-11.1-1.2-16.1-3.9-4.6-2.5-8.4-6.2-10.9-10.7-2.7-4.7-4-10-3.9-15.5-.1-5.4,1.2-10.7,3.8-15.5,2.4-4.4,5.9-8.1,10.3-10.7,4.5-2.6,9.6-3.9,14.7-3.8,5.1-.1,10.2,1.2,14.7,3.8,4.3,2.5,7.8,6.2,10.2,10.6,2.5,4.8,3.8,10.1,3.7,15.5l-.3,2.6Zm-42.7-20.1c-3.9,3.7-6.2,8.8-6.6,14.2h41.8c-.3-5.4-2.7-10.4-6.6-14.2-8.1-7.3-20.4-7.3-28.6,0h0Z"/>
                    <path  d="m1063.2,466.7c-4.5-2.5-8.3-6.2-10.8-10.7-2.7-4.7-4-10.1-3.9-15.5-.1-5.4,1.3-10.7,3.9-15.5,2.5-4.5,6.3-8.2,10.8-10.7,4.8-2.6,10.2-3.9,15.6-3.8,4.7-.1,9.4,1,13.6,3,3.9,1.9,7.2,4.9,9.5,8.7l-5.9,4c-1.8-2.8-4.4-5.1-7.4-6.5-3-1.5-6.4-2.2-9.8-2.2-4-.1-8,.9-11.5,2.9-3.4,1.9-6.2,4.7-8,8.1-3.8,7.5-3.8,16.5,0,24,1.8,3.4,4.6,6.2,8,8.1,3.5,1.9,7.5,2.9,11.5,2.9,3.4,0,6.7-.7,9.8-2.1,3-1.4,5.6-3.7,7.4-6.5l5.9,4c-2.3,3.8-5.6,6.8-9.5,8.7-4.2,2-8.9,3.1-13.6,3-5.4,0-10.8-1.3-15.6-3.9Z"/>
                    <path  d="m1123.6,396.5c-1.1-1-1.7-2.5-1.7-4s.6-2.9,1.7-3.9c1.1-1.1,2.6-1.7,4.1-1.7s3,.5,4.2,1.6c1.1,1,1.7,2.4,1.7,3.9,0,3.2-2.6,5.9-5.9,5.9-1.6-.1-3.1-.7-4.1-1.8h0Zm.1,14.6h8v58.9h-8v-58.9Z"/>
                    <path  d="m1162,468.5c-3.7-1-7.2-2.8-10.2-5.2l3.6-6.3c2.8,2.1,5.9,3.7,9.3,4.8,3.8,1.2,7.7,1.9,11.7,1.8,5.4,0,9.4-.8,11.9-2.5,2.5-1.5,4-4.2,3.9-7.1.1-1.9-.7-3.8-2.1-5.1-1.6-1.3-3.4-2.3-5.4-2.8-2.8-.8-5.7-1.4-8.6-1.8-3.9-.7-7.7-1.6-11.5-2.7-2.9-.9-5.4-2.5-7.4-4.8-2.1-2.2-3.1-5.3-3.1-9.3-.1-4.8,2.2-9.3,6.2-12.1,4.1-3.1,9.8-4.7,17.1-4.7,3.9,0,7.7.5,11.4,1.5,3.3.8,6.5,2.2,9.4,4l-3.5,6.4c-5.1-3.4-11.2-5.2-17.4-5-5.1,0-8.9.9-11.5,2.7-2.4,1.5-3.9,4.2-3.9,7-.1,2,.7,4,2.2,5.4,1.6,1.4,3.4,2.4,5.4,3,3,.8,6,1.5,9,2,3.8.7,7.6,1.6,11.3,2.7,2.8.9,5.3,2.4,7.3,4.6,2,2.2,3,5.2,3,9,.1,4.9-2.3,9.6-6.4,12.3-4.3,3-10.3,4.5-17.9,4.5-4.7-.1-9.3-.9-13.8-2.3Z"/>
                    <path  d="m1220.8,396.5c-1.1-1-1.7-2.5-1.7-4s.6-2.9,1.7-3.9c1.1-1.1,2.6-1.7,4.2-1.7,1.5,0,3,.5,4.1,1.6,1.1,1,1.7,2.4,1.7,3.9,0,3.2-2.6,5.9-5.9,5.9-1.6-.1-3-.7-4.1-1.8h0Zm.1,14.6h8v58.9h-8v-58.9Z"/>
                    <path  d="m1265.9,466.7c-4.5-2.5-8.2-6.2-10.8-10.7-5.2-9.6-5.2-21.3,0-30.9,2.5-4.5,6.3-8.2,10.8-10.7,9.6-5.1,21.1-5.1,30.7,0,4.5,2.5,8.2,6.2,10.7,10.7,5.1,9.7,5.1,21.2,0,30.9-2.5,4.5-6.2,8.2-10.7,10.7-9.5,5.1-21.1,5.1-30.7,0Zm26.6-6.1c3.3-1.9,6-4.7,7.8-8.1,3.7-7.6,3.7-16.4,0-24-1.8-3.4-4.5-6.2-7.8-8.1-7-3.8-15.5-3.8-22.5,0-3.3,1.9-6.1,4.7-7.8,8.1-3.8,7.5-3.8,16.5,0,24,1.8,3.4,4.5,6.2,7.8,8.1,7,3.8,15.5,3.8,22.5,0Z"/>
                    <path  d="m1381.3,417.1c4.4,4.3,6.6,10.5,6.6,18.8v34.2h-8v-33.5c0-6.1-1.5-10.8-4.6-14s-7.4-4.8-13.1-4.8c-6.3,0-11.4,1.9-15.1,5.7s-5.5,9-5.6,15.6v30.9h-8v-58.9h7.6v10.9c2.2-3.6,5.3-6.5,9-8.3,4.2-2.1,8.8-3.1,13.4-3,7.5-.1,13.4,2,17.8,6.4Z"/>
                </svg>
            </a>
        </>
    )
}

export default Logo;

