import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import UserAddressList from "./user-address-list";
import {ButtonIcapeOutlinedRed, ButtonIcapeSoftBlue} from "../../../button/button-icape";
import React, {useContext, useState} from "react";
import UserAddressEdit from "./user-address-edit";
import "./user-addresses.scss"
import {
    createCustomerAddress,
    deleteCustomerAddress,
    getCompanyAddresses,
    getCustomerAddresses,
    updateCompanyAddress,
    updateCustomerAddress
} from "../../../../services/customer.service";
import BackendConnection from "../../../backend-connection/backend-connection";
import {logInConsole} from "../../../../tools/debug";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";

const UserAddresses = () => {
    const {t} = useTranslation();

    const {
        auth,
        connectToBackend, setConnectToBackend,
        setErrorBackendConnection,
        isVerified,
        countriesList,
        customerAddress, setCustomerAddress,
        companyId,
        companyAddresses, setCompanyAddresses,
        addressId, setAddressId,
        getAddress, getCompanyAddress,
        aliasAddr, setAliasAddr,
        firstNameAddr, setFirstNameAddr,
        lastNameAddr, setLastNameAddr,
        companyAddr, setCompanyAddr,
        phoneNumberAddr, setPhoneNumberAddr,
        street, setStreet,
        postcode, setPostcode,
        city, setCity,
        countryCode, setCountryCode,
        isDefaultAddress, setIsDefaultAddress,
        isPrivateAddress, setIsPrivateAddress,
    } = useContext(UserContext);

    const [showFormAddress, setShowFormAddress] = useState(false);

    const [isAddAddress, setIsAddAddress] = useState(false);
    const [isDeletingPopup, setIsDeletingPopup] = useState(false);
    const [isCompanyAddress, setIsCompanyAddress] = useState(false);


    /**
     * Opens the address creation form.
     * Resets all fields before.
     */
    const handleAddAddress = () => {

        // RESET DES CHAMPS
        setCompanyAddr('');
        setAliasAddr('');

        setFirstNameAddr('');
        setLastNameAddr('');
        setPhoneNumberAddr('');
        setStreet('');
        setPostcode('');
        setCity('');
        setCountryCode('');
        setIsDefaultAddress(false);
        setIsPrivateAddress(false);

        setShowFormAddress(true);
        setIsAddAddress(true);
        logInConsole(`User is adding an address`, 'yellow');
    }

    /**
     * Handles address creation request then fetches addresses again.
     * @returns {Promise<void>}
     */
    const handleAddData = async () => {
        try {
            setConnectToBackend(true);
            const _addressToCreate = {
                alias : aliasAddr,
                firstName : firstNameAddr,
                lastName : lastNameAddr,
                company : companyAddr,
                phoneNumber: phoneNumberAddr,
                countryCode,
                street,
                city,
                postcode,
                isDefaultAddress,
                isPrivateAddress
            };
            await createCustomerAddress(auth.customerId, _addressToCreate);
            setCustomerAddress(await getCustomerAddresses(auth.customerId));
        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setShowFormAddress(false);
            setConnectToBackend(false);
        }
    }

    /**
     * Opens the address update form.
     * @param id
     * @param isCompanyAddress
     */
    const handleModifyAddress = (id, isCompanyAddress) => {
        setAddressId(id);
        setIsAddAddress(false);
        setShowFormAddress(true);
        setIsCompanyAddress(isCompanyAddress);


        //SET VARIABLES CHAMPS
        // setCompanyAddr(isCompanyAddress ? getCompanyAddress(id)?.company : getAddress(id)?.company);
        setAliasAddr(isCompanyAddress ? getCompanyAddress(id)?.alias : getAddress(id)?.alias);
        setFirstNameAddr(isCompanyAddress ? getCompanyAddress(id)?.firstName : getAddress(id)?.firstName);
        setLastNameAddr(isCompanyAddress ? getCompanyAddress(id)?.lastName : getAddress(id)?.lastName);
        setPhoneNumberAddr(isCompanyAddress ? getCompanyAddress(id)?.phoneNumber : getAddress(id)?.phoneNumber);
        setStreet(isCompanyAddress ? getCompanyAddress(id)?.street : getAddress(id)?.street);
        setPostcode(isCompanyAddress ? getCompanyAddress(id)?.postcode : getAddress(id)?.postcode);
        setCity(isCompanyAddress ? getCompanyAddress(id)?.city : getAddress(id)?.city);
        setCountryCode(isCompanyAddress ? getCompanyAddress(id)?.countryCode : getAddress(id)?.countryCode);
        setIsDefaultAddress(isCompanyAddress ? getCompanyAddress(id)?.isDefaultAddress : getAddress(id)?.isDefaultAddress);
        isCompanyAddress ? setIsPrivateAddress(false) : setIsPrivateAddress(getAddress(id)?.isPrivateAddress);

        window.scrollTo(0,0);
    }

    /**
     * Handles address update request then fetches addresses again
     * @param _address
     * @param _isCompanyAddress
     * @returns {Promise<void>}
     */
    const handleUpdateAddress = async (_address, _isCompanyAddress) => {
        try {
            setConnectToBackend(true);
            const _addressToUpdate = {
                companyId,
                alias : aliasAddr,
                firstName : firstNameAddr,
                lastName : lastNameAddr,
                phoneNumber: phoneNumberAddr,
                countryCode,
                street,
                city,
                postcode,
                isDefaultAddress,
                isPrivateAddress: _isCompanyAddress ? false : isPrivateAddress
            };

            if (_isCompanyAddress) {
                await updateCompanyAddress(auth.customerId, companyId, _address.id, _addressToUpdate);
                setCompanyAddresses(await getCompanyAddresses(auth.customerId, companyId))
            }
            else {
                await updateCustomerAddress(auth.customerId, _address.id, _addressToUpdate);
                setCustomerAddress(await getCustomerAddresses(auth.customerId));
            }
        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setShowFormAddress(false);
            setConnectToBackend(false);
        }
    }

    /**
     * Handles address deletion request then fetches address again.
     * @param _addressId
     */
    const handleDeleteAddress = async (_addressId) => {
        try {
            setConnectToBackend(true);
            await deleteCustomerAddress(_addressId);
            setCustomerAddress(await getCustomerAddresses(auth.customerId));
        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setIsDeletingPopup(false);
            setConnectToBackend(false);
        }
    }



    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            <Box className="user-content-field-wrapper">

                {/*TITLE*/}
                {!showFormAddress ?
                    <Typography variant={"h3"}>
                        {t("account.addresses.title")}
                    </Typography>
                    :
                    <Typography variant={"h3"}>
                        Address edition
                    </Typography>
                }



                {/*ADRESSES*/}
                {
                    showFormAddress ?
                        <UserAddressEdit
                            address={isCompanyAddress ? getCompanyAddress(addressId): getAddress(addressId)}
                            handleUpdateAddress={handleUpdateAddress}
                            isAddAddress={isAddAddress}
                            isCompanyAddress={isCompanyAddress}
                            handleAddAddress={handleAddAddress}
                            handleAddData={handleAddData}

                            // company={companyAddr}
                            alias={aliasAddr}
                            firstName={firstNameAddr}
                            lastName={lastNameAddr}
                            phoneNumber={phoneNumberAddr}
                            postcode={postcode}
                            street={street}
                            city={city}
                            countryCode={countryCode}
                            countriesList={countriesList}
                            isDefaultAddress={isDefaultAddress}
                            isPrivateAddress={isPrivateAddress}

                            // setCompany={setCompanyAddr}
                            setAlias={setAliasAddr}
                            setFirstName={setFirstNameAddr}
                            setLastName={setLastNameAddr}
                            setPhoneNumber={setPhoneNumberAddr}
                            setPostcode={setPostcode}
                            setStreet={setStreet}
                            setCity={setCity}
                            setCountryCode={setCountryCode}
                            setIsDefaultAddress={setIsDefaultAddress}
                            setIsPrivateAddress={setIsPrivateAddress}
                            setShowFormAddress={setShowFormAddress}
                        />
                        :
                        <>
                            {/*USER ADDRESSES*/}
                            <Box className="user-addresses-wrapper">
                                {
                                    customerAddress.map(
                                        (address) => <UserAddressList
                                            key={address.id}
                                            address={address}
                                            handleModifyAddress={handleModifyAddress}
                                            setIsDeletingPopup={setIsDeletingPopup}
                                            countriesList={countriesList}
                                            isCompanyAddress={false}
                                        />
                                    )
                                }

                                {/*CTA ADD ADDRESS*/}
                                {customerAddress.length <= 5 ?
                                    <Box className="user-address-card add-address-cta" onClick={handleAddAddress}>
                                        +
                                        <span>Add an address</span>
                                    </Box>
                                    : null
                                }
                            </Box>

                            {/*COMPANY ADDRESSES*/}
                            {isVerified &&
                                <>
                                    <Typography variant={"h3"} sx={{marginTop: "30px"}}>
                                        {t("account.addresses.secondTitle")}
                                    </Typography>

                                    <Box className="user-addresses-wrapper">
                                        {
                                            companyAddresses.map(
                                                (companyAddress) => <UserAddressList
                                                    key={companyAddress.id}
                                                    address={companyAddress}
                                                    handleModifyAddress={handleModifyAddress}
                                                    setIsDeletingPopup={setIsDeletingPopup}
                                                    countriesList={countriesList}
                                                    isCompanyAddress={true}
                                                />
                                            )
                                        }
                                    </Box>
                                </>
                            }

                            {/*DELETE POPUP*/}
                            {isDeletingPopup &&
                                <>
                                    <Dialog open={isDeletingPopup} onClose={ () => setIsDeletingPopup(false)}>
                                        <DialogTitle>
                                            {t("popup.deleteAddress.title")}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {t("popup.deleteAddress.content")}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <ButtonIcapeOutlinedRed onClick={ () => handleDeleteAddress(isDeletingPopup)}>
                                                {t("cta.delete")}
                                            </ButtonIcapeOutlinedRed>
                                            <ButtonIcapeSoftBlue onClick={ () => setIsDeletingPopup(0)}>
                                                {t("cta.cancel")}
                                            </ButtonIcapeSoftBlue>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            }
                        </>

                }
            </Box>
        </>
    )
}

export default UserAddresses;
