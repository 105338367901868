import React from "react";

function LogoSymbol (props) {

    return (
        <>
            <a href="https://www.icape-group.com/">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 200 200"
                     height={props.height}
                     fill= {props.fillColor}
                >
                    <path d="m61,92.6c-17.84,0-32.36-14.52-32.36-32.36s14.52-32.36,32.36-32.36c13.04,0,24.29,7.75,29.41,18.89h28.63C112.92,20.38,89.22.66,61,.66,28.15.66,1.43,27.39,1.43,60.24s26.73,59.58,59.58,59.58c28.22,0,51.92-19.72,58.04-46.11h-28.63c-5.12,11.13-16.37,18.89-29.41,18.89Z"/>
                    <circle cx="60.67" cy="60.24" r="14.85"/>
                </svg>
            </a>
        </>
    )
}

export default LogoSymbol;

