import React, {useContext, useEffect, useState} from "react";
import "./header.scss";
import {Box} from "@mui/material";
import {TopBar} from "./top-bar"
import {NavBar} from "./nav-bar";
import {AnnouncementBar} from "./announcement-bar";
import UserContext from "../../context/UserProvider";
import useAuth from "../../hooks/useAuth";
import {useLocation} from "react-router-dom";


function Header () {
    const {auth} = useAuth();
    const [languages, setLanguages] = useState([]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const actualRoute = useLocation();

    const { currenciesAllowed, isVerified } = useContext(UserContext);

    // FETCH LANGUAGES
    useEffect(() => {
        fetch('/jsons/languages.json')
            .then(response => response.json())
            .then(jsonData => {
                setLanguages(jsonData);
            })
            .catch(error => console.error(error));
    },[])

    // FETCH CURRENCIES
    useEffect(() => {
        fetch('/jsons/currencies.json')
            .then(response => response.json())
            .then(jsonData => {
                setCurrenciesList(jsonData);
            })
            .catch(error => console.error(error));
    },[])

    // DISPLAY ONLY ALLOWED CURRENCIES
    useEffect(() => {
        let curr = [];
        currenciesList.map((currency) => {
            for (let i = 0; i < currenciesAllowed.length; i++) {
                currency.id === currenciesAllowed[i] && curr.push(currency);
            }
            return currency
        });
        setCurrencies(curr);
    }, [currenciesAllowed])

    // FETCH MENU TOP BAR JSON
    const [menuTopBar, setMenuTopBar] = useState([]);
    useEffect(() => {
        fetch('/jsons/menu-top-bar.json')
            .then(response => response.json())
            .then(jsonData => {
                setMenuTopBar(jsonData)
            })
            .catch(error => console.error(error));
    },[])

    return (
        <>
            <Box className={"header"}>
                <TopBar menuTopBar={menuTopBar} languages={languages} currencies={currenciesAllowed}/>
                <NavBar menuTopBar={menuTopBar} languages={languages} currencies={currenciesAllowed}/>
                {
                    (isVerified || auth.username === undefined ) &&
                    (actualRoute.pathname === "/" || actualRoute.pathname === "/login") &&
                    <AnnouncementBar isEnabled={false} auth={auth} isVerified = {isVerified} />
                }
            </Box>
        </>
    )
}

export default Header;
