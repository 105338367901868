import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Link, useNavigate} from "react-router-dom";
import {ButtonIcape, ButtonIcapeOutlined} from "../../button/button-icape";
import {useTranslation} from "react-i18next";

function TechnologyCard(props){

    const { t } = useTranslation();
    const navigate = useNavigate();
    let calculatorLink = "";

    if(props.active){
        calculatorLink = props.link;
    }

    const handleCalculatorRedirection = () => {
        window.scrollTo(0, 0);

        // GTM EVENT : clic_cta_deviseur
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'clic_cta_deviseur',
            product_type: props.link
        });

        navigate(calculatorLink, {replace: false})
    }

    return (
    <Card className="card">
        <CardMedia component="img" height="150" image={props.image} alt={props.name} />
        <CardContent className="card-content">
            <Typography gutterBottom variant="h5" component="div">
                {t(props.name)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {t(props.description)}
            </Typography>
        </CardContent>
        <CardActions className="card-action">
            {props.repeat ?
                <Link to={`${calculatorLink}?mode=repeat-order`}>
                    <ButtonIcapeOutlined size="small" disabled={!props.active}>
                        {t('cta.repeatOrder')}
                    </ButtonIcapeOutlined>
                </Link>
            : ""}
            <ButtonIcape onClick={handleCalculatorRedirection} disabled={!props.active}>
                {t('cta.makeAQuote')}
            </ButtonIcape>
        </CardActions>
    </Card>
    )
}

export default TechnologyCard;
