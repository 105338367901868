import "./ui/icape.scss";
import React, {useContext, useEffect, useMemo} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import CalculatorRigid from "./ui/calculators/rigid/calculator-rigid";
import CalculatorRigidFlex from "./ui/calculators/rigidFlex/calculator-rigidFlex";
import Home from "./ui/home/home";
import LoginWrapper from "./ui/login/login-wrapper";
import {Box, createTheme, ThemeProvider, useTheme} from "@mui/material";
import Header from "./ui/header/header";
import Quickhelper from "./ui/quickhelper/quickhelper";
import Footer from "./ui/footer/footer";
import RequireAuth from "./components/RequireAuth";
import ShoppingCart from "./ui/shopping-cart/shopping-cart";
import Checkout from "./ui/checkout/checkout";
import PaymentRefused from "./ui/checkout/payment-refused";
import UserAccount from "./ui/user-account/user-account";
import CalculatorAluminum from "./ui/calculators/aluminum/calculator-aluminum";
import CalculatorFlex from "./ui/calculators/flex/calculator-flex";
import CalculatorHdi from "./ui/calculators/hdi/calculator-hdi";
import CalculatorLabServices from "./ui/calculators/labServices/calculator-labServices";
import Changelog from "./ui/changelog/changelog";
import icape from './ui/icape-da.scss';
import * as locales from '@mui/material/locale';
import UserContext from "./context/UserProvider";
import MissingRoute from "./ui/missing-route/missing-route";
import TagManager from "react-gtm-module/dist/TagManager";
import useAuth from "./hooks/useAuth";
import {object} from "prop-types";
import {handleDataLayerLoadEvent} from "./tools/google-tag-manager-events";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactGA from "react-ga4";
import VideoUpdates from "./ui/video-updates/video-updates";

// GTM INITIALIZATION
const tagManagerArgs = {
    gtmId: 'GTM-P34DL77',

}
TagManager.initialize(tagManagerArgs)

function App() {
    const theme = useTheme();
    theme.components = {
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    width: '300px',
                    marginTop: 0,
                    marginBottom: '0',
                    /*backgroundColor: 'white'*/
                },
            }
        },
        MuiSelect: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    backgroundColor: icape.white,
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {}
            }
        },
        MuiToggleButton: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    height: '40px',
                    border: 'solid 1px #BAE19F',
                    borderRadius: '10px',
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    color: icape.black,
                    display: 'flex',
                    justifyContent: 'space-between',
                    '&.Mui-selected': {
                        color: icape.white,
                        border: `solid 1px ${icape.green}`,
                        backgroundColor: icape.green,
                    },
                    '&:hover': {
                        color: icape.white,
                        backgroundColor: icape.softGreen,
                    },
                    '&.Mui-selected:hover': {
                        color: icape.white,
                        border: `solid 1px ${icape.softGreen}`,
                        backgroundColor: icape.softGreen,
                    },
                    '&.Mui-selected:after': {
                        content: '"→"',
                    }
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Montserrat',
                    /*textTransform: 'capitalize',*/
                },
                h1: {
                    fontSize: '28px',
                    fontWeight: '700'
                },
                h2: {
                    fontSize: '21px',
                    fontWeight: '600'
                },
                h3: {
                    fontSize: '18px',
                    fontWeight: '600'
                },
                h4: {
                    fontSize: '16px',
                    fontWeight: '600'
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    flexDirection: "row",
                    justifyContent: "center",
                    position: "static",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
                colorPrimary: {
                    color: icape.black,
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: icape.blueIcape,
                    },
                    '&.Mui-completed': {
                        color: icape.green,
                    }
                },
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px',
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '0 20px 20px 20px',
                    justifyContent: 'center',
                    gap: '30px',
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: icape.softBlue,
                    padding: '30px 20px 20px 20px',
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px',
                },
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: icape.black,
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '30px 20px 20px 20px',
                    gap: '30px',

                    '& :not(:first-of-type)': {
                        margin: '0',
                    }
                }
            }
        },
    }

    const {locale, activeLanguage} = useContext(UserContext);
    const {auth} = useAuth()
    const themeWithLocale = useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme]
    )
    const location = useLocation();

    // SCROLL TOP ON EACH ROUTE CHANGE
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [useLocation()]);

    const technologies = [
        {
            "id": 1,
            "name": "calculators.rigid.name",
            "image": "/assets/calculators/rigid.jpg",
            "description": "calculators.rigid.description",
            "link": "rigid",
            "active": true,
            "repeat": false
        },
        {
            "id": 2,
            "name": "calculators.hdi.name",
            "image": "/assets/calculators/hdi.jpg",
            "description": "calculators.hdi.description",
            "link": "hdi",
            "active": true,
            "repeat": false
        },
        {
            "id": 3,
            "name": "calculators.flex.name",
            "image": "/assets/calculators/flex.jpg",
            "description": "calculators.flex.description",
            "link": "flex",
            "active": true,
            "repeat": false
        },
        {
            "id": 4,
            "name": "calculators.aluminum.name",
            "image": "/assets/calculators/alu.jpg",
            "description": "calculators.aluminum.description",
            "link": "aluminum",
            "active": true,
            "repeat": false
        },
        {
            "id": 5,
            "name": "calculators.rigidFlex.name",
            "image": "/assets/calculators/rigid-flex.jpg",
            "description": "calculators.rigidFlex.description",
            "link": "rigid-flex",
            "active": false,
            "repeat": false
        },
        {
            "id": 6,
            "name": "calculators.laboratory.name",
            "image": "/assets/calculators/lab.jpg",
            "description": "calculators.laboratory.description",
            "link": "laboratory-services",
            "active": false,
            "repeat": false
        },
    ]

    /**
     * GTM EVENT : dataLayerLoad
     */
    useEffect(() => {
        if (auth === undefined){
            return
        }

        let userType
        if (Object.keys(auth).length === 0) {
            userType = 'non connected'
        } else {
            userType = 'connected'
        }

        handleDataLayerLoadEvent(activeLanguage, userType);
    }, [auth]);

    /**
     * GOOGLE ANALYTICS INITIALIZATION
     */
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            return;
        }

        ReactGA.initialize('G-50YE5PHJEK'); // Remplacez par votre ID de suivi GA4
        ReactGA.send('pageview');
    }, []);

    /**
     * DOCUMENT TITLE UPDATE UPON NAVIGATION
     */
    useEffect(() => {
        if (location.pathname !== "/"){
            document.title = `Eshop - ${formatPathname(location.pathname)}`;
        } else {
            document.title = "ICAPE Group - Eshop"
        }
    }, [location]);

    const formatPathname = (pathname) => {
        // Étape 1: Extraire la partie après le dernier slash
        let formattedPath = pathname.substring(pathname.lastIndexOf('/') + 1);

        // Étape 2: Remplacer les tirets par des espaces
        formattedPath = formattedPath.replace(/-/g, ' ');

        // Étape 3: Mettre une majuscule à la première lettre
        formattedPath = formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1);

        return formattedPath;
    };

    return (
        <ThemeProvider theme={themeWithLocale}>
            <Header/>
            <Quickhelper/>
            <TawkMessengerReact
                propertyId="5ab38d72d7591465c708ce00"
                widgetId="default"/>
            <Box id="routes-to-capture">
                <Routes>

                    {/* No protected routes */}
                    <Route path="/login" element={<LoginWrapper view={'login'}/>}/>
                    <Route path="/register" element={<LoginWrapper view={'register'}/>}/>
                    <Route path="/reset-password/:token" element={<LoginWrapper view={'reset-password'}/>}/>
                    <Route path="/" element={<Home technologies={technologies}/>}/>

                    {/* Protected routes */}
                    <Route element={<RequireAuth/>}>
                        {/* CALCULATORS */}
                        <Route name="rigid" path="/rigid" element= {
                            technologies[0].active ? <CalculatorRigid/> : <MissingRoute pageDisabledName="Rigid quotation tool"/> }/>
                        <Route name="aluminum" path="/aluminum" element= {
                            technologies[1].active ? <CalculatorAluminum/> : <MissingRoute pageDisabledName="Aluminum quotation tool"/> }/>
                        <Route name="flex" path="/flex" element= {
                            technologies[2].active ? <CalculatorFlex/> : <MissingRoute pageDisabledName="Flex quotation tool"/> }/>
                        <Route name="hdi" path="/hdi" element= {
                            technologies[3].active ? <CalculatorHdi/> : <MissingRoute pageDisabledName="HDI quotation tool"/> }/>
                        <Route name="rigidFlex" path="/rigid-flex" element= {
                            technologies[4].active ? <CalculatorRigidFlex/> : <MissingRoute pageDisabledName="Rigid flex quotation tool"/> }/>
                        <Route name="labServices" path="/laboratory-services" element = {
                            technologies[5].active ? <CalculatorLabServices/> : <MissingRoute pageDisabledName="Laboratory services"/> }/>

                        {/* USER */}
                        <Route path="/user" element={<UserAccount/>}/>
                        <Route path="/user/addresses" element={<UserAccount/>}/>
                        <Route path="/user/quotes" element={<UserAccount/>}/>
                        <Route path="/user/orders" element={<UserAccount/>}/>

                        {/* PAYMENT */}
                        <Route path="/cart" element={<ShoppingCart/>}/>
                        <Route path="/checkout" element={<Checkout/>}/>
                        <Route path="/payment-refused" element={<PaymentRefused/>}/>

                        {/* OTHERS */}
                        {/*<Route name="changelog" path="/changelog" element={<Changelog/>}/>*/}
                        <Route name="video-updates" path="/video-updates" element={<VideoUpdates/>}/>
                    </Route>

                    {/* Missing routes */}
                    <Route path="*" element={<MissingRoute/>}/>

                </Routes>
            </Box>
            <Footer/>
        </ThemeProvider>
    );
}

export default App;
