import React, {useContext, useEffect, useState} from "react";
import {Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText,} from "@mui/material";
import {ButtonIcapeSoftBlue} from "../button/button-icape";
import Logo from "../logo/logo";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {ExpandLess, ExpandMore, Logout} from "@mui/icons-material";
import {motion} from "framer-motion"
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import UserContext from "../../context/UserProvider";
import {logInConsole} from "../../tools/debug";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import useAuth from "../../hooks/useAuth";
import AuthService from "../../services/auth.service";

export const NavBar = (props) =>{
    const {auth} = useAuth();
    const navigate = useNavigate();
    const {firstName, lastName} = useContext(UserContext);
    const [tabActive, setTabActive] = useState("");

    // NAV CONTENT --------------------------------------------------------------------------

    // FETCH MENU JSON + config
    const [menuIcape, setMenuIcape] = useState([]);
    useEffect(() => {
        fetch('/jsons/menu.json')
            .then(response => response.json())
            .then(jsonData => {
                setMenuIcape(jsonData)
            })
            .catch(error => console.error(error));
    },[])

    // DESKTOP --------------------------------------------------------------------------
    const [tabActiveDesktop, setTabActiveDesktop] = useState("");
    const [isDesktopDrawerOpen, setIsDesktopDrawerOpen] = useState(false);

    const show = {
        y: 0,
    };
    const hide = {
        y: -10,
    };

    const handleDesktopDrawer = async (tab) => {
        if (tab) {
            setTabActiveDesktop(tab)
            setIsDesktopDrawerOpen(true)
        } else {
            setIsDesktopDrawerOpen(false)
            setTabActiveDesktop("")
        }
    };


    // MOBILE --------------------------------------------------------------------------
    const [mobileOpen, setMobileOpen] = useState(false);
    const menuTopBar = props.menuTopBar;


    // USER MENU
    const [isUserMenuMobileOpen, setIsUserMenuMobileOpen] = useState(false);
    const handleUserMobileMenu = (route) => {
        route();
        handleDrawerToggle();
    }
    const logout = () => {
        AuthService.logout();
        navigate('/', { replace: true});
        // window.location.reload();
    }
    const login = () => {
        navigate('/login', { replace: false});
    }
    const myAccount = () => {
        navigate('/user', { replace: false});
    }
    const changelog = () => {
        navigate('/changelog', { replace: false});
    }

    const {t, i18n} = useTranslation();
    const languages = props.languages
    const {activeLanguage, setActiveLanguage} = useContext(UserContext);
    const [isLanguageSwitcherMobileOpen, setIsLanguageSwitcherMobileOpen] = useState(false);

    const currenciesAllowed = props.currencies
    const {currencyCode, setCurrencyCode} = useContext(UserContext);
    const {activeCurrency, setActiveCurrency} = useContext(UserContext);
    const [isCurrencySwitcherMobileOpen, setIsCurrencySwitcherMobileOpen] = useState(false);

    const handleLanguageMobileSwitcher = (newLanguage, newLocale) => {
        setActiveLanguage(newLanguage);
        i18n.changeLanguage(newLocale);
        setIsLanguageSwitcherMobileOpen(false);
    }
    const handleCurrencyMobileSwitcher = (newCurrency) => {
        setCurrencyCode(newCurrency.code);
        setActiveCurrency(newCurrency.symbol);
        setIsCurrencySwitcherMobileOpen(false);
        logInConsole(`currencyCode : ${newCurrency.code}`, 'blue');
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const handleNested = (itemName) => {
        if (tabActive === itemName) {
            setTabActive("");
        }else {
            setTabActive(itemName);
        }
    };

    const drawer = (
            <Box  className="mobile-menu">
                {/*LOGO ICAPE GROUP*/}
                {/*<Logo fillColor={"#007dc8"} height={"45"}/>*/}

                {/*ClOSE MENU ICON*/}
                <Box id="menu-close-icon" onClick={handleDrawerToggle}>
                    <ClearIcon fontSize="large"/>
                </Box>

                {/*LIST MENU ITEMS*/}
                <List>
                    {menuIcape?.map((item) => (
                        <React.Fragment  key={item.name}>
                            <ListItem disablePadding onClick={ () => handleNested(item.name)}>
                                <ListItemButton disableRipple className="menu-item-button">
                                    <ListItemText primary={t(item.name)} /*sx={tabActive === item.name ? {color: '#37B0F1'} : ''}*//>
                                    {tabActive === item.name ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            <Collapse in={tabActive === item.name} timeout="auto" unmountOnExit>
                                <List disablePadding>
                                    {item.child.map((subItem) => (
                                        subItem.child ?
                                            <React.Fragment key={subItem.name}>
                                                <ListItem disablePadding>
                                                    <ListItemText className="menu-subitem-multiple-child">
                                                        {t(subItem.name)}
                                                    </ListItemText>
                                                </ListItem>
                                                <List className="menu-subitem-button-multiple-child-list">
                                                    {subItem.child.map((subSubItems) => (
                                                        <React.Fragment key={subSubItems.name}>
                                                            <ListItem >
                                                                <a href={subSubItems.url} onClick={handleDrawerToggle}>
                                                                    {t(subSubItems.name)}
                                                                </a>
                                                            </ListItem>
                                                        </React.Fragment>
                                                    ))}
                                                </List>
                                            </React.Fragment>
                                            :
                                            <React.Fragment key={subItem.name}>
                                                <ListItem disablePadding>
                                                    <ListItemButton disableRipple className="menu-subitem-button"
                                                                    onClick={handleDrawerToggle}>
                                                        <ListItemText>
                                                            {t(subItem.name)}
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                            </React.Fragment>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>

                {/*ESHOP CTA*/}
                <Link to={"/"} onClick={handleDrawerToggle}>
                    <ButtonIcapeSoftBlue sx={{ margin:'0 0 50px 0'}}>
                        {t("menu.eshop")}
                        <svg width="14"
                             height="12"
                             viewBox="0 0 14 12"
                             fill="inherit"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.16375 7.06216L0.0147495 2.18716C-0.0682049 1.83518 0.209244 1.5 0.583573 1.5H10.1304L10.3531 0.449766C10.4087 0.187992 10.6475 0 10.9246 0H13.4167C13.7388 0 14 0.251836 14 0.5625V0.9375C14 1.24816 13.7388 1.5 13.4167 1.5H11.7181L10.0107 9.54926C10.4192 9.77578 10.6944 10.2005 10.6944 10.6875C10.6944 11.4124 10.0851 12 9.33333 12C8.58161 12 7.97222 11.4124 7.97222 10.6875C7.97222 10.3201 8.12892 9.98824 8.38114 9.75H3.28555C3.53774 9.98824 3.69444 10.3201 3.69444 10.6875C3.69444 11.4124 3.08505 12 2.33333 12C1.58161 12 0.972219 11.4124 0.972219 10.6875C0.972219 10.1678 1.28547 9.71878 1.73976 9.50613L1.60567 8.93716C1.52272 8.58518 1.80016 8.25 2.17449 8.25H8.69854L8.85762 7.5H1.73257C1.4602 7.5 1.2241 7.31827 1.16375 7.06216Z"/>
                        </svg>
                    </ButtonIcapeSoftBlue>
                </Link>

                <Divider />

                {/*TOP BAR MENU*/}
                <Box className="mobile-menu-top-bar">
                    <a href={menuTopBar[0]?.url} target={menuTopBar[0]?.newTab ? "_blank" : "_self"}
                       onClick={handleDrawerToggle}>
                        {t(menuTopBar[0]?.name)}
                    </a>
                    <a href={menuTopBar[1]?.url} target={menuTopBar[1]?.newTab ? "_blank" : "_self"}
                        onClick={handleDrawerToggle}>
                        {t(menuTopBar[1]?.name)}
                    </a>
                    <a href={menuTopBar[2]?.url} target={menuTopBar[2]?.newTab ? "_blank" : "_self"}
                        onClick={handleDrawerToggle}>
                        {t(menuTopBar[2]?.name)}
                    </a>
                    <a href={menuTopBar[3]?.url} target={menuTopBar[3]?.newTab ? "_blank" : "_self"}
                       onClick={handleDrawerToggle}>
                        {t(menuTopBar[3]?.name)}
                    </a>


                    {/*USER MENU*/}
                    <Box className="secondary-dropdown-container-mobile"
                         onClick={auth.username === undefined ? () => handleUserMobileMenu(login) : () => setIsUserMenuMobileOpen(!isUserMenuMobileOpen)}>
                        <Box component={"span"}
                             className={isUserMenuMobileOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                            <svg width="18"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0C6.41775 0 4.87104 0.469191 3.55545 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59967 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 4.525C8.32535 4.525 8.6434 4.62148 8.91392 4.80223C9.18444 4.98299 9.39528 5.2399 9.51979 5.54049C9.64429 5.84107 9.67687 6.17182 9.6134 6.49092C9.54993 6.81002 9.39326 7.10313 9.1632 7.33319C8.93314 7.56325 8.64003 7.71992 8.32093 7.78339C8.00183 7.84686 7.67108 7.81429 7.3705 7.68978C7.06991 7.56528 6.81299 7.35443 6.63224 7.08391C6.45148 6.81339 6.35501 6.49535 6.35501 6.17C6.35488 5.95394 6.39733 5.73997 6.47995 5.54033C6.56258 5.34069 6.68374 5.15929 6.83652 5.00652C6.9893 4.85374 7.1707 4.73257 7.37034 4.64995C7.56997 4.56733 7.78394 4.52487 8 4.525ZM10.864 11.475H5.14V10.237C5.13974 10.0234 5.18162 9.81184 5.26324 9.61445C5.34486 9.41705 5.46463 9.2377 5.61567 9.08666C5.76671 8.93562 5.94606 8.81586 6.14345 8.73424C6.34085 8.65261 6.5524 8.61074 6.766 8.611H9.24001C9.67074 8.61206 10.0835 8.78377 10.3879 9.08853C10.6923 9.3933 10.8635 9.80627 10.864 10.237V11.475Z" fill="#299FDE"/>
                            </svg>
                            {/*TODO REPLACE BY USER FIRSTNAME */}
                            {auth.username === undefined ? t("common.connect") : `${firstName} ${lastName}`}
                        </Box>

                        {auth.username !== undefined &&
                            <svg width="9"
                                 viewBox="0 0 8 6"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L0 0L8 0L4 6Z"/>
                            </svg>
                        }

                        {isUserMenuMobileOpen === true ?
                            <Box className="dropdown-items">
                                <Box className="user-menu-items-mobile">
                                    <svg width="18"
                                         viewBox="0 0 16 16"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 0C6.41775 0 4.87104 0.469191 3.55545 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59967 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 4.525C8.32535 4.525 8.6434 4.62148 8.91392 4.80223C9.18444 4.98299 9.39528 5.2399 9.51979 5.54049C9.64429 5.84107 9.67687 6.17182 9.6134 6.49092C9.54993 6.81002 9.39326 7.10313 9.1632 7.33319C8.93314 7.56325 8.64003 7.71992 8.32093 7.78339C8.00183 7.84686 7.67108 7.81429 7.3705 7.68978C7.06991 7.56528 6.81299 7.35443 6.63224 7.08391C6.45148 6.81339 6.35501 6.49535 6.35501 6.17C6.35488 5.95394 6.39733 5.73997 6.47995 5.54033C6.56258 5.34069 6.68374 5.15929 6.83652 5.00652C6.9893 4.85374 7.1707 4.73257 7.37034 4.64995C7.56997 4.56733 7.78394 4.52487 8 4.525ZM10.864 11.475H5.14V10.237C5.13974 10.0234 5.18162 9.81184 5.26324 9.61445C5.34486 9.41705 5.46463 9.2377 5.61567 9.08666C5.76671 8.93562 5.94606 8.81586 6.14345 8.73424C6.34085 8.65261 6.5524 8.61074 6.766 8.611H9.24001C9.67074 8.61206 10.0835 8.78377 10.3879 9.08853C10.6923 9.3933 10.8635 9.80627 10.864 10.237V11.475Z" fill="#299FDE"/>
                                    </svg>
                                    <Typography component={"span"} onClick={() => handleUserMobileMenu(myAccount)}>
                                        {t("common.myAccount")}
                                    </Typography>
                                </Box>
                                {/*TODO ONLY FOR ADMIN*/}
                                {/*<Box className="user-menu-items-mobile">*/}
                                {/*    <MoveUpIcon fontSize="small" />*/}
                                {/*    <Typography component={"span"} onClick={() => handleUserMobileMenu(changelog)}>*/}
                                {/*        {t("common.version")} {process.env.REACT_APP_VERSION}*/}
                                {/*    </Typography>*/}
                                {/*</Box>*/}
                                <Box className="user-menu-items-mobile">
                                    <Logout fontSize="small" />
                                    <Typography component={"span"} onClick={() => handleUserMobileMenu(logout)}>
                                        {t("common.logout")}
                                    </Typography>
                                </Box>
                            </Box>
                            : <></>
                        }
                    </Box>


                    {/*LANGUAGE SWITCHER*/}
                    <Box className="secondary-dropdown-container-mobile" onClick={() => setIsLanguageSwitcherMobileOpen(!isLanguageSwitcherMobileOpen)}>
                        <Box component={"span"}
                             className={isLanguageSwitcherMobileOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                            {activeLanguage}
                        </Box>

                        <svg width="9"
                             viewBox="0 0 8 6"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6L0 0L8 0L4 6Z"/>
                        </svg>

                        {isLanguageSwitcherMobileOpen === true ?
                            <Box className="dropdown-items">
                                {
                                    languages.map((item) => (
                                        <React.Fragment key={item.name}>
                                            {item.active ?
                                                <Typography component={"span"}
                                                            onClick={() => handleLanguageMobileSwitcher(item.shortcode, item.locale)}
                                                            className={activeLanguage === item.shortcode ? "disabled" : ""}
                                                >
                                                    {item.name}
                                                </Typography>
                                                : null
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </Box>
                            : <></>
                        }
                    </Box>

                    {/*CURRENCY SWITCHER*/}
                    <Box className="secondary-dropdown-container-mobile" onClick={() => setIsCurrencySwitcherMobileOpen(!isCurrencySwitcherMobileOpen)}
                         onMouseLeave={() => setIsCurrencySwitcherMobileOpen(false)}>
                        <Box component={"span"}
                             className={isCurrencySwitcherMobileOpen ? "active-menu tools-with-icon" : "tools-with-icon"}>
                            {activeCurrency}
                        </Box>

                        <svg width="9"
                             viewBox="0 0 8 6"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6L0 0L8 0L4 6Z"/>
                        </svg>

                        {isCurrencySwitcherMobileOpen === true ?
                            <Box className="dropdown-items">
                                {
                                    currenciesAllowed.map((item) => (
                                        <React.Fragment key={item.code}>
                                            <Typography component={"span"}
                                                        onClick={() => handleCurrencyMobileSwitcher(item)}
                                                        className={currencyCode === item.code ? "disabled" : ""}
                                            >
                                                {item.code}&nbsp;{item.symbol}
                                            </Typography>
                                        </React.Fragment>
                                    ))
                                }
                            </Box>
                            : <></>
                        }
                    </Box>
                </Box>
            </Box>
        );

    // GLOBAL --------------------------------------------------------------------------
    return(
        <>
            {/*DESKTOP MENU*/}
            <Box className="nav-bar-wrapper">
                <Box className={"nav-bar"}>
                    {/*LOGO ICAPE GROUP*/}
                    <Box onMouseEnter={() => handleDesktopDrawer("")}>
                        <Logo fillColor={"#007dc8"} height={"48"}/>
                    </Box>

                    {/*MAIN ITEMS*/}
                    <Box className={"nav-bar-menu"}>
                        {menuIcape.map((item) => (
                            <React.Fragment key={item.name} >
                                <Box className="nav-bar-menu-item"
                                     onMouseEnter={() => handleDesktopDrawer(item.name)}
                                     onMouseLeave={() => handleDesktopDrawer("")}
                                >
                                    <Typography component={"span"}>
                                        {t(item.name)}
                                        <ExpandMoreIcon fontSize="small"/>
                                    </Typography>


                                    {/*MULTIPLE CHILDREN*/}
                                    <motion.div animate={isDesktopDrawerOpen ? show : hide}
                                                transition={{type: "linear"}}
                                                className="dropdown-menu-section-multiple-wrapper"
                                    >
                                        {item.name === tabActiveDesktop && item.multipleChildren?
                                            <Box className="dropdown-menu dropdown-menu-section-multiple">
                                                {item.child.map((subItems) => (
                                                    <Box key={subItems.name} className="section-multiple-links">

                                                        {/*CHILD NAME*/}
                                                        <Typography component={"span"}>
                                                            {t(subItems.name)}
                                                        </Typography>

                                                        {/*LINKS LIST*/}
                                                        {Object.keys(subItems.child).length < 7 ?
                                                            <Box className="one-column">
                                                                {subItems.child.map((subSubItems) => (
                                                                    <a key={subSubItems.name} href={subSubItems.url}>
                                                                        {t(subSubItems.name)}
                                                                    </a>
                                                                ))}
                                                            </Box>
                                                            :
                                                            <Box className={subItems.name === "menu.products.pcb.name" ? "two-column small-two-column" : "two-column"}>
                                                                {subItems.child.map((subSubItems) => (
                                                                    <a key={subSubItems.name} href={subSubItems.url}>
                                                                        {t(subSubItems.name)}
                                                                    </a>
                                                                ))}
                                                            </Box>
                                                        }

                                                    </Box>
                                                ))}
                                            </Box>
                                            :
                                            null
                                        }
                                    </motion.div>

                                    {/*SIMPLE CHILD*/}
                                    <motion.div animate={isDesktopDrawerOpen ? show : hide}
                                                transition={{type: "linear"}}
                                    >
                                        {item.name === tabActiveDesktop && !item.multipleChildren?
                                            <Box key={item.name} className="dropdown-menu dropdown-menu-section-solo">

                                                {/*LINKS LIST*/}
                                                {item.child.map((subItems) => (
                                                    <a key={subItems.name} href={subItems.url}>
                                                        {t(subItems.name)}
                                                    </a>
                                                ))}
                                            </Box>
                                            :
                                            null
                                        }
                                    </motion.div>

                                </Box>
                            </React.Fragment>
                        ))}

                        {/*ESHOP CTA*/}
                        <ButtonIcapeSoftBlue
                            onClick={() => {
                                navigate('/');
                                window.scrollTo(0,0);
                            }}
                            onMouseEnter={() => handleDesktopDrawer("")}>
                            {t("menu.eshop")}
                            <svg width="14"
                                 height="12"
                                 viewBox="0 0 14 12"
                                 fill="inherit"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16375 7.06216L0.0147495 2.18716C-0.0682049 1.83518 0.209244 1.5 0.583573 1.5H10.1304L10.3531 0.449766C10.4087 0.187992 10.6475 0 10.9246 0H13.4167C13.7388 0 14 0.251836 14 0.5625V0.9375C14 1.24816 13.7388 1.5 13.4167 1.5H11.7181L10.0107 9.54926C10.4192 9.77578 10.6944 10.2005 10.6944 10.6875C10.6944 11.4124 10.0851 12 9.33333 12C8.58161 12 7.97222 11.4124 7.97222 10.6875C7.97222 10.3201 8.12892 9.98824 8.38114 9.75H3.28555C3.53774 9.98824 3.69444 10.3201 3.69444 10.6875C3.69444 11.4124 3.08505 12 2.33333 12C1.58161 12 0.972219 11.4124 0.972219 10.6875C0.972219 10.1678 1.28547 9.71878 1.73976 9.50613L1.60567 8.93716C1.52272 8.58518 1.80016 8.25 2.17449 8.25H8.69854L8.85762 7.5H1.73257C1.4602 7.5 1.2241 7.31827 1.16375 7.06216Z"/>
                            </svg>
                        </ButtonIcapeSoftBlue>
                    </Box>

                    {/*BURGER MENU TRIGGER*/}
                    <IconButton
                        color="inherit"
                        aria-label="open mobile drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        id="burger-menu-icon"
                        disableRipple
                    >
                        <MenuIcon fontSize="large"/>
                    </IconButton>
                </Box>
            </Box>

            {/*MOBILE MENU*/}
            <Box component="nav">
                <Drawer
                    className="mobile-menu-wrapper"
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    transitionDuration={300}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    )
}
