import {Box, setRef, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {ButtonIcape, ButtonIcapeGreen} from "../../../button/button-icape";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useTranslation} from "react-i18next";
import {logInConsole, logInConsoleObject} from "../../../../tools/debug";

const UserOrderDetails = (props) => {
    const {t} = useTranslation();
    const {
        priceOfferPdf,
        invoicePdf,
        gerberFile,
        workingGerberFile,
        specFiles,
        orderSelected,
        setIsSelected,
        referenceSelected,
        handleRepeatOrderInfos,
        handleOrderNowInfos
    } = props;

    const title = orderSelected?.number || referenceSelected?.pcbPartNumber;

    // CURRENCY
    const [currencySymbol, setCurrencySymbol] = useState(null);

    // ITEMS
    const [stencil, setStencil] = useState({});
    const [pcb, setPcb] = useState({})
    const total = ((orderSelected?.total) || (referenceSelected?.prices.fullPrice));


    // ADDRESSES
    const shippingAddressLastName = orderSelected?.shippingAddress.lastName;
    const shippingAddressFirstName = orderSelected?.shippingAddress.firstName;
    const shippingAddressStreet = orderSelected?.shippingAddress.street;
    const shippingAddressPostcode = orderSelected?.shippingAddress.postcode;
    const shippingAddressCity = orderSelected?.shippingAddress.city;
    const shippingAddressCountryCode = orderSelected?.shippingAddress.country;

    const billingAddressLastName = orderSelected?.billingAddress.lastName;
    const billingAddressFirstName = orderSelected?.billingAddress.firstName;
    const billingAddressStreet = orderSelected?.billingAddress.street;
    const billingAddressPostcode = orderSelected?.billingAddress.postcode;
    const billingAddressCity = orderSelected?.billingAddress.city;
    const billingAddressCountryCode = orderSelected?.billingAddress.country;


    // SHIPPING
    const state = orderSelected?.state;
    const shippingState = orderSelected?.shippingState;
    const trackingNumber = orderSelected?.trackingNumber;
    const paymentState = orderSelected?.paymentState;
    const deliveryDate = orderSelected?.estimatedDeliveryDate;
    const deliveryCountry = {
        code: referenceSelected?.deliveryData.deliveryCountryCode || orderSelected?.shippingAddress.countryCode,
        name: referenceSelected?.deliveryData.deliveryCountry || orderSelected?.shippingAddress.country
    };
    const estimatedDeliveryDate = referenceSelected?.deliveryData.estimatedDeliveryDate;
    const shippingMethod = orderSelected?.shippingMethod || referenceSelected?.deliveryData.shippingMethod;


    //OTHERS
    const [discount, setDiscount] = useState({
        // isOne: orderSelected?.promotionData?.name !== "",
        isOne: orderSelected?.discountedPrice < 0,
        product: orderSelected?.promotionData?.name || "-",
        price: orderSelected?.discountedPrice,
        percentage: orderSelected?.promotionData?.percentage,
        type: orderSelected?.promotionData ? "promotion" : "discount"
    })
    const [taxTotal, setTaxTotal] = useState(null)
    const isSpecificationFile = false

    // CHECK AND FILL THE PAGE
    useEffect(() => {
        if (orderSelected) {
            setCurrencySymbol(orderSelected.currency.symbol);
            setTaxTotal(orderSelected.taxTotal)
            setStencil({
                isOne: orderSelected?.isStencil,
                reference: orderSelected?.stencilPartNumber,
                type: "Stencil",
                technology: orderSelected?.stencilType,
                quantity: orderSelected?.stencilQuantity,
                price: orderSelected?.stencilUnitPrice,
                totalPrice: orderSelected?.stencilTotal
            });
            setPcb({
                reference: orderSelected?.pcbPartNumber,
                type: orderSelected?.pcbType,
                technology: orderSelected?.pcbTechnology,
                quantity: orderSelected?.pcbQuantity,
                unitPrice: orderSelected?.pcbUnitPrice,
                totalPrice: orderSelected?.pcbTotal
            });
        }
        if (referenceSelected) {
            setCurrencySymbol(referenceSelected.currency.symbol);
            setTaxTotal(referenceSelected.prices.tariff)
            setStencil({
                isOne: referenceSelected?.isStencil,
                reference: referenceSelected?.stencil?.stencilPartNumber,
                type: "Stencil",
                technology: referenceSelected?.stencil?.stencilTypeName,
                quantity: referenceSelected?.stencil?.stencilQuantity,
                price: referenceSelected?.prices?.stencilUnitPrice,
                totalPrice: referenceSelected?.prices?.stencilTotal
            });
            setPcb({
                reference: referenceSelected.pcbPartNumber,
                type: referenceSelected.pcbType,
                technology: referenceSelected.pcbTechnology,
                quantity: referenceSelected.pcbQuantity,
                unitPrice: referenceSelected.prices.pcbUnitPrice,
                totalPrice: referenceSelected.prices.pcbTotal,
            });
        }
    }, [orderSelected, referenceSelected]);

    /**
     * Allow user to download a document / zip
     * @param {"gerber"|"invoice"|"quotation"|"spec"|"workingGerber"} _document
     */
    const handleDownloadDocument = (_document) => {
        switch(_document){
            case "gerber":
                window.open(process.env.REACT_APP_BACKEND_BASE_URL + gerberFile)
                break;
            case "workingGerber":
                window.open(process.env.REACT_APP_BACKEND_BASE_URL + workingGerberFile)
                break;
            case "spec":
                for (let i = 0; i < specFiles.length; i++) {
                    window.open(process.env.REACT_APP_BACKEND_BASE_URL + specFiles[i])
                    logInConsoleObject(specFiles[i]);
                }
                break;
            case "quotation":
                window.open(process.env.REACT_APP_BACKEND_BASE_URL + '/' + priceOfferPdf)
                break;
            case "invoice":
                window.open(process.env.REACT_APP_BACKEND_BASE_URL + invoicePdf)
                break;
            default:
                logInConsole("Error : no downloading document found", "red");
                break;
        }
    }

    return (
        <>
            <Box className="user-order-detail-wrapper">

                {/*TITLE AND CLOSE ICON*/}
                <Box className="user-order-detail-title">
                    <Typography variant={"h3"}>
                        {orderSelected ? t("account.details.order") + " :" : t("account.details.partNumber") + " :"}&nbsp;
                        <span>{title}</span>
                        <br/>
                        {orderSelected &&
                            <>
                                {t("account.details.reference") + " :"}&nbsp;
                                <span>{orderSelected?.reference === null ? "-" : orderSelected?.reference}</span>
                            </>
                        }
                    </Typography>

                    <Box onClick={() => setIsSelected(false)}>
                        <Close/>
                    </Box>
                </Box>

                {/*ORDER / QUOTE TAB RESUME*/}
                <Box className="user-order-tab-resume-wrapper">

                    {/*REFERENCES*/}
                    <Box className="user-order-tab-resume-column column1">
                        <Typography variant={"h4"}>
                            {t("account.details.partNumber")}
                        </Typography>
                        <span>{pcb.reference}</span>
                        {stencil.isOne && <span>{stencil.reference}</span>}
                        {discount.isOne &&
                            <span>
                                {discount.type === "promotion" && t('common.promotion')}
                                {discount.type === "discount" && t('common.discount')}
                            </span>
                        }
                        {deliveryCountry.code === 'US' ?
                                <>
                                    <span>Tariff</span>
                                </>
                                :
                                <>
                                    {orderSelected && taxTotal >= 0 && <span>{t("common.tax")}</span>}
                                </>
                        }
                    </Box>

                    {/*PRODUCTS*/}
                    <Box className="user-order-tab-resume-column column2">
                        <Typography variant={"h4"}>
                            {t("common.product")}
                        </Typography>
                        <span>{pcb.type}</span>
                        {stencil.isOne && <span>{stencil.type}</span>}
                        {discount.isOne && <span>{discount.product}</span>}
                        {deliveryCountry.code === 'US' ?
                            <>
                                <span>-</span>
                            </>
                            :
                            <>
                                {orderSelected && taxTotal >= 0 && <span>-</span>}
                            </>
                        }
                    </Box>

                    {/*TECHNOLOGY*/}
                    <Box className="user-order-tab-resume-column column3">
                        <Typography variant={"h4"}>
                            {t("account.details.technology")}
                        </Typography>
                        <span>{pcb.technology}</span>
                        {stencil.isOne && <span>{stencil.technology}</span>}
                        {discount.isOne && <span>-</span>}
                        {deliveryCountry.code === 'US' ?
                            <>
                                <span>-</span>
                            </>
                            :
                            <>
                                {orderSelected && taxTotal >= 0 && <span>-</span>}
                            </>
                        }
                    </Box>

                    {/*QUANTITY*/}
                    <Box className="user-order-tab-resume-column column4">
                        <Typography variant={"h4"}>
                            {t("common.quantity")}
                        </Typography>
                        <span>{pcb.quantity}</span>
                        {stencil.isOne && <span>{stencil.quantity}</span>}
                        {discount.isOne && <span>1</span>}
                        {deliveryCountry.code === 'US' ?
                            <>
                                <span>-</span>
                            </>
                            :
                            <>
                                {orderSelected && taxTotal >= 0 && <span>-</span>}
                            </>
                        }
                    </Box>

                    {/*UNIT PRICE*/}
                    <Box className="user-order-tab-resume-column column5">
                        <Typography variant={"h4"}>
                            {t("account.details.unitPrice")}
                        </Typography>
                        <span>{pcb.unitPrice} {currencySymbol}</span>
                        {stencil.isOne && <span>{stencil.price} {currencySymbol}</span>}
                        {discount.isOne &&
                            <span>
                                {discount.type === "discount" || discount.percentage === null && `${discount.price} ${currencySymbol}`}
                                {discount.type === "promotion" && discount.percentage !== null && `${discount.percentage} %`}
                            </span>
                        }
                        {deliveryCountry.code === 'US' ?
                            <>
                                <span>-</span>
                            </>
                            :
                            <>
                                {orderSelected && taxTotal >= 0 && <span>-</span>}
                            </>
                        }
                    </Box>

                    {/*TOTAL PRICE*/}
                    <Box className="user-order-tab-resume-column column6">
                        <Typography variant={"h4"}>
                            {t("account.details.totalPrice")}
                        </Typography>
                        <span>{pcb.totalPrice} {currencySymbol}</span>
                        {stencil.isOne && <span>{stencil.totalPrice} {currencySymbol}</span>}
                        {discount.isOne && <span>{discount.price} {currencySymbol}</span>}
                        {deliveryCountry.code === 'US' ?
                                <>
                                    <span>{taxTotal} {currencySymbol}</span>
                                </>
                                :
                                <>
                                    {orderSelected && taxTotal >= 0 && <span>{taxTotal} {currencySymbol}</span>}
                                </>
                        }
                    </Box>

                </Box>

                {/*ORDER TOTAL PRICE*/}
                <Box className="user-order-tab-resume-total">
                    <span>{t("account.details.totalOrder")} &nbsp;</span>
                    <span>{total} {currencySymbol}</span>
                </Box>

                {/*ADDRESSES AND ORDER RESUME*/}
                <Box className="user-order-infos-wrapper">
                    {orderSelected ?
                        <>
                            {/*STATUS*/}
                            <Box className="user-order-status-wrapper">
                                <Box className="user-order-status">
                                    <Typography variant={"h4"}>
                                        {t("account.details.states")}
                                    </Typography>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>{t("account.details.order")} :</td>
                                            <td>{state}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.shipping")} :</td>
                                            <td>{shippingState}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.trackingNumber")} :</td>
                                            <td>
                                                {
                                                    trackingNumber !== null ?
                                                        <a href={`https://www.dhl.com/fr-en/home/tracking.html?locale=true&tracking-id=${trackingNumber}`}
                                                           target={"_blank"}>
                                                            {trackingNumber}
                                                        </a>
                                                        :
                                                        "-"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("common.payment")} :</td>
                                            <td>{paymentState}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Box>
                                <Box className="user-order-status">
                                    <Typography variant={"h4"}>
                                    {t("common.delivery")}
                                    </Typography>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>{t("account.details.deliveryDate")} :</td>
                                            <td>{deliveryDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("account.details.shippingMethod")} :</td>
                                            <td>{shippingMethod}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Box>
                            </Box>

                            {/*ADDRESSES*/}
                            <Box className="user-order-addresses-wrapper">
                                {/*DELIVERY ADDRESS*/}
                                <Box className="user-order-address">
                                    <Typography variant={"h4"}>
                                        {t("common.deliveryAddress")}
                                    </Typography>
                                    <span>{shippingAddressLastName}&nbsp; {shippingAddressFirstName}</span>
                                    <span>{shippingAddressStreet}</span>
                                    <span>{shippingAddressPostcode},&nbsp; {shippingAddressCity}</span>
                                    <span>{shippingAddressCountryCode}</span>
                                </Box>

                                {/*BILLING ADDRESS*/}
                                <Box className="user-order-address">
                                    <Typography variant={"h4"}>
                                        {t("common.billingAddress")}
                                    </Typography>
                                    <span>{billingAddressLastName}&nbsp; {billingAddressFirstName}</span>
                                    <span>{billingAddressStreet}</span>
                                    <span>{billingAddressPostcode},&nbsp; {billingAddressCity}</span>
                                    <span>{billingAddressCountryCode}</span>
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            {/*STATUS*/}
                            <Box className="user-order-status">
                                <Typography variant={"h4"}>
                                    {t("common.delivery")}
                                </Typography>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>{t("account.details.deliveryCountry")} :</td>
                                        <td>{deliveryCountry.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("account.details.estimatedDeliveryDate")} :</td>
                                        <td>{estimatedDeliveryDate}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("account.details.shippingMethod")} :</td>
                                        <td>{shippingMethod}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </>
                    }
                </Box>

                {/*PROJECT FILES*/}
                <Box className="user-order-project-files-wrapper">
                    <Box className="user-order-project-files-title">
                        <Typography variant={"h4"}>
                            {t("account.details.projectFiles")}
                        </Typography>
                        {/*TODO DRAG AND DROP POPUP*/}
                        {/*<Box title={t("account.details.dragNDropBoxTitle")}>*/}
                        {/*    <AddCircleOutlineIcon />*/}
                        {/*</Box>*/}
                    </Box>

                    <Box className="user-order-project-file">
                        <span>{t("account.details.quotation")}</span>
                        <Box>
                            {priceOfferPdf !== "noPriceOffer" ?
                                <span onClick={() => handleDownloadDocument("quotation")}>
                                    {t("cta.download")}
                                </span>
                                :
                                <span className="red">
                                    {t("account.details.noPdfAvailable")}
                                </span>
                            }
                        </Box>
                    </Box>

                    {orderSelected ?
                        <>
                            {/*INVOICE*/}
                            <Box className="user-order-project-file">
                                <span>{t("account.details.invoice")}</span>
                                <Box>
                                    {invoicePdf !== "noInvoice" ?
                                        <span onClick={() => handleDownloadDocument("invoice")}>
                                            {t("cta.download")}
                                        </span>
                                        :
                                        <span className="red">
                                            {t("account.details.notAvailableYet")}
                                        </span>
                                    }
                                </Box>
                            </Box>
                            {/*GERBER*/}
                            <Box className="user-order-project-file">
                                {/*TODO add trad */}
                                <span>Gerber file</span>
                                <Box>
                                    {gerberFile !== "noGerberFile" ?
                                        <span onClick={() => handleDownloadDocument("gerber")}>
                                            {t("cta.download")}
                                        </span>
                                        :
                                        <span className="red">
                                            {t("account.details.notAvailableYet")}
                                        </span>
                                    }
                                </Box>
                            </Box>
                            {/*WORKING GERBER*/}
                            <Box className="user-order-project-file">
                                {/*TODO add trad */}
                                <span>Working Gerber file</span>
                                <Box>
                                    {workingGerberFile !== "noWorkingGerberFile" ?
                                        <span onClick={() => handleDownloadDocument("workingGerber")}>
                                            {t("cta.download")}
                                        </span>
                                        :
                                        <span className="red">
                                            {t("account.details.notAvailableYet")}
                                        </span>
                                    }
                                </Box>
                            </Box>
                            {/*SPEC*/}
                            <Box className="user-order-project-file">
                                {/*TODO add trad */}
                                <span>Specification file</span>
                                <Box>
                                    {specFiles !== "noSpecsFile" ?
                                        <span onClick={() => handleDownloadDocument("spec")}>
                                            {t("cta.download")}
                                        </span>
                                        :
                                        <span className="red">
                                            {t("account.details.notAvailableYet")}
                                        </span>
                                    }
                                </Box>
                            </Box>
                            <Box className="user-content-cta">
                                <ButtonIcape
                                    onClick={() => handleRepeatOrderInfos(orderSelected.id)}>
                                    {t("cta.repeatOrder")}
                                </ButtonIcape>
                            </Box>
                        </>
                        :
                        <>
                            <Box className="user-content-cta">
                                <ButtonIcapeGreen
                                    onClick={() => handleOrderNowInfos(referenceSelected.id, true)}
                                    disabled={referenceSelected.isOrdered}
                                >
                                    {t("cta.orderNow")}
                                </ButtonIcapeGreen>
                            </Box>
                        </>

                    }
                </Box>

            </Box>
        </>
    )

}


export default UserOrderDetails;
