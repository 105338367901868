import ErrorMessage from "../../error-message";
import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {handleVirtualPageView} from "../../../../tools/google-tag-manager-events";

const Step1 = (props) => {
    const {t} = useTranslation();
    const {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        accountPhone, setAccountPhone,
        validAccountPhone, setValidAccountPhone,
        password, setPassword,
        passwordConfirmation, setPasswordConfirmation,
        showErrMsg, errMsg
    } = props.referencesStep1


    const [showPassword, setShowPassword] = useState(false);

    // PHONE FIELD CHECK
    const handleAccountPhoneNumberChange = (newPhoneNumber) => {
        setValidAccountPhone(!matchIsValidTel(newPhoneNumber));
        setAccountPhone(newPhoneNumber);
    }


    return (
        <>
            {/*TITLE*/}
            <Box className="register-title">
                <Typography variant={"h1"}>{t("common.registration")}</Typography>
                <Typography component={"span"}>{t("common.information")}</Typography>
            </Box>

            {/*ERROR MESSAGE*/}
            <Box className={showErrMsg ? "errorMessage" : ''}>
                {showErrMsg ? <ErrorMessage message={errMsg}/> : ''}
            </Box>

            {/*STEP 01*/}
            <Box className="register-fields-wrapper">
                <TextField
                    id="firstName"
                    label={t("common.firstName")}
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                    variant="outlined"
                    margin="normal"
                    required
                />
                <TextField
                    id="lastName"
                    label={t("common.lastName")}
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                    variant="outlined"
                    margin="normal"
                    required
                />
                {/*TODO do not pre-fill this field (maybe a MUI method for that ?)*/}
                <TextField
                    id="email"
                    label={t("common.email")}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    variant="outlined"
                    margin="normal"
                    required
                />
                <MuiTelInput
                    label={t("common.phone")}
                    value={accountPhone}
                    disabled={false}
                    defaultCountry="FR"
                    error={validAccountPhone}
                    onChange={handleAccountPhoneNumberChange}
                    required
                />

                <TextField
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label={t("common.password")}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    variant="outlined"
                    margin="normal"
                    required
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>  setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                />
                <TextField
                    id="password-confirmation"
                    type={showPassword ? 'text' : 'password'}
                    label={t("common.passwordConfirmation")}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    value={passwordConfirmation}
                    variant="outlined"
                    margin="normal"
                    required
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>  setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                />
            </Box>
        </>
    )
}
export default Step1;
