import React, {useContext, useEffect, useState} from "react";
import {Box, Tab, Tabs, TextField, ToggleButton, Typography} from "@mui/material";
import {useDropzone} from "react-dropzone";
import NaTablePCB from "./tables/na-table-pcb";
import NaTablePCBA from "./tables/na-table-pcba";
import ChinaTable from "./tables/china-table";
import PropTypes from "prop-types";
import CalculatorLabServicesContext from "../../../../context/calculatorLabServicesProvider";
import {useTranslation} from "react-i18next";

// ALL STEPS -> LabServices Component Params
function AllSteps() {
    const {t} = useTranslation();

    const{
        handleResetProjectPanel,
        file, setFile,
        isNA_lab, setIsNA_lab,
        value, setValue,
        description, setDescription
    } = useContext(CalculatorLabServicesContext);

    // TODO REFACTO FOR DROP GERBER
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    // TODO CHECK IF USEFUL (OR REFACTO FOR GERBER)
    useEffect(() => {
        // console.log(file)
        // Make sure to revoke the data uris to avoid memory leaks
        file.forEach(file => URL.revokeObjectURL(file.preview));
    }, [file]);

    // const [isOn, setIsOn] = useState(false);
    // const toggleDropzoneAnimation = () => setIsOn(!isOn);

    //CHINA LAB / NA LAB
    const [selectedChina, setSelectedChina] = useState(true);
    const [selectedNa, setSelectedNa] = useState(false);

    const handleToggleModeChinaLab = (e) => {
        setSelectedChina(true);
        setSelectedNa(false);
        setIsNA_lab(false);
        handleResetProjectPanel();
    }
    const handleToggleModeNaLab = (e) => {
        setSelectedChina(false);
        setSelectedNa(true);
        setIsNA_lab(true);
        handleResetProjectPanel();
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleResetProjectPanel();
    };

    return (
        <>
            {/*DRAG N DROP + SWITCHER  + COMMENT SECTION*/}
            <Box className="technical-data-card-column lab-services-menu">
                <Box className="lab-services-menu-inner">
                    {/*DRAG N DROP*/}
                    <Box className="technical-data-card drop-bloc">

                        <Box className={"drop-heading"}>
                            <Typography variant={'h3'}>{t("calculators.step1.gerber.gerberFile")}</Typography>
                        </Box>

                        <Box className="archive-bloc">

                                <Box {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <img src="/assets/lottie/dragndrop.gif" alt=""/>
                                    <Box component={"span"}>{t("calculators.step1.gerber.dragNDrop")}</Box>
                                </Box>
                        </Box>
                    </Box>

                    {/*SWITCHER*/}
                    <Box className="technical-data-card lab-services-menu-inner-switcher">
                        <Typography variant={'h3'}>{t("calculators.laboratory.labs.labSelection")}</Typography>
                        <Box sx={{display: 'flex', flexDirection: 'Column',gap:'5px'}}>
                            <ToggleButton
                                //muiToggleButton theme to have correct colors
                                value="check"
                                selected={selectedChina}
                                onChange={(e) => {
                                    handleToggleModeChinaLab(e);
                                }}
                            >
                                {t("calculators.laboratory.labs.chineseLab")}
                            </ToggleButton>
                            <ToggleButton
                                value="check"
                                selected={selectedNa}
                                onChange={(e) => {
                                    handleToggleModeNaLab(e);
                                }}
                            >
                                {t("calculators.laboratory.labs.usaLab")}
                            </ToggleButton>

                        </Box>
                    </Box>
                </Box>

                {/*COMMENT SECTION*/}
                <Box className="technical-data-card">
                    {/*TITLE*/}
                    <Typography variant={'h3'}>{t("calculators.comment.commentTitle")}</Typography>

                    {/*COMMENT SECTION*/}
                    <TextField
                        id="description"
                        label={t("calculators.comment.description")}
                        multiline
                        rows={7}
                        value={description}
                        placeholder={t("calculators.comment.placeholder")}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            width: '100%',
                        }}
                    />
                </Box>
            </Box>

            {/*CHINA LAB / NA LAB*/}
            <Box className="technical-data-card lab-services-table">
                {
                    selectedChina &&
                    <>
                        <Typography variant={'h3'}>{t("calculators.laboratory.labs.chineseLaboratory")}</Typography>
                        <ChinaTable/>
                    </>
                }
                {
                    selectedNa &&
                    <>
                        <Box className="lab-services-table-usa-menu">
                            <Tabs value={value}
                                  onChange={handleChange}
                                  aria-label="USA Lab menu"
                                  variant="scrollable"
                                  scrollButtons={window.innerWidth < 1200}
                                  allowScrollButtonsMobile>
                                <Tab disableRipple label={t("calculators.laboratory.labs.naLabPcb")} {...a11yProps(0)} />
                                <Tab disableRipple label={t("calculators.laboratory.labs.naLabPcba")} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} className="lab-services-table-inner">
                            <NaTablePCB/>
                        </TabPanel>
                        <TabPanel value={value} index={1} className="lab-services-table-inner">
                            <NaTablePCBA/>
                        </TabPanel>
                    </>
                }
            </Box>
        </>
    )
}
export default AllSteps;
