import React, {useState, useEffect} from "react";
import "../login/login.scss";
import {Box, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField} from "@mui/material";
import LockOpen from '@mui/icons-material/LockOpen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {axios, BASE_URL} from '../../../api/axios';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import ErrorMessage from "../error-message";
import Typography from "@mui/material/Typography";
import {ButtonIcape} from "../../button/button-icape";
import {useTranslation} from "react-i18next";
import {regex} from "../../../tools/regex";
import {logInConsole} from "../../../tools/debug";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import errorMessage from "../error-message";
import backendConnection from "../../backend-connection/backend-connection";

const ResetPassword = (props) => {
    const {t} = useTranslation();

    const { token } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {backendConnection, setBackendConnection} = props.backendConnection

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

    const initialPasswordError = {
        isError: false,
        isPasswordFieldError: false,
        isPasswordConfirmationFieldError: false,
        errorMessage: '',
    }
    const [passwordError, setPasswordError] = useState(initialPasswordError);


    /**
     * Update the view when url is used to navigate in the component
     */
    useEffect(() => {
        if (location.pathname === "/login") {
            props.handleView('login')
        }
        if (location.pathname === "/register") {
            props.handleView('register')
        }
    }, [location.pathname]);


    /**
     * Reset password errors and message after on type on either password field
     */
    const handleResetPasswordError = () => {
        if (passwordError.isError) {
            setPasswordError(initialPasswordError);
        }
    }


    /**
     * Check password field then send the reset password request to backend
     * @param e
     * @returns {Promise<void>}
     */
    const handleSubmit = async e => {
        e.preventDefault();

        // REGEX CHECKS
        if (!regex(newPassword, 'password')) {
            setPasswordError({
                ...passwordError,
                isError: true,
                isPasswordFieldError: true,
                errorMessage: t("error.invalidPassword")
            })
            return
        }
        if (!regex(newPasswordConfirmation, 'password')) {
            setPasswordError({
                ...passwordError,
                isError: true,
                isPasswordConfirmationFieldError: true,
                errorMessage: t("error.invalidPassword")
            })
            return
        }
        if (newPassword !== newPasswordConfirmation) {
            setPasswordError({
                ...passwordError,
                isError: true,
                isPasswordFieldError: true,
                isPasswordConfirmationFieldError: true,
                errorMessage: t("error.nonIdenticalPassword")
            })
            return
        }


        setBackendConnection({...backendConnection, isLoading: true});
        let isPatchError = false;
        await axios.patch(BASE_URL + '/shop/reset-password-requests/' + token,
            JSON.stringify({  "newPassword": newPassword, "confirmNewPassword": newPasswordConfirmation}),
            { headers: {'Content-Type': 'application/merge-patch+json'} })
            .then(
                (response) => {
                    if (response.status === 202) {
                        setIsPasswordUpdated(true);
                    }
                },
                (error) => {
                    isPatchError = true;
                }
            )
            .finally(() => {
                setBackendConnection({isError: isPatchError, isLoading: false});
            })
    }

    return (
        <section className="login-part">
            <Typography variant={"h1"}>
                {t("login.passwordRecovery")}
            </Typography>
            <Typography component={"span"}>
                {/*TODO TRANSLATE*/}
                Please enter your new password
            </Typography>

            <Box className={passwordError.isError && "errorMessage"} sx={passwordError.isError ? {margin: "30px 0 0 0"} : {}}>
                {passwordError ? <ErrorMessage message={passwordError.errorMessage} /> : ''}
            </Box>

            <Box className="form-wrapper">

                {
                    isPasswordUpdated ?
                        <>
                            {/*TODO Translate*/}
                            <p>Password updated with success</p>
                        </>
                        :
                        <>
                            <TextField
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                label={t("common.password")}
                                value={newPassword}
                                onChange={e => {
                                    handleResetPasswordError();
                                    setNewPassword(e.target.value);
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                onKeyDown={e=>(e.key === 'Enter' ? handleSubmit(e) : null)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                error={passwordError.isPasswordFieldError}
                            />

                            <TextField
                                id="password-confirmation"
                                type={showPassword ? 'text' : 'password'}
                                label={t("common.passwordConfirmation")}
                                value={newPasswordConfirmation}
                                onChange={e => {
                                    handleResetPasswordError();
                                    setNewPasswordConfirmation(e.target.value);
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                onKeyDown={e=>(e.key === 'Enter' ? handleSubmit(e) : null)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                error={passwordError.isPasswordConfirmationFieldError}
                            />
                        </>
                }

                <Box className="login-cta">
                    <ButtonIcape onClick={(e) => isPasswordUpdated ? navigate('/login', {replace: true}) : handleSubmit(e) }>
                        {
                            isPasswordUpdated ?
                                t("common.login")
                                :
                                t("cta.createPassword")
                        }
                    </ButtonIcape>
                </Box>
            </Box>
        </section>
    )
}

export default ResetPassword;
