import {Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography} from "@mui/material";
import {DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter} from "@mui/x-data-grid";
import useAuth from "../../../../hooks/useAuth";
import React, {useContext, useEffect, useState} from "react";
import UserOrderDetails from "./user-order-details";
import BackendConnection from "../../../backend-connection/backend-connection";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import {ButtonIcapeSoftBlue} from "../../../button/button-icape";
import CalculatorAluminumContext from "../../../../context/calculatorAluminumProvider";
import CalculatorFlexContext from "../../../../context/calculatorFlexProvider";
import CalculatorHdiContext from "../../../../context/calculatorHdiProvider";
import CalculatorRigidContext from "../../../../context/calculatorRigidProvider";
import {useNavigate} from "react-router-dom";
import {handleClickOrderEvent} from "../../../../tools/google-tag-manager-events";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import DoNotDisturbOnTwoToneIcon from "@mui/icons-material/DoNotDisturbOnTwoTone";
import {getOrderDetails, getOrdersByCompany, getRepeatOrderinfo} from "../../../../services/orders.services";

const UserOrders = (props) => {
    const {t} = useTranslation();
    const {auth} = useAuth();
    const [connectToBackend, setConnectToBackend] = useState(false);
    const {setErrorBackendConnection} = props;

    const { loadCustomerReferenceAluminum } = useContext(CalculatorAluminumContext);
    const { loadCustomerReferenceFlex } = useContext(CalculatorFlexContext);
    const { loadCustomerReferenceHdi } = useContext(CalculatorHdiContext);
    const { loadCustomerReferenceRigid } = useContext(CalculatorRigidContext);
    const navigate = useNavigate()

    const {dataGridLocale} = useContext(UserContext);
    const [dataGridHeight, setDataGridHeight] = useState(694);

    const [customerOrders, setCustomerOrders] = useState([]);

    /**
    * Test la répétabilité de la commande
    * Si la date de création est inférieur à 6 mois
    * */
    const isOrderRepeatable = (_targetDate) => {
        const targetDate = new Date(_targetDate);
        const currentDate = new Date();

        targetDate.setMonth(targetDate.getMonth() +6);

        return targetDate > currentDate;
    }

    const isStencilEnabled = true;
    const orderColumns = [
        {field: 'number', headerName: t("account.details.number"), flex: 1},
        {field: 'reference', headerName: t("account.details.reference"), flex: 2},
        {field: 'pcbPartNumber', headerName: t("account.details.partNumber"), flex: 2},
        {field: 'type&technology', headerName: t("common.product"), flex: 2},
        {field: 'createdAt', headerName: t("account.details.createdAt"), flex: 2},
        {field: 'pcbQuantity', headerName: t("common.quantity"), flex: 1},
        {
            field: 'total',
            headerName: t("common.total"),
            type: 'number',
            flex: 1,
            valueGetter: (params) => {
                const { value, row } = params;
                return `${value} ${row.currency.symbol}`;
            },
        },
        {field: 'repeatOrder', type: 'actions', flex: 1, sortable: false, align: 'center',
            renderCell: (params) => (
                <Box sx={{display: "flex", gap:"10px"}}>
                    {!isStencilEnabled && params.row.isStencil ?
                        <Tooltip title={t("cta.unavailable")}>
                            <IconButton className={"tooltip-icon-disabled"}
                                        onClick={(event) => {
                                            event?.stopPropagation();
                                            setIsStencilOrderDialog(true);
                                        }}>
                                <DoNotDisturbOnTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={t("cta.orderNow")}>
                            <IconButton className={"tooltip-icon-blue"}
                                        onClick={(event) => {
                                            event?.stopPropagation();
                                            handleRepeatOrderInfos(params.row.id)
                                        }}>
                                <ShoppingCartTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    }

                </Box>
            ),
        },
    ];

    const [priceOfferPdf, setPriceOfferPdf] = useState('');
    const [invoicePdf, setInvoicePdf] = useState('');
    const [gerberFile, setGerberFile] = useState('');
    const [workingGerberFile, setWorkingGerberFile] = useState('')
    const [specFiles, setSpecFiles] = useState('');


    const [isStencilOrderDialog, setIsStencilOrderDialog] = useState(false);

    // GET USER ORDERS
    useEffect(async () => {

        try {
            const orders = await getOrdersByCompany(auth.customerId);
            setCustomerOrders(orders);
        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setConnectToBackend(false);
        }

    }, [auth.customerId])

    // UseState to handle the detail view of an order selected
    const [isOrderSelected, setIsOrderSelected] = useState(false);
    const [orderSelected, setOrderSelected] = useState('');


    /**
     * Fetches details from selected order opens detail view.
     * @param {string} _tokenValueSelected
     * @returns {Promise<void>}
     */
    const handleOrderSelected = async (_tokenValueSelected) => {
        try{
            setConnectToBackend(true);
            const orderDetails = await getOrderDetails(_tokenValueSelected);
            setOrderSelected(orderDetails);
            setIsOrderSelected(true);
            setPriceOfferPdf(orderDetails.documents.priceOfferPdf);
            setInvoicePdf(orderDetails.documents.invoicePdf);
            setSpecFiles(orderDetails.documents.specFiles);
            setGerberFile(orderDetails.documents.gerberFile);
            setWorkingGerberFile(orderDetails.documents.workingGerberFile);
        }
        catch (error){
            setErrorBackendConnection(true);
        }
        finally {
            setConnectToBackend(false);
            window.scrollTo(0,0);
        }
    };

    /**
     *
     * @param {int} _orderId
     * @returns {Promise<void>}
     */
    const handleRepeatOrderInfos = async (_orderId) => {
        try {
            setConnectToBackend(true);
            const repeatOrderInfo = await getRepeatOrderinfo(_orderId);

            window.scrollTo({ top: 0, behavior: 'smooth' });

            if (repeatOrderInfo.pcb.pcbType === 'Aluminium') {
                loadCustomerReferenceAluminum(repeatOrderInfo, true, false);
                navigate("/aluminum");
            }
            if (repeatOrderInfo.pcb.pcbType === 'Flex') {
                loadCustomerReferenceFlex(repeatOrderInfo, true, false);
                navigate("/flex");
            }
            if (repeatOrderInfo.pcb.pcbType === 'Hdi') {
                loadCustomerReferenceHdi(repeatOrderInfo, true, false);
                navigate("/hdi");
            }
            if (repeatOrderInfo.pcb.pcbType === 'Rigid') {
                loadCustomerReferenceRigid(repeatOrderInfo, true, false);
                navigate("/rigid");
            }

            // GTM EVENT : click_repeat_order
            handleClickOrderEvent(
                'click_repeat_order',
                repeatOrderInfo.customPanelization,
                repeatOrderInfo.pcb.pcbType,
            );

        }
        catch (error) {
            setErrorBackendConnection(true);
        }
        finally {
            setConnectToBackend(false);
        }
    };


    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            <Box className="user-content-field-wrapper">
                {
                    isOrderSelected ?
                        <UserOrderDetails
                            priceOfferPdf = {priceOfferPdf}
                            invoicePdf = {invoicePdf}
                            gerberFile = {gerberFile}
                            workingGerberFile = {workingGerberFile}
                            specFiles = {specFiles}
                            orderSelected={orderSelected}
                            setIsSelected={setIsOrderSelected}
                            handleRepeatOrderInfos={handleRepeatOrderInfos}/>
                        :
                        <>
                            <Typography variant={"h3"}>
                                {t("account.orders.title")}
                            </Typography>

                            <Box sx={{height: dataGridHeight, width: '100%'}}>
                                <DataGrid
                                    rows={customerOrders}
                                    columns={orderColumns}
                                    slots={{ toolbar: () => {
                                            return <GridToolbarContainer style={{justifyContent: 'flex-end', gap: '30px'}}>
                                                <GridToolbarFilterButton />
                                                <GridToolbarQuickFilter />
                                            </GridToolbarContainer>
                                        }}}
                                    disableColumnMenu
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 10 } },
                                        sorting: {sortModel: [{ field: 'createdAt', sort: 'desc' }], },
                                    }}
                                    onPaginationModelChange={(params) => {
                                        window.scrollTo(0, 0);
                                        if(params.pageSize === 5){
                                            setDataGridHeight(450);
                                            return
                                        }
                                        setDataGridHeight(params.pageSize * 52 + 147 + 25);
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50]}
                                    localeText={dataGridLocale}
                                    onRowClick={element=> {
                                        if (!isStencilEnabled && element.row.isStencil) {
                                            setIsStencilOrderDialog(true);
                                        } else {
                                            handleOrderSelected(element.row.tokenValue);
                                        }
                                    }}
                                />
                            </Box>
                        </>
                }
            </Box>

            {/*POPUP STENCIL ORDER NOT AVAILABLE*/}
            <Dialog open={isStencilOrderDialog} onClose={ () => setIsStencilOrderDialog(false) }>
                <DialogTitle>
                    {t("popup.stencilUnavailable.titleOrder")}
                </DialogTitle>
                <DialogContent>
                    {t("popup.stencilUnavailable.content1")} <br/>
                    {t("popup.stencilUnavailable.content2")} <br/>
                    {t("popup.stencilUnavailable.content3")}
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeSoftBlue  onClick={ () => setIsStencilOrderDialog(false) }>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>
        </>
)

}


export default UserOrders;
