import {axiosPrivate} from "../api/axios";
import {useEffect} from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import AuthService from "../services/auth.service";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const {auth, setAuth } = useAuth();
    const persistLogin = localStorage.getItem("persist") === 'true' ;

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    console.log("persistLogin ",persistLogin);
                    if(persistLogin) {
                        console.log('Token expired > need to refresh the access token');

                        AuthService.logout();

                        //TODO Uncomment when refresh token works
                        // prevRequest.sent = true;
                        // const newAccessToken = await refresh();
                        // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        // return axiosPrivate(prevRequest);
                    }else {
                        AuthService.logout();
                    }
                }
                return Promise.reject(error);
            }
        );
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;
