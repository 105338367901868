import {axiosPrivate} from "../api/axios";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {ErrorHandling} from "../tools/error-handling";


/**
 * Filters Price Offers by Part Number.
 * Returns an array of Price Offers with unique PN
 * @param {array} priceOffers
 * @param {string} calculator
 * @returns {*[]}
 */
const filterPriceOffer = (priceOffers, calculator) => {
    logInConsole(`Filtering price offers by: ${calculator}`, 'chocolate');

    let referencesFilterred = [];
    for (let i = 0; i < priceOffers.length; i++) {
        let isPartNumberExists = false;

        // Filter for identical Part Number
        referencesFilterred.map(reference => {
            if (priceOffers[i].pcbPartNumber === reference.pcbPartNumber) {
                isPartNumberExists = true
            }
        })

        // Filter for only selected calculator
        priceOffers[i].pcbType === calculator && !isPartNumberExists && referencesFilterred.push(priceOffers[i] );
    }

    logInConsoleObject(referencesFilterred);

    return referencesFilterred;

}



/**
 * Creates a price offer from any calculator.
 * @returns {Promise<any>}
 * @param {'aluminum'|'flex'|'hdi'|'rigid'} calculator
 * @param quoteData
 */
export const createPriceOffer = async (calculator, quoteData) => {
    logInConsoleSpacer();
    logInConsole(`Creation request from frontend : ${calculator} quotation`, 'darkcyan', 'bold');

    //Merci le J, t'es le S
    if (calculator === 'aluminum') {calculator = 'aluminium'}

    try {
        const priceOffer = await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/${calculator}-quotations`, quoteData);

        logInConsole(`${calculator} quotation created`, 'green');
        logInConsoleObject(priceOffer.data);

        return priceOffer.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error creating price offer --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Fetches all Price Offers from the customer's company.
 * Filters the result based on calculator if given.
 * Return an array of Price Offers filters or not.
 * @param {int} customerId
 * @param {string} calculator
 * @returns {Promise<*[]|any>}
 */
export const getPriceOffersByCompany = async (customerId, calculator) => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Quotes`, 'darkmagenta', 'bold');

    try {
        const priceOffers = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${customerId}/price-offers-by-company`);

        if (priceOffers && priceOffers.data.length > 0) {
            logInConsole(`Customer quotes received`, 'green');
            logInConsoleObject(priceOffers.data);
        } else {
            logInConsole(`No customer quotes found (empty)`, 'orange');
        }
        return calculator ? filterPriceOffer(priceOffers.data, calculator) : priceOffers.data

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quotes --->  ${resMessage}`, 'red');
        ErrorHandling(calculator, resMessage);

        throw error;
    }
}

/**
 * Fetches all data from a selected Price Offer.
 * @param {int} priceOfferId
 * @param {boolean} includeTechnicalDetails
 * @returns {Promise<any>}
 */
export const getPriceOfferData = async (priceOfferId, includeTechnicalDetails) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Quote ${priceOfferId} data`, 'darkmagenta', 'bold');

    try {
        const priceOfferData = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${includeTechnicalDetails}/${priceOfferId}/price-offer`)

        logInConsole(`Quote data received`, 'green');
        logInConsoleObject(priceOfferData.data);

        return priceOfferData.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving quote data --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Fetches all PCB data.
 * @param {int} priceOfferId
 * @returns {Promise<any>}
 */
export const getPcbDetails = async (priceOfferId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : PCB Details for Quote ${priceOfferId}`, 'darkmagenta', 'bold');

    try {
    const pcbDetails = await axiosPrivate.get(`/shop/pcb-details-from-price-offer/${priceOfferId}`)

    logInConsole(`PCB Details received`, 'green');
    logInConsoleObject(pcbDetails.data);

    return pcbDetails.data;

}
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving PCB Details --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

    throw error;
}
}

/**
 * Updates quote excluding price change fields.
 * Comment or locale code.
 * @param {int} customerId
 * @param {int} priceOfferId
 * @param {string} comment
 * @param {string} localeCode "en_US"
 * @returns {Promise<any>}
 */
export const updateQuoteExcludingPriceChange = async (customerId, priceOfferId, comment, localeCode) => {
    logInConsoleSpacer();
    logInConsole(`Updating quote excluding price change for customer ${customerId} and price offer ${priceOfferId}`, 'darkmagenta', 'bold');

    const data = {comment, 'localeCode': localeCode}

    try {
        const newFields = await axiosPrivate.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/${customerId}/${priceOfferId}/update-loaded-quote`, data);
        logInConsole(`Quote updated successfully`, 'green');
        logInConsoleObject(newFields.data);
        return newFields.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error updating quote excluding price change ---> ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;

    }
}



/**
 * Creates a stencil only for price, no persist.
 * @returns {Promise<any>}
 * @param stencilData
 */
export const createStencil = async (stencilData) => {
    logInConsoleSpacer();
    logInConsole(`Creation request from frontend : Stencil`, 'darkcyan', 'bold');

    try {
        const stencil = await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/get-stencil-info`, stencilData);

        logInConsole(`Stencil created`, 'green');
        logInConsoleObject(stencil.data);

        return stencil.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error creating stencil --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Updates stencil in a price offer to be able to place an order for the first time.
 * Returns a product variant
 * Comment or locale code.
 * @returns {Promise<any>}
 * @param {int} priceOfferInt
 * @param stencilData
 */
export const updateStencilToOrder = async (priceOfferInt, stencilData) => {
    logInConsoleSpacer();
    logInConsole(`Updating stencil with locked price to order.`, 'darkmagenta', 'bold');

    try {
        const productVariant = await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/price-offers/${priceOfferInt}/update-stencil-before-order-action`, stencilData);
        logInConsole(`Stencil updated successfully`, 'green');
        logInConsoleObject(productVariant.data);
        return productVariant.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error updating stencil with locked price to order ---> ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;

    }
}

/**
 * Updates stencil in a price offer already ordered to be able to place a repeat order.
 * Returns a product variant
 * Comment or locale code.
 * @returns {Promise<any>}
 * @param {int} orderId
 * @param stencilData
 */
export const updateStencilToRepeatOrder = async (orderId, stencilData) => {
    logInConsoleSpacer();
    logInConsole(`Updating stencil with locked price to repeat order.`, 'darkmagenta', 'bold');

    try {
        const productVariant = await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${orderId}/repeat-order`, stencilData);
        logInConsole(`Stencil updated successfully.`, 'green');
        logInConsoleObject(productVariant.data);
        return productVariant.data;
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error updating stencil with locked price to repeat order ---> ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;

    }
}