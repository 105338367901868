import {axios, axiosPrivate, BASE_URL} from '../api/axios';

import authHeader from "./auth-header";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {ErrorHandling} from "../tools/error-handling";


/**
 * Fetches all customer primary data.
 * @param {int} customerId
 * @returns {Promise<any>}
 */
export const getCustomerData = async (customerId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Customer data`, 'darkmagenta', 'bold');

    try {
        const customerData = await axiosPrivate.get(`${BASE_URL}/shop/customers/${customerId}/customized`, {headers: authHeader()});

        logInConsole(`Customer data received`, 'green');
        logInConsoleObject(customerData.data);
        return customerData.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving customer data --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Updates customer data.
 * @param {int} customerId
 * @param customerData
 * @returns {Promise<any>}
 */
export const updateCustomerData = async (customerId, customerData) => {
    logInConsoleSpacer();
    logInConsole(`Updating request from frontend : Customer data`, 'darkslategrey', 'bold');

    try {
        await axiosPrivate.put(`${BASE_URL}/shop/customers/${customerId}`, customerData, {headers: authHeader()});
        logInConsole(`Customer data updated`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}



/**
 * Updates customer password.
 * @param {int} customerId
 * @param password
 * @returns {Promise<any>}
 */
export const updateCustomerPassword = async (customerId, password) => {
    logInConsoleSpacer();
    logInConsole(`Updating request from frontend : Customer password`, 'darkslategrey', 'bold');

    try {
        await axiosPrivate.put(`${BASE_URL}/shop/customers/${customerId}/password`, password, {headers: authHeader()});
        logInConsole(`Customer password updated`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}



/**
 * Fetches mail preferences from one customer.
 * @param {int} customerId
 * @returns {Promise<any>}
 */
export const getMailPreferences = async (customerId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Mail preferences`, 'darkmagenta', 'bold');

    try {
        const mailPreferences = await axiosPrivate.get(`${BASE_URL}/shop/customers/${customerId}/mail-preferences`, {headers: authHeader()});

        logInConsole(`Mail preferences received`, 'green');
        logInConsoleObject(mailPreferences.data);
        return mailPreferences.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Updates mail preferences.
 * @param {int} customerId
 * @param mailData
 * @returns {Promise<any>}
 */
export const updateMailPreferences= async (customerId, mailData) => {
    logInConsoleSpacer();
    logInConsole(`Updating request from frontend : Mail preferences`, 'darkslategrey', 'bold');

    try {
        await axiosPrivate.put(`${BASE_URL}/shop/customers/${customerId}/mail-preferences`, mailData, {headers: authHeader()});
        logInConsole(`Mail preferences updated`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}



/**
 * Creates a customer address.
 * @returns {Promise<any>}
 * @param {int}customerId
 * @param {object} addressData
 */
export const createCustomerAddress = async (customerId, addressData) => {
    logInConsoleSpacer();
    logInConsole(`Creation request from frontend : Customer addresses`, 'darkcyan', 'bold');

    try {
        const customerAddresses = await axiosPrivate.post(`${BASE_URL}/shop/addresses/${customerId}/customized`, addressData, {headers: authHeader()});

        logInConsole(`Customer address created`, 'green');
        logInConsoleObject(customerAddresses.data);
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Fetches customer addresses.
 * @param {int} customerId
 * @returns {Promise<any>}
 */
export const getCustomerAddresses = async (customerId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Customer addresses`, 'darkmagenta', 'bold');

    try {
        const customerAddresses = await axiosPrivate.get(`${BASE_URL}/shop/addresses/${customerId}/customized`, {headers: authHeader()});

        logInConsole(`Customer addresses received`, 'green');
        logInConsoleObject(customerAddresses.data);
        return customerAddresses.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Updates one customer addresse.
 * @param {int} customerId
 * @param {int} addressId
 * @param addressData
 * @returns {Promise<any>}
 */
export const updateCustomerAddress = async (customerId, addressId, addressData) => {
    logInConsoleSpacer();
    logInConsole(`Updating request from frontend : Customer addresses`, 'darkslategrey', 'bold');

    try {
        await axiosPrivate.put(`${BASE_URL}/shop/addresses/${customerId}/customized/${addressId}`, addressData, {headers: authHeader()});
        logInConsole(`Customer addresses updated`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Deletes one customer address.
 * @param {int} addressId
 * @returns {Promise<any>}
 */
export const deleteCustomerAddress = async (addressId) => {
    logInConsoleSpacer();
    logInConsole(`Deleting request from frontend : Customer addresses`, 'darkorange', 'bold');

    try {
        await axiosPrivate.delete(`${BASE_URL}/shop/addresses/${addressId}`, {headers: authHeader()});
        logInConsole(`Customer address deleted`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}



/**
 * Fetches company addresses from one customer.
 * @param {int} customerId
 * @param {int} companyId
 * @returns {Promise<any>}
 */
export const getCompanyAddresses = async (customerId, companyId) => {
    logInConsoleSpacer();
    logInConsole(`Fetching from backend : Company addresses`, 'darkmagenta', 'bold');

    try {
        const companyAddresses = await axiosPrivate.get(`${BASE_URL}/shop/addresses/${companyId}/${customerId}/customized`, {headers: authHeader()});

        logInConsole(`Company addresses received`, 'green');
        logInConsoleObject(companyAddresses.data);
        return companyAddresses.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}

/**
 * Updates one company address from a customer.
 * @param {int} customerId
 * @param {int} companyId
 * @param {int} addressId
 * @param addressData
 * @returns {Promise<any>}
 */
export const updateCompanyAddress = async (customerId, companyId, addressId, addressData) => {
    logInConsoleSpacer();
    logInConsole(`Updating request from frontend : Company addresses`, 'darkslategrey', 'bold');

    try {
        await axiosPrivate.put(`${BASE_URL}/shop/addresses/${companyId}/${customerId}/customized/${addressId}`, addressData, {headers: authHeader()});
        logInConsole(`Company address updated`, 'green');
    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}
