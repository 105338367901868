import React from "react";

function LogoQuickhelper (props) {

    return (
        <>
            <svg version="1.1"
                 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 250 250"
                 height={props.height}
                 fill= {props.fillColor}>

                <path d="M202.1,199.4l-47.9-47.9c8.4-12.4,13.4-27.4,13.4-43.6c0-42.9-34.9-77.7-77.7-77.7S12.2,65.1,12.2,108
		c0,42.9,34.9,77.7,77.7,77.7c15.9,0,30.7-4.8,43-13l47.9,48c2.9,2.9,6.8,4.4,10.6,4.4c3.8,0,7.7-1.5,10.6-4.4
		C208,214.8,208,205.3,202.1,199.4z M42.2,108c0-26.3,21.4-47.7,47.7-47.7s47.7,21.4,47.7,47.7c0,26.3-21.4,47.7-47.7,47.7
		S42.2,134.3,42.2,108z"/>
                <path d="M239.4,90.5v-25H169c4.2,7.7,7.2,16.1,9,25H239.4z"/>
                <path d="M158.8,50.5h61v-15h-77.1C148.7,39.9,154.1,44.9,158.8,50.5z"/>
                <path d="M178.9,120h40.7v-15h-40c0,1,0.1,2,0.1,3C179.7,112.1,179.4,116.1,178.9,120z"/>

            </svg>
        </>
    )
}

export default LogoQuickhelper;

