import React, {useContext, useEffect, useState} from "react";
import {Box, Typography, FormControl, InputLabel, MenuItem, Select, styled, TextField} from "@mui/material";
import {useDropzone} from "react-dropzone";
import './all-steps.scss';
import {Info} from "@mui/icons-material";
import PropTypes from 'prop-types';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider, DateCalendar} from '@mui/x-date-pickers';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {addDays, differenceInBusinessDays, differenceInDays, format, isWeekend, subBusinessDays} from "date-fns";
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import CalculatorRigidFlexContext from "../../../../context/calculatorRigidFlexProvider";
import axios from "axios";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";

//SETTING COLORS FOR THE CALENDAR
const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'isShippingDay' && prop !== 'isWeekEnd' && prop !== 'isSelected'&& prop !== 'isDisabled'
})(({theme, day, isFirstDay, isLastDay, isShippingDay, isWeekEnd, isSelected}) => ({
    ...(day && {
        width: '40px'
    }),
    ...(isShippingDay && {
        borderRadius: 0,
        border: 'none',
        backgroundColor: '#00588C',
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: '#80D3FF',
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        backgroundColor: '#00588C',
        color: theme.palette.common.white,
        border: 'none !important',
        borderColor: 'white !important'
    }),
    ...(isLastDay && {
        backgroundColor: '#00588C',
        border: 'none',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
    ...(isWeekEnd && isShippingDay && {
        backgroundColor: '#E6F2F8',
        border: 'none',
    }),
    ...(isSelected && {
        backgroundColor: '#BAE19F !important',
        color: '#353535 !important',
        border: 'none',
    }),
}));

// ALL STEPS -> RigidFlex Component Params
function AllSteps(props) {
    const {t} = useTranslation();
    const {muiLocale} = useContext(UserContext);

    const{
        media, setMedia,
        quantity, setQuantity,
        layer, setLayer,
        description, setDescription,
        countryId, setCountryId,
        minDeliveryDate, setMinDeliveryDate,
        daysToDelivery, setDaysToDelivery,
        requestedDate, setRequestedDate,
        countriesList, setCountriesList,
        deliveryDate, setDeliveryDate,
    } = useContext(CalculatorRigidFlexContext);


    const [isOn, setIsOn] = useState(false);
    const toggleDropzoneAnimation = () => setIsOn(!isOn);

    //Api post media after drop
    const {getRootProps, getInputProps} = useDropzone({

        accept: 'image/*',
        onDrop:  acceptedFiles => {

            const formData = new FormData();
            formData.append("media", acceptedFiles[0]);

            let headers = {
                'Content-Type': 'multipart/form-data'
            }
            let requestOptions = {
                headers,
                redirect: 'follow'
            }
            axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/v2/media`, formData, requestOptions)
                .then(response => {
                    setMedia(String(response.data.id));
                    return response.data })
                .catch(error => console.log('error', error));
        }
    });

    useEffect(() => {
        if (deliveryDate !== null) {
            deliveryDate.setHours(9);
        }
        setRequestedDate(deliveryDate);
    }, [deliveryDate, setRequestedDate])

    useEffect(() => {
        if (requestedDate !== null) {
            setDaysToDelivery(differenceInBusinessDays(requestedDate, new Date()) + 1);
        }
    }, [requestedDate, setDaysToDelivery])

    //CUSTOMIZATION RENDER DAY FOR CALENDAR
    function renderDay(props) {

        const { day, selectedDay, ...other } = props;

        if (!selectedDay) {
            return <PickersDay day={day}{...other} />;
        }

        const today = new Date();
        const start = today;
        const end = addDays(today, differenceInDays(deliveryDate, today));

        const isShippingDay = isWithinInterval(day, {start, end});
        const isFirstDay = isSameDay(day, start);
        const isLastDay = isSameDay(day, end);
        const isWeekEnd = isWeekend(day);
        const isSelected = isSameDay(day, selectedDay);

        return (
            <CustomPickersDay
                {...other}
                day={day}
                disableMargin
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                isShippingDay={isShippingDay}
                isWeekEnd={isWeekEnd}
                isSelected={isSelected}
            />
        );
    }

    renderDay.propTypes = {
        /**
         * The date to show.
         */
        day: PropTypes.object.isRequired,
        selectedDay: PropTypes.object,
    };

    // minDeliveryDate -> 3 days after today's date excluding Weekends
    function disableDates(date) {
        return date.getDay() === 0 || date.getDay() === 6 || date < subBusinessDays(minDeliveryDate,1);
    }

    const shipping = (differenceInBusinessDays(requestedDate,new Date()) + 1) || null;

    const formatDate = (date) => {
        return format(new Date(date), 'PPPP');
    }

    return (
        <>
            {/*SETTINGS, DRAG N DROP & COMMENT SECTION*/}
            <Box className={"technical-data-card-column rigid-flex-settings-card"}>
                <Box sx={{
                    display: 'flex',
                    gap: '20px',
                }}>
                    <Box className="technical-data-card">

                        {/*TITLE*/}
                        <Typography variant={'h3'}>{t("common.settings")}</Typography>

                        {/*FIELDS*/}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            alignItems: 'center',
                        }}>

                            {/*QUANTITY FIELD*/}
                            <TextField
                                id="quantity"
                                label={t("calculators.toolbar.quantity")}
                                value={quantity}
                                onChange={(e) => {
                                    setQuantity(e.target.value)
                                }}
                                variant="outlined"
                                required
                                type={"number"}
                                sx={{
                                    width: "100%"
                                }}
                            />

                            {/*LAYERS SELECTOR*/}
                            <FormControl
                                sx={{
                                    width: "100%"
                                }}
                            >
                                <InputLabel id="layer">{t("calculators.step1.fields.stackUp")}</InputLabel>
                                <Select
                                    labelId="layer"
                                    id="layer"
                                    value={layer}
                                    label={t("calculators.step1.fields.stackUp")}
                                    onChange={(e) => {
                                        setLayer(e.target.value)
                                    }}
                                >
                                    <MenuItem value={1}>1 Layer</MenuItem>
                                    <MenuItem value={2}>2 Layers</MenuItem>
                                    <MenuItem value={3}>4 Layers</MenuItem>
                                    <MenuItem value={4}>6 Layers</MenuItem>
                                    <MenuItem value={5}>8 Layers</MenuItem>
                                    <MenuItem value={6}>10 Layers</MenuItem>
                                    <MenuItem value={7}>12 Layers</MenuItem>
                                    <MenuItem value={8}>14 Layers</MenuItem>
                                    <MenuItem value={9}>16 Layers</MenuItem>
                                    <MenuItem value={10}>18 Layers</MenuItem>
                                    <MenuItem value={11}>20 Layers</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className="technical-data-card drop-bloc" onClick={toggleDropzoneAnimation}>
                        <Box className={"drop-heading"}>
                            <Typography variant={'h3'}>{t("calculators.step1.gerber.gerberFile")}</Typography>
                        </Box>
                        <Box className="archive-bloc">
                                <Box {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <img src="/assets/lottie/dragndrop.gif" alt=""/>
                                    <Box component={"span"}>{t("calculators.step1.gerber.dragNDrop")}</Box>
                                </Box>
                        </Box>
                    </Box>

                </Box>
                <Box className="technical-data-card">
                    {/*TITLE*/}
                    <Typography variant={'h3'}>{t("calculators.comment.commentTitle")}</Typography>

                    {/*COMMENT SECTION*/}
                    <TextField
                        id="description"
                        label={t("calculators.comment.description")}
                        multiline
                        rows={6}
                        value={description}
                        placeholder={t("calculators.comment.placeholder")}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            width: '100%',
                        }}
                    />
                </Box>
            </Box>

            {/*DELIVERY SETTINGS*/}
            <Box className="technical-data-card delivery-form-settings">
                <Typography variant={'h3'}>{t("common.delivery")}</Typography>
                <Box className={"delivery-picker-wrapper"}>

                    {/*NEW ONE*/}
                    <LocalizationProvider adapterLocale={muiLocale} dateAdapter={AdapterDateFns}>
                        <DateCalendar
                            onChange={(newDeliveryDate) => setDeliveryDate(newDeliveryDate)}
                            shouldDisableDate={disableDates}
                            value={deliveryDate}
                            slots={{ day: renderDay }}
                            slotProps={{
                                day: {
                                    selectedDay: deliveryDate,
                                },
                            }}
                        />
                    </LocalizationProvider>

                    <Box className="delivery-selector-info">
                        <Box className={"delivery-box-info"}>
                            <FormControl>
                                <InputLabel id="deliveryCountry-label">{t("calculators.step3.fields.deliveryCountry")}</InputLabel>
                                <Select
                                    labelId="deliveryCountry"
                                    id="deliveryCountry"
                                    value={countryId}
                                    label={t("calculators.step3.fields.deliveryCountry")}
                                    onChange={(e) => setCountryId(e.target.value)}
                                >
                                    {countriesList.map( (country,i) => {
                                        if(country.enabled)
                                        {
                                            return <MenuItem name="pays" value={country.id} key={i}>{country.name}</MenuItem>
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className={"delivery-box-info"}>
                            <Box className="legend-line">
                                <Box className="legend-square days-to-delivery"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.daysToDelivery")} :
                                    <span className="shipping-days-legend">
                                        {shipping}
                                    </span>
                                </Typography>
                            </Box>

                            <Box className="legend-line">
                                <Box className="legend-square estimated-delivery-date"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.estimatedDeliveryDate")} :
                                </Typography>
                            </Box>

                            <Box className="legend-line">
                                <span className="delivery-date-legend">
                                    {deliveryDate ? formatDate(deliveryDate).toString() : ''}
                                </span>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                <div className='customer-info'>
                    <Info/>
                    <span>{t("calculators.step3.calendar.engineeringConfirmation")}</span>
                </div>
            </Box>
        </>
    )
}
export default AllSteps;
