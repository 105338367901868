import React, {useState} from "react";
import "./quickhelper.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Close} from "@mui/icons-material";
import LogoQuickhelper from "./logo-quickhelper";
import {Link} from "react-router-dom";


function Quickhelper () {

    const [isQuickhelperOpen, setIsQuickhelperOpen] = useState(false);

    return (
        <>
            <Box className={"quickhelper-container"}>

                <Box className="quickhelper-tablet" onClick={() => setIsQuickhelperOpen(true)}>
                    <LogoQuickhelper height="40px" fillColor="#ffffff"/>
                </Box>

                {
                    isQuickhelperOpen ?
                        <Box className="quickhelper-menu-container">
                            <Box className="quickhelper-close" title="Close Quickhelper" onClick={() => setIsQuickhelperOpen(false)}>
                                <Close/>
                            </Box>
                            <Box className="quickhelper-header">
                                <Typography component={"span"}>
                                    <LogoQuickhelper height="30px" fillColor="#80D3FF"/>
                                    uick Helper
                                </Typography>
                                <Typography component={"span"}>Click on a topic and save time!</Typography>
                            </Box>

                            <ul className="quickhelper-items">
                                <li><Link to={"/"}>Order your PCBs online</Link></li>
                                <li>Chat with us</li>
                                <li><Link to={"/"}>Find your local contact</Link></li>
                                <li><Link to={"/"}>Design rules</Link></li>
                                <li><Link to={"/"}>Join ICAPE Group</Link></li>
                                <li><Link to={"/"}>Get our latest news</Link></li>
                            </ul>
                        </Box>
                        : null
                }

            </Box>
        </>
    )
}

export default Quickhelper;
