import {
    Box,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ButtonIcape, ButtonIcapeGreen, ButtonIcapeOutlined} from "../../../button/button-icape";
import React, {useContext, useState} from "react";
import {updateCustomerData, updateCustomerPassword, updateMailPreferences} from "../../../../services/customer.service";
import BackendConnection from "../../../backend-connection/backend-connection";
import {logInConsole, logInConsoleSpacer} from "../../../../tools/debug";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {regex} from "../../../../tools/regex";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import {localeConverter} from "../../../../tools/localeConverter";

const UserAccountSettings = (props) => {
    const {t} = useTranslation();

    // USER FIELD REACT STATES
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);

    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const [isEmailValid, setIsEmailValid] = useState(true);

    const [isEditable, setIsEditable] = useState(false);
    const [userInfoTempo, setUserInfoTempo] = useState({});
    const [userMailPrefTempo, setUserMailPrefTempo] = useState({});

    const {
        auth,
        connectToBackend, setConnectToBackend,
        setErrorBackendConnection,
        isVerified,
        company, setCompany,
        firstName, setFirstName,
        lastName, setLastName,
        phoneNumber, setPhoneNumber,
        email, setEmail,
        newPassword, setNewPassword,
        isTestAccount,
        technicalDocsEmail, setTechnicalDocsEmail,
        extraTechnicalDocsEmail, setExtraTechnicalDocsEmail,
        orderConfirmationEmail, setOrderConfirmationEmail,
        extraOrderConfirmationEmail, setExtraOrderConfirmationEmail,
        deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail,
        extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail,
        localesList,
        companyLocale, setCompanyLocale
    } = useContext(UserContext);

    // INFO UPDATE
    const handleEdit = () => {
        // Saving data in case of cancel to display right info
        setUserInfoTempo({
            "company": company,
            "firstName": firstName,
            "lastName": lastName,
            "phone": phoneNumber,
            "email": email
        });

        setUserMailPrefTempo({
            "technicalDocsEmail" : technicalDocsEmail,
            "extraTechnicalDocsEmail" : extraTechnicalDocsEmail,
            "orderConfirmationEmail" : orderConfirmationEmail,
            "extraOrderConfirmationEmail" : extraOrderConfirmationEmail ,
            "deliveryAndInvoiceEmail" : deliveryAndInvoiceEmail,
            "extraDeliveryAndInvoiceEmail" : extraDeliveryAndInvoiceEmail,
        });

        // Unlocking fields
        setIsEditable(true);
    }
    const handleCancelEdit = () => {
        // reset field at initial data in case of modifications
        setCompany(userInfoTempo.company);
        setFirstName(userInfoTempo.firstName);
        setLastName(userInfoTempo.lastName);
        setPhoneNumber(userInfoTempo.phone);
        setEmail(userInfoTempo.email);

        setTechnicalDocsEmail(userMailPrefTempo.technicalDocsEmail)
        setExtraTechnicalDocsEmail(userMailPrefTempo.extraTechnicalDocsEmail)

        setOrderConfirmationEmail(userMailPrefTempo.orderConfirmationEmail)
        setExtraOrderConfirmationEmail(userMailPrefTempo.extraOrderConfirmationEmail)

        setDeliveryAndInvoiceEmail(userMailPrefTempo.deliveryAndInvoiceEmail)
        setExtraDeliveryAndInvoiceEmail(userMailPrefTempo.extraDeliveryAndInvoiceEmail)

        // Empty the temporary object
        setUserInfoTempo({});
        setUserMailPrefTempo({});

        // Reset phone error cause of MUI auto error check
        setIsPhoneNumberValid(false);

        // Locking fields
        setIsEditable(false);
    }


    /**
     * Checks customer email.
     * @returns {boolean}
     */
    const checkMail = () => {
        if (regex(email, 'email')) {
            logInConsole(`Mail valid`, 'green');
            setIsEmailValid(true);
            return true
        }
        setIsEmailValid(false);
        logInConsole("Mail isn't valid", 'red');
    }

    /**
     * Handles check and update process request for customer data and mail preferences.
     */
    const handleUpdatedInfo = async () => {
        if (checkMail()) {
            try {
                setConnectToBackend(true);

                let _customerInfo = {
                    firstName,
                    lastName,
                    phoneNumber,
                    company,
                    email
                }
                let _mailData = {
                    technicalDocsEmail,
                    extraTechnicalDocsEmail,
                    orderConfirmationEmail,
                    extraOrderConfirmationEmail,
                    deliveryAndInvoiceEmail,
                    extraDeliveryAndInvoiceEmail,
                    companyLocale
                }

                await updateCustomerData(auth.customerId, _customerInfo);
                await updateMailPreferences(auth.customerId, _mailData);

                setIsEditable(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            catch (error) {
                setErrorBackendConnection(true);
            }
            finally {
                setConnectToBackend(false);
            }
        }
    }



    /**
     * Cancels password reset action and closes the form.
     */
    const cancelResetPassword = () => {
        setIsResetPassword(false);
        setShowPassword(false);
    }

    /**
     * Handles password reset checks.
     * @returns {boolean}
     */
    const checkPassword = () => {
        logInConsoleSpacer();
        logInConsole(`password : ${newPassword}`, 'cornflowerblue');
        logInConsole(`password conf : ${confirmNewPassword}`, 'cornflowerblue');

        if (regex(newPassword, 'password') && regex(confirmNewPassword, 'password')) {
            if (newPassword === confirmNewPassword) {
                setIsPasswordValid(true);
                logInConsole(`Same password`, 'green');
                return true
            } else {
                setIsPasswordValid(false);
                logInConsole(`Different password`, 'orange');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return false
            }
        }
        setIsPasswordValid(false);
        logInConsole('Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol', 'red');
    }

    /**
     * Handles password reset request.
     * @returns {Promise<void>}
     */
    const handlePasswordReset = async () => {
        if(checkPassword()) {
            try {
                setConnectToBackend(true);

                let password = {
                    newPassword,
                    confirmNewPassword,
                    currentPassword,
                };

                await updateCustomerPassword(auth.customerId, password);

                setWrongCurrentPassword(false);
                setIsResetPassword(false);
                setShowPassword(false);
            }
            catch (error) {
                setErrorBackendConnection(true);
                setWrongCurrentPassword(true);
            }
            finally {
                setConnectToBackend(false);
            }
        }
    }



    // PHONE FIELD CHECK
    const handlePhoneNumberChange = (newPhoneNumber) => {
        setIsPhoneNumberValid(!matchIsValidTel(newPhoneNumber));
        setPhoneNumber(newPhoneNumber);
    }

    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            {/*USER INFORMATION*/}
            <Box className="user-content-field-wrapper">
                <Typography component="h3" variant="h3">
                    {t("account.accountSettings.title")}
                </Typography>
                <Box className="user-content-field">
                    {!isResetPassword ?
                        <>
                            {/*COMPANY NAME*/}
                            <TextField
                                label={t("common.companyName")}
                                value={company}
                                disabled={true}
                                onChange={e => setCompany(e.target.value)}
                            />
                            {/*FIRST NAME*/}
                            <TextField
                                label={t("common.firstName")}
                                value={firstName}
                                disabled={!isEditable}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            {/*LAST NAME*/}
                            <TextField
                                label={t("common.lastName")}
                                value={lastName}
                                disabled={!isEditable}
                                onChange={e => setLastName(e.target.value)}
                            />
                            {/*PHONE NUMBER*/}
                            <MuiTelInput
                                label={t("common.phone")}
                                value={phoneNumber}
                                disabled={!isEditable}
                                defaultCountry="FR"
                                error={isPhoneNumberValid}
                                onChange={handlePhoneNumberChange}
                            />
                            {/*EMAIL*/}
                            <TextField
                                label={t("common.email")}
                                value={email}
                                disabled={!isEditable || isTestAccount}
                                error={!isEmailValid}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {/*PASSWORD*/}
                            <TextField
                                label={t("common.password")}
                                value={"••••••••••••"}
                                disabled={true}
                                type={'text'}
                            />
                        </>
                        :
                        <>
                            {/*NEW PASSWORD*/}
                            <TextField
                                label={t("common.newPassword")}
                                value={newPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={!isPasswordValid}
                                helperText={!isResetPassword ? null : "Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol."}
                                onChange={e => setNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {/*CONFIRM PASSWORD*/}
                            <TextField
                                label={t("common.passwordConfirmation")}
                                value={confirmNewPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={!isPasswordValid}
                                helperText={!isResetPassword ? null : "Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol."}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {/*CURRENT PASSWORD*/}
                            <TextField
                                label={t("common.currentPassword")}
                                value={currentPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={wrongCurrentPassword}
                                helperText={wrongCurrentPassword ? "Your password is incorrect." : null}
                                onChange={e => setCurrentPassword(e.target.value)}
                                InputProps={ isResetPassword ? {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                } : <></> }
                            />
                        </>
                    }
                </Box>
            </Box>

            {/*CTA USER INFORMATION*/}
            <Box className="user-content-cta">
                {isEditable === false ?
                    <>
                        {isResetPassword ?
                            <>
                                <ButtonIcapeOutlined onClick={() => cancelResetPassword()}>
                                    {t("cta.cancel")}
                                </ButtonIcapeOutlined>
                                <ButtonIcape onClick={() => handlePasswordReset()}>
                                    {t("cta.reset")}
                                </ButtonIcape>
                            </>
                            :
                            <>
                                <ButtonIcapeOutlined onClick={() => setIsResetPassword(true)} disabled={isTestAccount}>
                                    {t("cta.resetPassword")}
                                </ButtonIcapeOutlined>
                                <ButtonIcape onClick={() => handleEdit()}>
                                    {t("cta.edit")}
                                </ButtonIcape>
                            </>
                        }
                    </>
                    :
                    <>
                        <ButtonIcapeOutlined onClick={() => handleCancelEdit()}>
                            {t("cta.cancel")}
                        </ButtonIcapeOutlined>
                        <ButtonIcapeGreen onClick={handleUpdatedInfo}>
                            {t("cta.update")}
                        </ButtonIcapeGreen>
                    </>
                }
            </Box>

            {/*MAIL PREFERENCES*/}
            {isVerified &&
                <>
                    <Divider/>

                    <Box className="user-content-field-wrapper">

                        {/*TITLE*/}
                        <Typography variant={"h3"}>
                            {t("account.accountSettings.mailPreferences.title")}
                        </Typography>

                        {/*FIELDS*/}
                        <Box className="user-content-field-double-wrapper">

                            {/*TECHNICAL DOCUMENTS*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.technicalDocuments")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={technicalDocsEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setTechnicalDocsEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail")}
                                    value={extraTechnicalDocsEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraTechnicalDocsEmail(e.target.value)}
                                />
                            </Box>

                            {/*ORDER CONFIRMATION*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.orderConfirmation")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={orderConfirmationEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setOrderConfirmationEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail")}
                                    value={extraOrderConfirmationEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraOrderConfirmationEmail(e.target.value)}
                                />
                            </Box>

                            {/*DELIVERY NOTICE AND INVOICES*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.deliveryNotice")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={deliveryAndInvoiceEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setDeliveryAndInvoiceEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail") }
                                    value={extraDeliveryAndInvoiceEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraDeliveryAndInvoiceEmail(e.target.value)}
                                />
                            </Box>

                            {/*MAIL AND DOCUMENT LANGUAGE*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.mailAndDocumentLanguage")}
                                </Typography>
                                <FormControl disabled={!isEditable} sx={{flexGrow: 1}}>
                                    <Select
                                        id="companuLocale"
                                        value={companyLocale}
                                        onChange={e => setCompanyLocale(e.target.value)}
                                    >
                                        {localesList.map((locale, index) => (
                                            <MenuItem value={locale} key={index}>{t(localeConverter(locale))}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    {/*CTA*/}
                    <Box className="user-content-cta">
                        {isEditable === false ?
                            <ButtonIcape onClick={() => handleEdit()}>
                                {t("cta.edit")}
                            </ButtonIcape>
                            :
                            <>
                                <ButtonIcapeOutlined onClick={() => handleCancelEdit()}>
                                    {t("cta.cancel")}
                                </ButtonIcapeOutlined>
                                <ButtonIcapeGreen onClick={handleUpdatedInfo}>
                                    {t("cta.update")}
                                </ButtonIcapeGreen>
                            </>
                        }
                    </Box>
                </>
            }
        </>
    )
}

export default UserAccountSettings;
