
// Converting mm to inches
export const mmToInch = (mm) => {
    const newIn = (mm / 25.4).toFixed(2);

    // If newIn equal 0, return empty to let MUI show an empty field instead of 0.00
    return newIn === 0 ? '' : parseFloat(newIn);
}

// Converting mm to mil
export const mmToMil = (mm) => {
    const newIn = ((mm / 25.4)*1000).toFixed(2);

    // If newIn equal 0, return empty to let MUI show an empty field instead of 0.00
    return newIn === 0 ? '' : parseFloat(newIn);
}

// Converting inches to mm
export const inchToMm = (inch) => {
    const newMm = (inch * 25.4).toFixed(2);

    // If newMm equal 0, return empty to let MUI show an empty field instead of 0.00
    return newMm === 0 ? '' : parseFloat(newMm);
}

// Calculate the PCB area size in mm or inch
export const getAreaSize = (pcbWidth, pcbLength, pcbWidthIn, pcbLengthIn, pcbPerPanel, quantity, unitFormat) => {

    if (isNaN(quantity)){
        return 0
    }

    if (pcbWidth !== '' && pcbLength !== '' && pcbPerPanel !== '' && quantity !== ''){
        if (unitFormat === 'mm'){
            return ((pcbWidth * pcbLength * quantity) / 1000000).toFixed(2)
        }
        if (unitFormat === 'in'){
            return (pcbWidthIn * pcbLengthIn * quantity).toFixed(2)
        }
    }
    return 0;
}