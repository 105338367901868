import {Box, ToggleButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";


const DeliveryFormatSwitch = (props) => {

    const {t} = useTranslation();
    const location = useLocation();

    // PROPS SETUP
    const {pcbDeliveryFormat, setPcbDeliveryFormat} = props.pcbDeliveryFormat
    const {isCustomPanelization, handleShowPanelization, lockCustomPanelizationFields} = props.panelization
    const isLockFields = props.isLockFields


    return(
        <>
            <Box className="technical-data-card">

                {/* TITLE */}
                <Typography variant={'h3'}>{t("calculators.step1.deliveryFormat.name")}</Typography>

                {/* SWITCH TOGGLE */}
                <Box sx={{display: 'flex', flexDirection: 'Column', gap: '5px'}}>

                    {/* SINGLE PCB TOGGLE */}
                    <ToggleButton
                        id='single'
                        value="check"
                        selected={pcbDeliveryFormat === 'single'}
                        onChange={(e) => setPcbDeliveryFormat(e.target.id)}
                        disabled={isLockFields || lockCustomPanelizationFields}
                    >
                        {t("calculators.step1.deliveryFormat.singlePcb")}
                    </ToggleButton>

                    {/* PANELIZATION TOGGLE */}
                    <ToggleButton
                        id='classic'
                        value="check"
                        selected={pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom'}
                        onChange={(e) => setPcbDeliveryFormat(e.target.id)}
                        disabled={isLockFields /* || (!isCustomPanelization && location.pathname === '/flex')*/}
                    >
                        {t("calculators.step1.deliveryFormat.panelization")}
                    </ToggleButton>

                    {/* CUSTOM PANELIZATION TOGGLE */}
                    {
                        isCustomPanelization &&
                            <ToggleButton
                                id='custom'
                                value="check"
                                // selected={pcbDeliveryFormat === 'custom'}
                                onChange={(e) => handleShowPanelization()}
                                disabled={isLockFields && (pcbDeliveryFormat === 'single' || pcbDeliveryFormat === 'classic')}
                            >
                                {t("calculators.step1.deliveryFormat.designMyPanel")}
                            </ToggleButton>
                    }
                </Box>
            </Box>
        </>
    )
}

export default DeliveryFormatSwitch