import {createContext, useState} from "react";

const JaydaContext = createContext({});

export const JaydaProvider = ({ children }) => {
    let jobId = null;
    const [ jobParams, setJobParams ] = useState({});
    const [ analyzeInProgress, setAnalyzeInProgress ] = useState('stop');
    const [ progress, setProgress ] = useState(0);

    return (
        <JaydaContext.Provider value={{
            jobId,
            jobParams, setJobParams,
            analyzeInProgress, setAnalyzeInProgress,
            progress, setProgress
        }} displayName="Jayda">
            {children}
        </JaydaContext.Provider>
    )
}

export default JaydaContext;
