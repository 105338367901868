import React from "react";
import ListTechnologies from "../technologies/list-technologies";
import VideoHeader from "./video-header/video-header";


function Home(props) {
    return (
        <>
            <VideoHeader/>

            <ListTechnologies technologies={props.technologies}/>
        </>
    )
}

export default Home;
